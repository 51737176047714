import { SET_ERROR, IAction } from '../actions/errorActions';
import { createStoreError } from '../../utils/helper';

export interface IError {
  errorCode?: string;
  message?: string;
}

const initState: IError = null;

const errorReducer = (state = initState, action: IAction) => {
  if (action.type === SET_ERROR) {
    const error = createStoreError(action.payload);
    console.log(error); // eslint-disable-line
    return {
      ...error,
    };
  }

  return state;
};

export default errorReducer;
