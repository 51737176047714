import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { gtmPageView } from 'api/analytics/market/gtm';

const GooglePageTracking = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    gtmPageView();

    const searchParams = new URLSearchParams(window.location.search);

    for (const [key, value] of searchParams.entries()) {
      if (key.includes('utm')) {
        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; max-age=2592000`;
      }
    }
  }, [pathname]);

  return null;
};

export default GooglePageTracking;
