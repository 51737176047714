import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import FormD, { Field } from 'components/atoms/Form';
import Block from 'components/atoms/Block';
import Button from 'components/atoms/Button';
import { H3 } from 'components/atoms/Heading';
import InputPhoneNumber from 'components/inputs/InputPhoneNumber';

import { ComponentProps } from './types';

import { FIELDS } from './constants';

const Form: React.FC<ComponentProps> = (props) => {
  const { store } = props;
  const {
    mounted,
    mount,
    unmount,
    captions,
    button,
    values,
    errors,
    disabled,
    handleBlur,
    handleChange,
    handleSubmit,
  } = store;

  useEffect(() => {
    if (!mounted) mount();

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  return (
    <FormD onSubmit={handleSubmit}>
      <Block margin="l" textTransform="uppercase">
        <H3
          font="primary"
          sizes={[32, 40, 48]}
          fontWeight="medium"
          fontStretch="condensed"
          color="grey"
          whiteSpace="preWrap"
          lineHeight={1}
          textAlign="center"
        >
          {captions.title}
        </H3>
      </Block>
      <Block>
        <Field id={FIELDS.PHONE} error={errors[FIELDS.PHONE]}>
          <InputPhoneNumber
            id={FIELDS.PHONE}
            label={captions.fields.phone}
            value={values[FIELDS.PHONE]}
            error={!!errors[FIELDS.PHONE]}
            disabled={disabled[FIELDS.PHONE]}
            onBlur={handleBlur}
            onChange={handleChange}
            autoFocus
          />
        </Field>
      </Block>
      <Block margin="l">
        <Button
          id="getCode"
          display="block"
          type="gradientDark"
          loading={button.loading}
          disabled={button.disabled}
          onClick={handleSubmit}
        >
          {captions.buttons.getCode}
        </Button>
      </Block>
    </FormD>
  );
};

export default observer(Form);
