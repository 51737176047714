import { Dispatch } from 'redux';

export const SET_CONTENT_ID = 'SET_CONTENT_ID';

export interface ISetContentAction {
  type: typeof SET_CONTENT_ID;
  payload: string;
}

export const setContent = (id: string) => (dispatch: Dispatch<ISetContentAction>) => {
  dispatch({
    type: SET_CONTENT_ID,
    payload: id,
  });
};
