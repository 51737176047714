import React from 'react';

import { FormFiledProps } from './types';
import { StyledFormField, StyledFormFieldLabel, StyledFormFieldDescription, StyledFormFieldError } from './style';

const FormField: React.FC<FormFiledProps> = (props) => {
  const { id, label, description, error, children } = props;

  return (
    <StyledFormField>
      {label && <StyledFormFieldLabel htmlFor={id}>{label}</StyledFormFieldLabel>}
      {children}
      {description && <StyledFormFieldDescription>{description}</StyledFormFieldDescription>}
      {error && <StyledFormFieldError>{error}</StyledFormFieldError>}
    </StyledFormField>
  );
};

export default FormField;
