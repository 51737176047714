import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { StyledIcon, StyledIconSvg } from 'components/icons/style';

import { display, StyledLinkProps, types } from './types';
import theme from './theme';

function getColor(props: StyledLinkProps, isHovered: boolean) {
  const type = props.sType || types.primary;
  if (isHovered) {
    // @ts-ignore
    return theme[type].colors.hovered;
  }
  // @ts-ignore
  return theme[type].colors.normal;
}

export const StyledLink = styled.a<StyledLinkProps>`
  display: ${({ sDisplay }) => display[sDisplay]};
  align-items: ${({ sDisplay }) => (sDisplay === 'flex' ? 'center' : 'inherit')};
  color: ${(props) => getColor(props, false)};
  transition: fill 0.32s ease-out;
  text-decoration: none;
  cursor: pointer;

  ${StyledIcon} {
    margin: 0 4px;
    background: none;

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }

  ${StyledIconSvg} {
    fill: ${(props) => getColor(props, false)};
  }

  &:hover {
    color: ${(props) => getColor(props, true)};

    ${StyledIconSvg} {
      fill: ${(props) => getColor(props, true)};
    }
  }
`;

export const StyledRouterLink = styled(RouterLink)`
  cursor: inherit;
  text-decoration: inherit;
  color: inherit;
`;
