export const checkbox = {
  baseStyle: {
    icon: {
      color: 'white',
    },
    control: {
      border: 'none',
      marginLeft: '2px',
      borderRadius: '4px',
      transform: 'skew(-15deg)',
      bg: 'lightGrey10',
      boxShadow: 'none !important',

      _disabled: {
        bg: 'lightGrey10',
      },
      _hover: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #F4F4F4',
      },
      _active: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #F4F4F4',
      },
      _invalid: {
        border: '1px red solid',
      },

      _checked: {
        bg: 'blue10',

        _disabled: {
          bg: 'lightGrey10',
        },
        _hover: {
          bg: 'blueHover',
        },
        _active: {
          bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #4801FF',
          backgroundBlendMode: 'multiply, normal',
        },
      },
    },
    label: {
      textStyle: 'bodyM',
    },
  },
};
