// @ts-nocheck
import Cookies from 'universal-cookie';
import { setLocalData } from '../../utils/helper';

import { CartActions } from '../actions/cartActions';
import { ICart } from '../types/cart';
import { STORAGE_KEYS } from '../../constants';

const initState: ICart = {
  cartItems: {},
  cartID: '',
  cartCount: 0,
  orderID: '',
};

const cartReducer = (state = initState, action) => {
  const cartItems = state;
  const product = action.payload;
  if (action.type === CartActions.SET_CART_ID) {
    const cartCookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
    const cookies = new Cookies();
    cookies.set(cartCookie, action.payload, { path: '/', maxAge: 15_778_800 }); // 6 months
    setLocalData(CartActions.SET_CART_ID, action.payload);

    return {
      ...state,
      cartID: action.payload,
    };
  }
  if (action.type === CartActions.SET_CART) {
    return {
      ...state,
      cartItems: { ...action.payload },
      cartCount: action.payload.quantity,
    };
  }
  switch (action.type) {
    case CartActions.DECREASE_QUANTITY: {
      console.log('Decrease cart qty'); // eslint-disable-line
      // if (product.quantity === 1) {
      //   const remainingItems = (cartItems, product) =>
      //     cartItems.filter(
      //       cartItem => cartItem.cartItemId !== product.cartItemId
      //     );
      //   return remainingItems(cartItems, product);
      // } else {
      //   return cartItems.map(item =>
      //     item.cartItemId === product.cartItemId
      //       ? { ...item, quantity: item.quantity - 1 }
      //       : item
      //   );
      // }

      break;
    }
    case CartActions.DELETE_FROM_CART: {
      const index = cartItems.cartItems.products.findIndex((order) => order.id === product.id);
      cartItems.cartItems.products.splice(index, 1);
      // if (cartItems.cartItems.products.length === 0) {
      //   const cartCookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
      //   const cookies = new Cookies();
      //   cookies.remove(cartCookie);
      //   removeLocalData(CartActions.SET_CART_ID);
      //   return {
      //     ...state,
      //     cartItems: {},
      //     cartCount: cartItems.cartItems.products.length,
      //     cartID: localStorage.getItem('token') ? state.cartID : '',
      //   };
      // }
      return {
        ...state,
        cartCount: cartItems.cartItems.products.length,
        cartItems: cartItems.cartItems,
      };
    }
    case CartActions.DELETE_ALL_FROM_CART: {
      const isPermanentCartId = !!localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

      if (!isPermanentCartId) {
        const cartCookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
        const cookies = new Cookies();
        cookies.remove(cartCookie, { path: '/' });
        localStorage.removeItem('SET_CART_ID');
      }

      return {
        ...state,
        cartItems: {},
        cartCount: 0,
        cartID: isPermanentCartId ? state.cartID : '',
        orderID: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default cartReducer;
