export const urls = {
  getFio: '/suggest/fio',
  getAddress: '/suggest/address',
  getLocate: '/iplocate/address',
};

export const dadataTransform = {
  postalCode: 'postal_code',
  regionCode: (data: any) => data.region_kladr_id?.slice(0, 2),
  regionType: 'region_type',
  regionName: 'region',
  regionKladrCode: 'region_kladr_id',
  areaType: 'area_type',
  areaName: 'area',
  areaKladrCode: 'area_kladr_id',
  cityType: 'city_type',
  cityName: 'city',
  cityKladrCode: 'city_kladr_id',
  settlementType: 'settlement_type',
  settlementName: 'settlement',
  settlementKladrCode: 'settlement_kladr_id',
  streetType: 'street_type',
  streetName: 'street',
  streetKladrCode: 'street_kladr_id',
  houseType: 'house_type',
  house: 'house',
  houseKladrCode: 'house_kladr_id',
  blockType: 'block_type',
  block: 'block',
  flatType: 'flat_type',
  flat: 'flat',
  kladrCode: 'kladr_id',
  fiasId: 'fias_id',
};
