import {
  mediaSizes,
  fonts,
  colors,
  fontWeights,
  fontStyles,
  textAligns,
  fontStretches,
  textTransform,
} from 'components/constants';

export default {
  font: { ...fonts },
  color: { ...colors },
  fontWeight: { ...fontWeights },
  fontStyle: { ...fontStyles },
  fontStretch: { ...fontStretches },
  textAlign: { ...textAligns },
  textTransform: { ...textTransform },
  size: {
    [mediaSizes.s]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
    [mediaSizes.m]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
    [mediaSizes.l]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
  },
  lineHeight: {
    [mediaSizes.s]: { xxs: 12, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
    [mediaSizes.m]: { xxs: 12, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
    [mediaSizes.l]: { xxs: 12, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
  },
  margin: { xxs: 2, xs: 4, s: 8, m: 16, l: 24, xl: 32 },
  padding: { xxs: 2, xs: 4, s: 8, m: 16, l: 24, xl: 32 },
};
