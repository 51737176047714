import getUnique from '../../utils/getUnique';

import { IGetFioResult, IFio, IGetAddressResult, IAddress } from './models';
import { dadataTransform } from './constants';

export function normalizeGetAddressFromServer(data: IGetAddressResult, type: string): IGetAddressResult {
  return {
    ...data,
    suggestions: getUnique(data.suggestions, 'value').map((item: IAddress) => ({
      ...item,
      data: { ...item.data, type },
    })),
  } as IGetAddressResult;
}

export function normalizeGetFioFromServer(data: IGetFioResult, type: string): IGetFioResult {
  return {
    ...data,
    suggestions: getUnique(data.suggestions, 'value').map((item: IFio) => ({
      ...item,
      data: { ...item.data, type },
    })),
  } as IGetFioResult;
}

type KladrAddress = { [key in keyof typeof dadataTransform]: string | null };

export const normalizeAddress = (data: { [key: string]: any }): KladrAddress => {
  const result = {};

  for (const [civiMartKey, dadataKey] of Object.entries(dadataTransform)) {
    if (typeof dadataKey === 'function') {
      result[civiMartKey] = dadataKey(data) ?? null;
    } else {
      result[civiMartKey] = data[dadataKey] ?? null;
    }
  }

  return result as KladrAddress;
};
