const common = {
  border: 'none',
  fontWeight: '500',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  lineHeight: '105%',
};

const blueGradient =
  'linear-gradient(87.44deg, #74F2BA -128.21%, #6CE4CF -103.43%, #62D1EE -80.9%, #57BCF9 -54.92%, #4BA4F8 -18.38%, #3B89F7 18.11%, #2864F6 59.78%, #3F20F5 101.18%)';

export const button = {
  baseStyle: {
    borderRadius: '8px',
    textDecoration: 'none !important',

    _hover: {
      _disabled: {
        bg: 'mediumGrey10',
        // color: '#999999 !important',
      },
    },
  },

  sizes: {
    lg: {
      h: '50px',
    },
    md: {
      h: '40px',
    },
    sm: {
      h: '30px',
    },
    xs: {
      h: '26px',
    },
  },

  variants: {
    light: {
      ...common,
      bg: 'gradient1',
      color: 'white !important',

      _hover: {
        bg: blueGradient,

        _loading: {
          opacity: 1,
          bg: blueGradient,
        },
      },

      _loading: {
        opacity: 1,
        bg: blueGradient,
      },

      _active: {
        bg: blueGradient,
        cursor: 'default',
      },
    },

    dark: {
      ...common,
      bg: '#00F7B6',
      color: '#4801FF',

      _hover: {
        bg: 'linear-gradient(87.44deg, #74F2BA -14.96%, #6CE4CF 17.14%, #62D1EE 46.33%, #57BCF9 79.98%, #4BA4F8 127.31%, #3B89F7 174.58%, #2864F6 228.56%, #3F20F5 282.19%)',
      },
    },

    green: {
      ...common,
      bg: '#00F7B6',
      color: '#515151',

      _active: {
        bg: blueGradient,
        color: '#FFFFFF',
      },
    },

    secondary: {
      ...common,
      color: '#4801FF',

      _hover: {
        bg: '#f3f3f3',
      },

      _active: {
        bg: '#ececec',
      },
    },

    gradient: {
      bg: 'linear-gradient(90.09deg, #74F2BA 0.08%, #6CE4CF 10.87%, #62D1EE 20.68%, #57BCF9 31.98%, #4BA4F8 47.89%, #3B89F7 63.77%, #2864F6 81.9%, #3F20F5 99.93%)',
      border: 'none',
      color: 'white',
    },

    blue: {
      ...common,
      fontSize: '14px',
      fontWeight: '700',
      bg: 'blue10',
      color: 'white',
      letterSpacing: '0.04em',

      _hover: {
        color: 'blue10',
        bg: 'white064',
      },
    },

    lightBlue: {
      ...common,
      fontSize: '14px',
      fontWeight: 500,
      bg: '#f6f2ff',
      color: 'blue10',
      letterSpacing: '0.04em',

      _hover: {
        color: 'blue10',
        bg: '#f2f0f6',
      },
    },

    white: {
      ...common,
      bg: 'white',
      color: 'blue10',

      _hover: {
        bg: 'white',
      },

      _loading: {
        opacity: 1,
        bg: 'white',
      },

      _active: {
        bg: 'white',
        cursor: 'default',
      },
    },

    whiteBlack: {
      bg: 'white',
      textStyle: 'bodyM',
      fontWeight: '400',
    },

    card: {
      bg: 'ultraLightGrey10',

      letterSpacing: '0.04em',

      _hover: {
        color: ['inherit', 'inherit', 'inherit', 'white'],
        bg: ['ultraLightGrey10', 'ultraLightGrey10', 'ultraLightGrey10', 'blue10'],
      },

      _active: {
        color: 'white',
        bg: 'blue10',
      },
    },

    pagination: {
      fontSize: '24px',
      margin: '2px',
      fontWeight: '500',
      fontStretch: 'condensed',
      textAlign: 'center !important',
      color: 'grey10',

      _hover: {
        boxShadow: '0px 8px 29px rgba(0, 0, 0, 0.03), 0px 1px 12.1155px rgba(0, 0, 0, 0.0215656)',
      },

      _active: {
        bg: 'rgba(81, 81, 81, 0.04)',
      },
    },

    tab: {
      w: '50%',
      h: [12, 12, 14],
      textStyle: 'bodyMU',
      fontSize: ['12px', '12px', '16px'],
      color: 'blue10',
      bg: 'lightBlue',
      border: 'none',
      whiteSpace: 'break-spaces',

      _active: {
        color: 'white',
        bg: 'blue10',
      },
    },

    iconCart: {
      minWidth: '32px',
      width: '32px',
      height: '32px',
      bg: 'gradient1',
      borderRadius: '50px',

      '> svg': {},
      _hover: {
        transform: 'scale(1.3)',
        color: 'white',
        bg: 'linear-gradient(87.44deg, #74F2BA -128.21%, #6CE4CF -103.43%, #62D1EE -80.9%, #57BCF9 -54.92%, #4BA4F8 -18.38%, #3B89F7 18.11%, #2864F6 59.78%, #3F20F5 101.18%)',

        _loading: {
          opacity: 1,
          color: 'white',
          bg: 'gradient1',
        },
      },

      _loading: {
        opacity: 1,
        color: 'white',
        bg: 'gradient1',
      },
    },

    icon: {
      _hover: {
        '> svg': {
          fill: 'blue10 !important',
        },
      },
      _active: {
        '> svg': {
          fill: 'blueActive !important',
        },
      },
    },

    iconWhite: {
      '> svg': {
        fill: 'white !important',
        stroke: 'white !important',
      },
      _hover: {
        '> svg': {
          fill: 'white064 !important',
          stroke: 'white064 !important',
        },
      },
      _active: {
        '> svg': {
          fill: 'white !important',
          stroke: 'white !important',
        },
      },
    },

    selector: {
      width: '100%',
      height: '50px',
      bg: 'white',
      fontStyle: 'bodyM',

      fontFamily: 'ALS Hauss',
      fontWeight: 400,
      textAlign: 'left',

      _hover: {
        // box-shadow: 0px 1px 12.115507125854492px 0px rgba(0, 0, 0, 0.0216);
        boxShadow: '0px 8px 29px 0px rgba(0, 0, 0, 0.03)',
      },
      _active: {
        '> svg': {
          fill: 'white !important',
          stroke: 'white !important',
        },
      },
    },
  },
};
