const moonCalc = (imei: string, d: string, n: number) => {
  return Number.parseInt((n + imei.length) % 2 ? d : [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][d], 10);
};

const moonAlgorithm = (value?: string | number) => {
  if (!value) return false;
  const imei = value.toString();
  return [...imei].reduce((sum, d, n) => sum + moonCalc(imei, d, n), 0) % 10 === 0;
};

export default moonAlgorithm;
