import {
  UserActions,
  ISetCountryAction,
  ISetUserAction,
  ISetCurrentAddress,
  ISetStateAction,
} from '../actions/userAction';
import { IUser } from '../types/user';

const initState: IUser = {
  userData: null,
  country: [],
  shipCountry: [],
  state: [],
  shipState: [],
  currentAddress: [],
};

type UserAction = ISetCountryAction | ISetUserAction | ISetCurrentAddress | ISetStateAction;

const userReducer = (state = initState, action: UserAction) => {
  switch (action.type) {
    case UserActions.SET_USER:
      return { ...state, userData: action.payload };
    case UserActions.SET_COUNTRY:
      return { ...state, country: action.payload };
    case UserActions.SET_SHIPPING_COUNTRY:
      return { ...state, shipCountry: action.payload };
    case UserActions.SET_STATE:
      return { ...state, state: action.payload };
    case UserActions.SET_CURRENT_ADDRESS:
      return { ...state, currentAddress: action.payload };
    case UserActions.SET_SHIP_STATE:
      return { ...state, shipState: action.payload };
    default:
      return state;
  }
};

export default userReducer;
