import { combineReducers } from 'redux';
import { createMultilanguageReducer } from 'redux-multilanguage';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import storeReducer from './storeReducer';
import loaderReducer from './loaderReducer';
import goToReducer from './goToReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import contentReducer from './contentReducer';
import settingsReducer from './settingsReducer';

const rootReducer = combineReducers({
  // @ts-ignore
  multilanguage: createMultilanguageReducer({ currentLanguageCode: 'ru' }),
  // currencyData: currencyReducer,
  productData: productReducer,
  merchantData: storeReducer,
  cartData: cartReducer,
  loading: loaderReducer,
  error: errorReducer,
  userData: userReducer,
  content: contentReducer,
  settings: settingsReducer,
  goTo: goToReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
