import { mobileNativeEvents, mobileOs } from './api/constants';
import { MobileNativeEventsListItem } from './types';

export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/*',
  MOBILE: '/mobile',
  MINDBOX: '/s',

  CATALOG: '/catalog',
  PRODUCT: '/product/:id',
  DEVICE_REPORT: '/product/report/:id',
  CONTENT: '/content/pages/:id',
  SEARCH: '/search',
  SEARCH_PRODUCT: '/search/:id',

  BLOG: '/blog',
  BLOG_ITEM: '/blog/:id',

  CONTACT: '/contact',
  ABOUT_US: '/about-us',

  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_FAVORITES: '/my-account/favorites',
  MY_ACCOUNT_ORDERS: '/my-account',
  MY_ACCOUNT_ORDERS_PURCHASES: '/my-account/orders/purchases',
  MY_ACCOUNT_ORDERS_PURCHASES_ITEM: '/my-account/orders/purchases/:id',
  MY_ACCOUNT_ORDERS_SALES: '/my-account/orders/sales',
  MY_ACCOUNT_ORDERS_SALES_ITEM: '/my-account/orders/sales/:id',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT: '/my-account/orders/sales/:id/checkout/',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT: '/my-account/orders/sales/:id/checkout/result',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_ERROR: '/my-account/orders/sales/:id/checkout/result/error',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_SELL_ERROR: '/my-account/orders/sales/:id/checkout/result/error-sell',
  MY_ACCOUNT_WALLET: '/my-account/wallet',
  MY_ACCOUNT_SUPPORT: '/my-account/support',

  REPORT: '/report',
  REPORT_BUY: '/report/buy',
  REPORT_BUY_FUNCTIONAL: '/report/buy-functional',
  REPORT_CHECKOUT: '/report/checkout',
  REPORT_CHECKOUT_RESULT: '/report/checkout/result',
  REPORT_CHECKOUT_RESULT_ERROR: '/report/checkout/result/error',
  REPORT_CHECKOUT_RESULT_FAILED: '/report/checkout/result/failed',

  MY_ACCOUNT_OLD: '/my-account-old',
  REGISTER: '/register',
  LOGIN: '/login',

  CART: '/cart/:id?',
  ORDER_DETAILS: '/order-details/:id',
  CHECKOUT: '/checkout/:cartId?',
  CHECKOUT_RESULT: '/checkout/result',
  CHECKOUT_RESULT_ERROR: '/checkout/result/error',
  ORDER_CONFIRM: '/order-confirm',

  SELL: '/sell',
  SELL_PHONE: '/sell/phone',
  SELL_PHONE_MODEL: '/sell/phone/:manufacturer',
  SELL_PHONE_MODEL_MEMORY: '/sell/phone/:manufacturer/:model',
  SELL_PHONE_MODEL_MEMORY_POLL: '/sell/phone/:manufacturer/:model/:memory/poll/:sellSessionId',
  SELL_VISUAL_DIAGNOSTIC: '/sell/visual-diagnostic',
  SELL_VISUAL_DIAGNOSTIC_PROCESS: '/sell/visual-diagnostic/process',
  SELL_VISUAL_DIAGNOSTIC_RESULT: '/sell/visual-diagnostic/result',
  SELL_VISUAL_DIAGNOSTIC_REJECT: '/sell/visual-diagnostic/reject',
  SECURITY_POLICY: '/security-policy',

  CARD_ENROLL_RESULT: '/card-enroll/result',
  CARD_ENROLL_RESULT_ERROR: '/card-enroll/result/error',

  CREDIT_DC_WIDGET: '/credit/dc-widget/:partnerId/:token',
  CREDIT_WAIT_APPROVE: '/credit/wait-approve',
  CREDIT_ERROR: '/credit/error',

  FAQ: '/faq',
  FAQ_SALE_QUESTIONS: '/faq/sale-questions',
  FAQ_PURCHASE_QUESTIONS: '/faq/purchase-questions',

  INSTRUCTIONS_ITEM: '/instructions/:type/:os',
  INSTRUCTIONS_ITEM_BRANDS: '/instructions/:type/:os/brands',
  INSTRUCTIONS_ITEM_BRANDS_ITEM: '/instructions/:type/:os/brands/:brand',

  NEWS: '/news',
  HOW_TO_TRANSFER: '/how-to-transfer',

  ERROR: '/error',
};

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  DIAGNOSTIC_TOKEN: 'diagnostic_token',
  PAGE_FROM: 'page_from',
  CM_REDIRECT_FROM: 'cmRedirectFrom',
};

export const PAGE_FROM_TYPES = {
  FINAL_OFFER: 'FINAL_OFFER',
  FUNCTIONAL_OFFER: 'FUNCTIONAL_OFFER',
  PERSONAL_CABINET: 'PERSONAL_CABINET',
};

export const mobileNativeEventsList: MobileNativeEventsListItem[] = [
  {
    id: mobileNativeEvents.setBackgroundColor,
    platforms: [
      {
        id: mobileOs.ios,
        version: null,
      },
      {
        id: mobileOs.android,
        version: '1.0.50',
      },
    ],
  },
  {
    id: mobileNativeEvents.processAsDeepLink,
    platforms: [
      {
        id: mobileOs.ios,
        version: '1.0.12',
      },
    ],
  },
];
