export const URLS = {
  GET_CUSTOMER: '/customer/id/:id',
  CHECK_CUSTOMER: '/front/customer/check-customer',
  GET_PROFILE: '/auth/customer/profile ',
  UPDATE_CUSTOMER: '/private/update/customer',
  GET_BALANCE: '/front/customer/balance',
  SEND_SMS_CODE: '/front/customer/send-sms-code',
  SEND_EMAIL_CODE: 'front/customer/send-email-code',
  CHECK_CODE_AND_UPDATE: '/private/update/',
  CHECK_CODE_AND_UPDATE_EMAIL: '/private/update/email',
  DELETE_CUSTOMER: '/private/customer',
  GET_CARD: '/front/customer/card',
  EXIST_CUSTOMER_GET_TOKEN: 'front/customer/exist-customer-get-token',
  NEW_CUSTOMER_GET_TOKEN: 'front/customer/new-customer-get-token',
};

export const SMS_RESULT_CODES = {
  SESSION_CLOSED: 'SESSION_CLOSED',
  SUCCESS: 'SUCCESS',
};

export const SMS_CODE_VERIFICATION_RESULTS = {
  MATCH: 'MATCH',
  MISMATCH: 'MISMATCH',
  ERROR_SESSION_CLOSED: 'ERROR_SESSION_CLOSED',
};
