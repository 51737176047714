import { Product } from 'redux/types/productsSearch';
import { gtmEvent } from '../index';
import { IProductsItems } from '../../orders';

const getVariant = (product: any) => {
  const memory = product?.properties?.find((item: any) => item.code === 'storage')?.propertyValue?.name;
  const color = product?.properties?.find((item: any) => item.code === 'color')?.propertyValue?.name;
  return [memory, color].join('-');
};

const getProduct = (product: Product) => {
  return {
    name: product.description?.name,
    id: String(product.id),
    price: product.finalPrice.replace(/[^\d.]/g, ''),
    brand: product.manufacturer?.description?.name,
    category: 'Телефоны', // передаем всю вложенность категорий товара от первого уровня через слеш
    variant: getVariant(product), // объем памяти и цвет, если какого-то параметра нет, то пропускаем его и отображаем только имеющиеся данные
    quantity: 1,
  };
};

export const gtmPageView = () => {
  gtmEvent({
    event: 'pageview',
  });
};

export const gtmImpressions = (product: Product, position: number) => {
  gtmEvent({
    event: 'impressions',
    ecommerce: {
      currencyCode: 'RUB',
      impressions: [
        {
          ...getProduct(product),
          list: window.location.pathname,
          position: position + 1,
        },
      ],
    },
  });
};

export const gtmProductClick = (product: Product, position: number) => {
  gtmEvent({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: window.location.pathname },
        products: [
          {
            ...getProduct(product),
            position: position + 1,
          },
        ],
      },
    },
  });
};

export const gtmAddToCart = (product: Product, quantity: number) => {
  gtmEvent({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: [
          {
            ...getProduct(product),
            quantity,
          },
        ],
      },
    },
  });
};

export const gtmRemoveFromCart = (products: [Product]) => {
  gtmEvent({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'RUB',
      remove: {
        products: products?.map((product) => getProduct(product)),
      },
    },
  });
};

export const gtmDetailView = (product: Product, event = 'detailView') => {
  gtmEvent({
    event,
    ecommerce: {
      detail: {
        actionField: { list: window.location.pathname },
        products: [
          {
            ...getProduct(product),
          },
        ],
      },
    },
  });
};

export const gtmCheckout = (product: any, step: number, checkoutType?: string) => {
  gtmEvent({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step, option: 'RUB' },
        products: [
          {
            ...getProduct(product),
          },
        ],
      },
    },
    'checkout-type': checkoutType,
  });
};

export const gtmPurchase = (products: IProductsItems, id: string, revenue: string, paymentType: string) => {
  gtmEvent({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id,
          revenue,
          'payment-type': paymentType,
          affiliation: localStorage.getItem('isMobileApp') === 'true' ? 'app' : 'site',
        },
        products: products?.map((item) => {
          return {
            name: item.productName,
            id: String(item.id),
            price: item.product?.finalPrice?.replace(/[^\d.]/g, ''),
            brand: item.product?.manufacturer?.description?.name,
            category: 'Телефоны',
            variant: getVariant(item.product),
            quantity: 1,
          };
        }),
      },
    },
  });
};

export const gtmSuccess = (id: string) => {
  gtmEvent({
    event: 'payment-success',
    'order-id': id,
  });
};

export const gtmAddPhonePopup = {
  initiate: () => gtmEvent({ event: 'add-phone-popup-initiate' }),
  added: () => gtmEvent({ event: 'add-phone-popup-added' }),
  smsRequest: () => gtmEvent({ event: 'add-phone-popup-sms-request' }),
  success: () => gtmEvent({ event: 'add-phone-popup-success' }),
};
