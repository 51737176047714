import styled from 'styled-components';
import { media } from 'components/constants';

export const StyledAuthTimer = styled.div`
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  margin: 24px 24px 0 24px;

  @media (${media.tablet}) {
    margin: 35px 0 10px 0;
  }

  @media (${media.desktop}) {
    margin: 35px 0 0 0;
  }
`;
