import { IProduct } from '../types/product';
import {
  ProductActions,
  ISetCategoryIdAction,
  ISetProductIdAction,
  ISetProductsAction,
} from '../actions/productActions';

const initState: IProduct = {
  products: [],
  productid: null,
  categoryid: null,
};

type ProductAction = ISetCategoryIdAction | ISetProductIdAction | ISetProductsAction;

const productReducer = (state = initState, action: ProductAction) => {
  switch (action.type) {
    case ProductActions.SET_PRODUCTS:
      return { ...state, products: action.payload };
    case ProductActions.SET_PRODUCT_ID:
      return { ...state, productid: action.payload };
    case ProductActions.SET_CATEGORY_ID:
      return { ...state, categoryid: action.payload };
    default:
      return state;
  }
};

export default productReducer;
