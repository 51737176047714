import jwtDecode from 'jwt-decode';
import { getMobileToken, isExistMobileToken } from './getMobileToken';
import { STORAGE_KEYS } from '../constants';

export function getTokenExpTime(key: string) {
  try {
    const token = localStorage.getItem(key);
    if (!token) return 0;
    const data: any = jwtDecode(token);
    if (!data?.exp) return 0;
    return data?.exp * 1000 - Date.now();
  } catch {
    return 0;
  }
}

export function checkDiagnosticToken() {
  const tokenExpTime = getTokenExpTime(STORAGE_KEYS.DIAGNOSTIC_TOKEN);
  if (tokenExpTime > 0) return;
  localStorage.removeItem(STORAGE_KEYS.DIAGNOSTIC_TOKEN);
}

export function getAuthorized(isMobileApp: boolean) {
  checkDiagnosticToken();
  if (isMobileApp && isExistMobileToken()) getMobileToken();
  const tokenExpTime = getTokenExpTime(STORAGE_KEYS.ACCESS_TOKEN);
  return tokenExpTime > 0;
}
