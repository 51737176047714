import styled from 'styled-components';

import { media, mediaSizes } from 'components/constants';

import { StyledBlockProps } from './Block.types';
import theme from './theme';

function getDisplay(props: StyledBlockProps, mediaSize: string) {
  if (Array.isArray(props.sDisplay)) {
    const [s, m, l] = props.sDisplay;
    const params = { s, m, l };
    return params[mediaSize];
  }
  return props.sDisplay;
}

function getFontFamily(props: StyledBlockProps) {
  if (!props.sFont) return 'inherit';
  return theme.font[props.sFont];
}

function getColor(props: StyledBlockProps) {
  if (!props.sColor) return 'inherit';
  return theme.color[props.sColor];
}

function getTextAlign(props: StyledBlockProps) {
  if (!props.sTextAlign) return 'inherit';
  return theme.textAlign[props.sTextAlign];
}

function getTextTransform(props: StyledBlockProps) {
  if (!props.sTextTransform) return 'inherit';
  return theme.textTransform[props.sTextTransform];
}

function getFontSize(props: StyledBlockProps, mediaSize: string) {
  if (!props.sSize) return 'inherit';
  return `${theme.size[mediaSize][props.sSize]}px`;
}

function getLineHeight(props: StyledBlockProps, mediaSize: string) {
  if (!props.sSize) return 'inherit';
  return `${theme.lineHeight[mediaSize][props.sSize]}px`;
}

function getFontWeight(props: StyledBlockProps) {
  if (!props.sFontWeight) return 'inherit';
  return theme.fontWeight[props.sFontWeight];
}

function getFontStyle(props: StyledBlockProps) {
  if (!props.sFontStyle) return 'inherit';
  return theme.fontStyle[props.sFontStyle];
}

function getFontStretch(props: StyledBlockProps) {
  if (!props.sFontStretch) return 'inherit';
  return theme.fontStretch[props.sFontStretch];
}

function getMargin(props: StyledBlockProps, mediaSize: string) {
  if (props.sM) return `${props.sM}px 0`;
  if (props.sMargins) {
    const [s, m, l] = props.sMargins;
    const params = { s, m, l };
    return `${params[mediaSize]}px 0`;
  }
  if (Array.isArray(props.sMargin)) {
    const [s, m, l] = props.sMargin;
    const params = { s, m, l };
    return params[mediaSize] && `${params[mediaSize]}px 0`;
  }
  return props.sMargin ? `${theme.margin[props.sMargin]}px 0` : '0';
}

function getPadding(props: StyledBlockProps, mediaSize: string) {
  const vPadding = props.sVPadding || props.sPadding;
  const hPadding = props.sHPadding || props.sPadding;
  let aVPadding;
  let aHPadding;
  if (Array.isArray(vPadding)) {
    const [s, m, l] = vPadding;
    const params = { s, m, l };
    aVPadding = params[mediaSize];
  }
  if (Array.isArray(hPadding)) {
    const [s, m, l] = hPadding;
    const params = { s, m, l };
    aHPadding = params[mediaSize];
  }
  if (Array.isArray(vPadding) || Array.isArray(hPadding)) {
    return `${aVPadding ? aVPadding : 0}px ${aHPadding ? aHPadding : 0}px`;
  }
  return `${vPadding ? theme.padding[vPadding] : 0}px ${hPadding ? theme.padding[hPadding] : 0}px`;
}

export const StyledBlock = styled.div<StyledBlockProps>`
  position: relative;
  box-sizing: border-box;
  display: ${(props) => getDisplay(props, mediaSizes.s)};
  font-family: ${(props) => getFontFamily(props)};
  color: ${(props) => getColor(props)};
  font-style: ${(props) => getFontStyle(props)};
  font-weight: ${(props) => getFontWeight(props)};
  font-stretch: ${(props) => getFontStretch(props)};
  text-align: ${(props) => getTextAlign(props)};
  text-transform: ${(props) => getTextTransform(props)};
  margin: ${(props) => getMargin(props, mediaSizes.s)};
  padding: ${(props) => getPadding(props, mediaSizes.s)};
  font-size: ${(props) => getFontSize(props, mediaSizes.s)};
  line-height: ${(props) => getLineHeight(props, mediaSizes.s)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'inherit')};
  width: ${(props) => (props.width ? props.width : 'initial')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'inherit')};

  @media (${media.tablet}) {
    display: ${(props) => getDisplay(props, mediaSizes.m)};
    font-size: ${(props) => getFontSize(props, mediaSizes.m)};
    line-height: ${(props) => getLineHeight(props, mediaSizes.m)};
    margin: ${(props) => getMargin(props, mediaSizes.m)};
    padding: ${(props) => getPadding(props, mediaSizes.m)};
  }

  @media (${media.desktop}) {
    display: ${(props) => getDisplay(props, mediaSizes.l)};
    font-size: ${(props) => getFontSize(props, mediaSizes.l)};
    line-height: ${(props) => getLineHeight(props, mediaSizes.l)};
    margin: ${(props) => getMargin(props, mediaSizes.l)};
    padding: ${(props) => getPadding(props, mediaSizes.l)};
  }

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`;
