import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import date from 'utils/date';

import LogoMain from 'components/atoms/LogoMain';
import Link from 'components/atoms/Link';
import { H3, H4 } from 'components/atoms/Heading';
import Span from 'components/atoms/Span';
import Grid, { Item } from 'components/atoms/Grid';
import Block from 'components/atoms/Block';
import Button from 'components/atoms/Button';

import { ErrorProps } from './types';
import {
  StyledError,
  StyledErrorLogo,
  StyledErrorBody,
  StyledErrorBodyCode,
  StyledErrorBodyTitle,
  StyledErrorBodyAction,
  StyledErrorBodyDescription,
} from './style';

import { ROUTES } from '../../../constants';
import { useStore } from '../../../store';

const Error: React.FC<ErrorProps> = (props) => {
  const store = useStore();
  const { error, isMobileApp, captions } = props;
  const { status = 500 } = error;
  const location = useLocation();
  const dateTime = date.format(date.getToday(), 'DD.MM.YYYY, HH:mm:ss');
  const description = captions.error.description.replace(':date', dateTime);
  const notFound = status === 404 && !isMobileApp;

  const homeRoute = store.isChannel ? `${ROUTES.SELL_PHONE}${location.search}` : ROUTES.HOME;
  return (
    <StyledError>
      <StyledErrorLogo>
        <Link href={homeRoute} isPseudo={store.isMobileRoute}>
          <LogoMain type="iconWhite" />
        </Link>
      </StyledErrorLogo>
      <StyledErrorBody>
        <StyledErrorBodyCode>
          <H3 font="primary" sizes={[190, 300, 320]} fontWeight="medium" fontStretch="condensed" color="transparent">
            {status}
          </H3>
        </StyledErrorBodyCode>
        <StyledErrorBodyTitle>
          <H4 font="primary" sizes={[22, 40, 64]} fontStretch="condensed" fontWeight="medium" color="blue">
            {status === 404 ? captions.error.notFount : captions.error.serverError}
          </H4>
        </StyledErrorBodyTitle>
        <StyledErrorBodyDescription>
          <Block textAlign="center" margin="s">
            <Span font="secondary" sizes={[16, 16, 16]} color="white" whiteSpace="preWrap">
              {window.location.href.replace(/(^\w+:|^)\/\//, '')}
            </Span>
          </Block>
          <Block textAlign="center" margin="s">
            <Span font="secondary" sizes={[16, 16, 16]} color="white" whiteSpace="preWrap">
              {description}
            </Span>
          </Block>
        </StyledErrorBodyDescription>
        <StyledErrorBodyAction>
          <Grid size={100} justifyContent="center">
            {notFound && (
              <Item size={100} tablet={40} desktop={18}>
                <Link href={ROUTES.HOME}>
                  <Button width="100%">{captions.error.buttons.goToMain}</Button>
                </Link>
              </Item>
            )}
            {!notFound && (
              <Item size={100} tablet={40} desktop={18}>
                <Button width="100%" onClick={() => window.location.reload()}>
                  {captions.error.buttons.updatePage}
                </Button>
              </Item>
            )}
          </Grid>
        </StyledErrorBodyAction>
      </StyledErrorBody>
    </StyledError>
  );
};

export default observer(Error);
