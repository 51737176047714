import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconClose: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={26}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.38507 4.81694C3.9801 5.22197 3.98015 5.87861 4.38519 6.28359L11.1193 13.0166L4.38513 19.7508C3.98013 20.1558 3.98013 20.8125 4.38513 21.2175C4.79014 21.6225 5.44678 21.6225 5.85178 21.2175L14.0527 13.0165L5.85172 4.81682C5.44669 4.41184 4.79005 4.4119 4.38507 4.81694Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9943 21.2158C21.3993 20.8107 21.3992 20.1541 20.9942 19.7491L14.2601 13.0161L20.9943 6.28191C21.3993 5.8769 21.3993 5.22026 20.9943 4.81526C20.5893 4.41026 19.9326 4.41026 19.5276 4.81526L11.3267 13.0162L19.5277 21.2159C19.9327 21.6209 20.5893 21.6208 20.9943 21.2158Z"
      />
    </IconPrimitive>
  );
};

export default IconClose;
