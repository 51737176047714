export const URLS = {
  CART: 'cart/:id?lang=ru',
  START_CHECKOUT: 'cart/:id/start-checkout',
  MERGE_CART: 'auth/cart/merge',
};

export const paymentTypes = {
  cashOnDelivery: 'CASH_ON_DELIVERY',
  sbp: 'SBP',
  card: 'CARD',
  credit: 'CREDIT_DC',
  yandexSplit: 'YANDEX_SPLIT',
};
