import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import dateIsToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { UnitTypeLong } from 'dayjs/esm';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);
dayjs.extend(dateIsToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);

export const LOCALES = {
  EN: 'en',
  RU: 'ru',
};

function setLocale(locale: string) {
  dayjs.locale(locale);
}

/**
 * Форматирование даты из формата ISO
 * @param {string} date - дата в формате ISO
 * @param {string} customFormat - формат
 * @returns {string} - отформатированная дата
 */
function format(date: string, customFormat = 'DD.MM.YYYY HH:mm:ss') {
  return date && dayjs(date).isValid() ? dayjs(date).format(customFormat) : null;
}

function customFormat(date: string, customParseFormat?: string, customFormat?: string) {
  return date && dayjs(date, customParseFormat).isValid() ? dayjs(date, customParseFormat).format(customFormat) : null;
}

function getDay(date: string) {
  if (!date || !dayjs(date).isValid()) return null;

  const day = dayjs(date);

  return {
    date: day.format(),
    start: day.startOf('day').format(),
    end: day.endOf('day').format(),
    day: day.format('DD'),
    weekDay: day.format('dd'),
    isToday: day.isToday(),
    isYesterday: day.isYesterday(),
    isTomorrow: day.isTomorrow(),
    isPast: day.isBefore(dayjs().startOf('day')),
  };
}

function getFormattedDay(date: string) {
  return format(date, 'DD.MM.YYYY');
}

function getFormattedTime(date: string) {
  return format(date, 'HH:mm');
}

function getToday() {
  return dayjs().format();
}

function getStartOfDay(date: string) {
  return dayjs(date).startOf('day').format();
}

function addDays(date: string, qty: number) {
  if (!date || !dayjs(date).isValid()) return null;
  return dayjs(date).add(qty, 'day').format();
}

function getTimeDiff(t1: string, t2: string, unit?: UnitTypeLong) {
  const m1 = dayjs(t1);
  const m2 = dayjs(t2);
  return m2.diff(m1, unit);
}

function isInPast(date: string) {
  if (!date || !dayjs(date).isValid()) return null;

  const now = dayjs();
  const dayjsDate = dayjs(date);

  return dayjsDate.isSameOrBefore(now);
}

setLocale(LOCALES.RU);

export default {
  LOCALES,
  setLocale,
  format,
  customFormat,
  getDay,
  getFormattedDay,
  getFormattedTime,
  getToday,
  getStartOfDay,
  addDays,
  getTimeDiff,
  isInPast,
};
