import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from './constants';

const Sell = lazy(() => import('../pages/sell/Sell'));
const SellPhone = lazy(() => import('../pages/sell/poll/Phone'));
const SellModel = lazy(() => import('../pages/sell/poll/Model'));
const SellMemory = lazy(() => import('../pages/sell/poll/Memory'));
const SellPoll = lazy(() => import('../pages/sell/poll/Poll'));
const MyAccountProfile = lazy(() => import('../pages/my-account/Profile'));
const MyAccountPurchases = lazy(() => import('../pages/my-account/purchases/List'));
const MyAccountPurchasesItem = lazy(() => import('../pages/my-account/purchases/Item'));
const MyAccountSales = lazy(() => import('../pages/my-account/sales/List'));

const SellCheckout = lazy(() => import('../pages/sell/Checkout'));
const SellCheckoutResult = lazy(() => import('../pages/sell/Checkout/Result'));
const SellCheckoutResultError = lazy(() => import('../pages/sell/Checkout/ResultError'));
const SellCheckoutResultSellError = lazy(() => import('../pages/sell/Checkout/ResultSellError'));
const BuyReport = lazy(() => import('../pages/report/BuyReport'));
const BuyFuncReport = lazy(() => import('../pages/report/BuyFuncReport'));
const CheckoutReport = lazy(() => import('../pages/report/CheckoutReport'));
const CheckoutReportResult = lazy(() => import('../pages/report/CheckoutResult'));
const CheckoutReportResultError = lazy(() => import('../pages/report/CheckoutError'));
const CardEnrollResult = lazy(() => import('../pages/card-enroll/CardEnrollResult'));
const CardEnrollResultError = lazy(() => import('../pages/card-enroll/CardEnrollError'));
const CheckoutReportResultFailed = lazy(() => import('../pages/report/CheckoutFailed'));
const CreditDCWidget = lazy(() => import('../pages/credit/direct-credit/Widget'));
const CreditWaitApprove = lazy(() => import('../pages/credit/direct-credit/WaitApprove'));
const CreditError = lazy(() => import('../pages/credit/direct-credit/Error'));

const Faq = lazy(() => import('../pages/faq/Faq'));
const FaqPurchaseQuestions = lazy(() => import('../pages/faq/PurchaseQuestions'));
const FaqSaleQuestions = lazy(() => import('../pages/faq/SaleQuestions'));

const Content = lazy(() => import('../pages/content/Content'));

const InstructionsItem = lazy(() => import('../pages/instructions/Item'));

const News = lazy(() => import('../pages/news/News'));
const HowToTransfer = lazy(() => import('../pages/how-to-transfer/HowToTransfer'));

const NotFound = lazy(() => import('../pages/other/NotFound'));

const renderContent = (Component) => {
  return <Component isMobileRoute />;
};

const Mobile = () => {
  return (
    <Switch>
      <Route path={ROUTES.SELL} component={() => renderContent(Sell)} exact />
      <Route path={ROUTES.SELL_PHONE} component={() => renderContent(SellPhone)} exact />
      <Route path={ROUTES.SELL_PHONE_MODEL} component={() => renderContent(SellModel)} exact />
      <Route path={ROUTES.SELL_PHONE_MODEL_MEMORY} component={() => renderContent(SellMemory)} exact />
      <Route path={ROUTES.SELL_PHONE_MODEL_MEMORY_POLL} component={() => renderContent(SellPoll)} exact />

      <Route
        path={ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_SELL_ERROR}
        component={() => renderContent(SellCheckoutResultSellError)}
      />
      <Route
        path={ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_ERROR}
        component={() => renderContent(SellCheckoutResultError)}
      />
      <Route
        path={ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT}
        component={() => renderContent(SellCheckoutResult)}
      />
      <Route path={ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT} component={() => renderContent(SellCheckout)} />

      <Route path={ROUTES.MY_ACCOUNT} component={MyAccountProfile} exact />
      <Route path={ROUTES.MY_ACCOUNT_ORDERS_PURCHASES} component={MyAccountPurchases} exact />
      <Route path={ROUTES.MY_ACCOUNT_ORDERS_PURCHASES_ITEM} component={MyAccountPurchasesItem} exact />
      <Route path={ROUTES.MY_ACCOUNT_ORDERS_SALES} component={MyAccountSales} exact />

      <Route path={ROUTES.REPORT_BUY} component={BuyReport} exact />
      <Route path={ROUTES.REPORT_BUY_FUNCTIONAL} component={BuyFuncReport} exact />
      <Route path={ROUTES.REPORT_CHECKOUT} component={CheckoutReport} exact />
      <Route path={ROUTES.REPORT_CHECKOUT_RESULT} component={CheckoutReportResult} exact />
      <Route path={ROUTES.REPORT_CHECKOUT_RESULT_ERROR} component={CheckoutReportResultError} exact />
      <Route path={ROUTES.REPORT_CHECKOUT_RESULT_FAILED} component={CheckoutReportResultFailed} exact />
      <Route path={ROUTES.CARD_ENROLL_RESULT} component={() => renderContent(CardEnrollResult)} exact />
      <Route path={ROUTES.CARD_ENROLL_RESULT_ERROR} component={() => renderContent(CardEnrollResultError)} exact />
      <Route path={ROUTES.CREDIT_DC_WIDGET} component={() => renderContent(CreditDCWidget)} exact />
      <Route path={ROUTES.CREDIT_WAIT_APPROVE} component={() => renderContent(CreditWaitApprove)} exact />
      <Route path={ROUTES.CREDIT_ERROR} component={() => renderContent(CreditError)} exact />
      <Route path={ROUTES.FAQ} component={Faq} exact />
      <Route path={ROUTES.FAQ_PURCHASE_QUESTIONS} component={FaqPurchaseQuestions} exact />
      <Route path={ROUTES.FAQ_SALE_QUESTIONS} component={FaqSaleQuestions} exact />
      <Route path={ROUTES.CONTENT} component={Content} />
      <Route path={ROUTES.INSTRUCTIONS_ITEM} component={InstructionsItem} exact />
      <Route path={ROUTES.INSTRUCTIONS_ITEM_BRANDS} component={InstructionsItem} exact />
      <Route path={ROUTES.INSTRUCTIONS_ITEM_BRANDS_ITEM} component={InstructionsItem} exact />
      <Route path={ROUTES.NEWS} component={News} exact />
      <Route path={ROUTES.HOW_TO_TRANSFER} component={HowToTransfer} exact />
      <Route path={ROUTES.NOT_FOUND} component={NotFound} />
    </Switch>
  );
};

export default Mobile;
