import checkVersion from './utils/checkVersion';
import { MobileNativeEventsListItem } from './types';
import { ISettings } from './store';

export const getMobileNativeEventsVisible = (list: MobileNativeEventsListItem[], settings?: ISettings) => {
  return list.reduce((acc, item) => {
    const platform = item.platforms.find((element) => element.id === settings?.os);
    if (platform && !platform.version) acc[item.id] = true;
    if (platform && platform.version && checkVersion(settings.version, platform.version)) acc[item.id] = true;
    return acc;
  }, {});
};
