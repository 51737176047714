import React from 'react';

import { LinkProps } from './types';
import { StyledLink, StyledRouterLink } from './style';

const Link: React.FC<LinkProps> = (props) => {
  const { id, children, display = 'block', isPseudo, isRouter, type, href, ...rest } = props;

  let as = 'a';
  if (isPseudo || isRouter) as = 'div';

  const showRouter = isRouter && !isPseudo;

  if (showRouter) {
    return (
      <StyledRouterLink to={href} {...rest}>
        {/* @ts-ignore */}
        <StyledLink id={id} as={as} sDisplay={display} sType={type}>
          {children}
        </StyledLink>
      </StyledRouterLink>
    );
  }
  return (
    // @ts-ignore
    <StyledLink id={id} as={as} sDisplay={display} sType={type} href={href} {...rest}>
      {children}
    </StyledLink>
  );
};

export default Link;
