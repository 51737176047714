import { colors, fonts, mediaSizes } from 'components/constants';

export default {
  font: { ...fonts },
  colors: { ...colors },
  backgroundColors: { ...colors },

  size: {
    [mediaSizes.s]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
    [mediaSizes.m]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
    [mediaSizes.l]: { xxs: 10, xs: 12, s: 14, m: 16, l: 18, xl: 20 },
  },
  lineHeight: {
    [mediaSizes.s]: { xxs: 13, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
    [mediaSizes.m]: { xxs: 13, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
    [mediaSizes.l]: { xxs: 13, xs: 16, s: 20, m: 24, l: 28, xl: 32 },
  },

  fadeBackgroundColor: colors.black56,

  inner: {
    borderRadius: {
      [mediaSizes.s]: '8px',
      [mediaSizes.m]: '12px',
      [mediaSizes.l]: '16px',
    },
  },

  header: {
    padding: {
      [mediaSizes.s]: '24px 56px 0px 32px',
      [mediaSizes.m]: '24px 56px 0px 32px',
      [mediaSizes.l]: '24px 56px 0px 32px',
    },
  },

  close: {
    size: {
      [mediaSizes.s]: 18,
      [mediaSizes.m]: 20,
      [mediaSizes.l]: 32,
    },
    position: {
      [mediaSizes.s]: [16, 16],
      [mediaSizes.m]: [18, 18],
      [mediaSizes.l]: [20, 20],
    },
  },

  content: {
    padding: {
      [mediaSizes.s]: { none: 0, s: '16px 12px', m: '16px 16px', l: '20px 16px' },
      [mediaSizes.m]: { none: 0, s: '16px 14px', m: '24px 24px', l: '24px 32px' },
      [mediaSizes.l]: { none: 0, s: '16px 24px', m: '28px 32px', l: '32px 40px' },
    },
  },
};
