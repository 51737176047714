import styled from 'styled-components';
import Modal from 'components/atoms/Modal';
import { StyledModalTransitionProps } from './types';
import { StyledModalInner } from 'components/atoms/Modal/style';

export const StyledModalTransition = styled(Modal)<StyledModalTransitionProps>`
  &.modal-c-enter {
    opacity: 0;

    ${StyledModalInner} {
      transform: scale(0.97) translate(-50%, -50%);
      transform-origin: -15% -15%;
    }
  }
  &.modal-c-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all ${({ sDuration }) => sDuration}ms;

    ${StyledModalInner} {
      transform: scale(1) translate(-50%, -50%);
      transition: all ${({ sDuration }) => sDuration}ms;
      transform-origin: -15% -15%;
    }
  }
  &.modal-c-exit {
    opacity: ${({ sExitOpacity }) => sExitOpacity};
  }
  &.modal-c-exit-active {
    opacity: 0;
    transition: all ${({ sDuration }) => sDuration}ms;
  }
`;
