import styled from 'styled-components';

import { media, mediaSizes } from 'components/constants';

import { StyledSpanProps } from './Span.types';
import theme from './theme';
import { StyledHeadingProps } from '../Heading/Heading.types';

function getFontFamily(props: StyledSpanProps) {
  if (!props.sFont) return 'inherit';
  return theme.font[props.sFont];
}

function getColor(props: StyledSpanProps) {
  if (!props.sColor) return 'inherit';
  return theme.color[props.sColor];
}

function getFontSize(props: StyledSpanProps, mediaSize: string) {
  if (props.sSizes) {
    const [s, m, l] = props.sSizes;
    const params = { s, m, l };
    return `${params[mediaSize]}px`;
  }
  if (!props.sSize) return 'inherit';
  return `${theme.size[mediaSize][props.sSize]}px`;
}

function getFontWeight(props: StyledSpanProps) {
  if (!props.sFontWeight) return 'inherit';
  return theme.fontWeight[props.sFontWeight];
}

function getFontStyle(props: StyledSpanProps) {
  if (!props.sFontStyle) return 'inherit';
  return theme.fontStyle[props.sFontStyle];
}

function getFontStretch(props: StyledSpanProps) {
  if (!props.sFontStretch) return 'inherit';
  return theme.fontStretch[props.sFontStretch];
}

function getWhiteSpace(props: StyledHeadingProps) {
  if (!props.sWhiteSpace) return 'normal';
  return `${theme.whiteSpace[props.sWhiteSpace]}`;
}

function getTextTransform(props: StyledHeadingProps) {
  if (!props.sTextTransform) return 'inherit';
  return `${theme.textTransform[props.sTextTransform]}`;
}

export const StyledSpan = styled.span<StyledSpanProps>`
  font-family: ${(props) => getFontFamily(props)};
  color: ${(props) => getColor(props)};
  font-style: ${(props) => getFontStyle(props)};
  font-stretch: ${(props) => getFontStretch(props)};
  font-weight: ${(props) => getFontWeight(props)};
  font-size: ${(props) => getFontSize(props, mediaSizes.s)};
  line-height: ${({ sLineHeight }) => (sLineHeight ? sLineHeight * 100 : 120)}%;
  white-space: ${(props) => getWhiteSpace(props)};
  letter-spacing: ${({ sLetterSpacing }) => sLetterSpacing || 'inherit'};
  font-variant-numeric: ${(props) => (props.sDigital ? 'tabular-nums' : 'inherit')};
  text-transform: ${(props) => getTextTransform(props)};

  @media (${media.tablet}) {
    font-size: ${(props) => getFontSize(props, mediaSizes.m)};
  }

  @media (${media.desktop}) {
    font-size: ${(props) => getFontSize(props, mediaSizes.l)};
  }
`;
