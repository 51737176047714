// import PropTypes from "prop-types";
import React from 'react';
import './style.css';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { multilanguage } from 'redux-multilanguage';
import { ReactComponent as CookieImage } from '../../assets/images/cookie.svg';

function Cookie({ strings }) {
  return (
    <CookieConsent
      location="bottom"
      disableButtonStyles
      style={{
        zIndex: '99999',
        background: 'rgba(72, 1, 255, 0.88)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'self-start',
        padding: '12px 0px',
        wordWrap: 'break-word',
      }}
      contentClasses="contentStyle"
      contentStyle={{
        flex: 'none',
        flexShrink: '2',
        flexBasis: '0',
        margin: 'none',
      }}
      buttonWrapperClasses="buttonWrapperClasses"
      buttonText={strings?.Accept}
      buttonClasses="buttonCookie"
    >
      <CookieImage className="cookieImage" />
      <noindex>
        <span className="textStyle">
          {strings?.['Cookie Consent']?.[0]}{' '}
          <a href="/content/pages/cookies" className="cookieLink">
            {strings?.['Cookie Consent']?.[1]}
          </a>
          {strings?.['Cookie Consent']?.[2]}
        </span>
      </noindex>
    </CookieConsent>
  );
}

const mapStateToProperties = (state) => ({
  currentLanguageCode: state.multilanguage.currentLanguageCode,
});

const mapDispatchToProperties = () => ({});

export default connect(mapStateToProperties, mapDispatchToProperties)(multilanguage(Cookie));
