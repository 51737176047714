import { Dispatch } from 'redux';
import Geocode from 'react-geocode';
// import { changeLanguage } from 'redux-multilanguage';

import WebService from '../../utils/webService';
import constant from '../../utils/constant';
import { Country, IUserData } from '../types/user';

Geocode.setApiKey(window._env_.APP_MAP_API_KEY);
Geocode.setLanguage('en');

export const enum UserActions {
  SET_USER = 'SET_USER',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_SHIPPING_COUNTRY = 'SET_SHIPPING_COUNTRY',
  SET_STATE = 'SET_STATE',
  SET_SHIP_STATE = 'SET_SHIP_STATE',
  SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS',
}

export interface ISetUserAction {
  type: UserActions.SET_USER;
  payload: IUserData;
}

export interface ISetCountryAction {
  type: UserActions.SET_COUNTRY | UserActions.SET_SHIPPING_COUNTRY;
  payload: Country;
}

// export interface ISetShippingCountryAction extends Omit<ISetCountryAction, 'type'> {
//    type: UserActions.SET_SHIPPING_COUNTRY;
// }

export interface ISetStateAction {
  type: UserActions.SET_STATE | UserActions.SET_SHIP_STATE;
  payload: Country;
}

export interface ISetCurrentAddress {
  type: UserActions.SET_CURRENT_ADDRESS;
  payload: any[];
}

export const setUser = (data: IUserData) => async (dispatch: Dispatch<ISetUserAction>) => {
  dispatch({ type: UserActions.SET_USER, payload: data });
};

export const getCountry = (lang: string) => async (dispatch: Dispatch<ISetCountryAction>) => {
  try {
    const action = `${constant.ACTION.COUNTRY}?store=${window._env_.APP_MERCHANT}&lang=${lang}`;
    const response = await WebService.get(action);
    dispatch({ type: UserActions.SET_COUNTRY, payload: response });
  } catch {}
};

export const getShippingCountry = (lang: string) => async (dispatch: Dispatch<ISetCountryAction>) => {
  try {
    const action = `${constant.ACTION.SHIPPING_COUNTRY}?store=${window._env_.APP_MERCHANT}&lang=${lang}`;
    const response = await WebService.get(action);
    dispatch({ type: UserActions.SET_SHIPPING_COUNTRY, payload: response });
  } catch {}
};

export const getState = (code: string) => async (dispatch: Dispatch<ISetStateAction>) => {
  try {
    console.log('Calling get state'); // eslint-disable-line
    const action = `${constant.ACTION.ZONES}?code=${code}`;
    const response = await WebService.get(action);
    dispatch({ type: UserActions.SET_STATE, payload: response });
  } catch {}
};

export const getShippingState = (code: string) => async (dispatch: Dispatch<ISetStateAction>) => {
  try {
    const action = `${constant.ACTION.ZONES}?code=${code}`;
    const response = await WebService.get(action);
    dispatch({ type: UserActions.SET_SHIP_STATE, payload: response });
  } catch {}
};

export const getCurrentAddress = (lat: number, long: number) => async (dispatch: Dispatch<ISetCurrentAddress>) => {
  Geocode.fromLatLng(lat, long).then(
    (response) => {
      dispatch({
        type: UserActions.SET_CURRENT_ADDRESS,
        payload: response.results,
      });
    },
    (error) => {
      console.error(error); // eslint-disable-line
    },
  );
};

// @ts-ignore
export const getCurrentLocation = () => async (dispatch) => {
  const location = window.navigator && window.navigator.geolocation;
  // console.log(location, 'getCurrentLocation')
  if (location) {
    location.getCurrentPosition(
      (position) => {
        // console.log(position)
        dispatch(getCurrentAddress(position.coords.latitude, position.coords.longitude));
      },
      (error) => {
        console.log(error); // eslint-disable-line
      },
    );
  }
};
