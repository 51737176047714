import * as SockJS from 'sockjs-client';
// @ts-ignore
import * as Stomp from 'stompjs';

import { WS_URLS } from './constants';
import { getTokenExpTime } from '../../utils/auth';
import { STORAGE_KEYS } from '../../constants';

export default class Socket {
  static instance: any;

  wsoc: any;

  client: any;

  subscription: any;

  tokenKey: any;

  userEvent: any;

  isReconnect: any = false;

  onMessageReceivedItems: any = [];

  onConnectionSuccessItems: any = [];

  onConnectionErrorItems: any = [];

  constructor(tokenKey = STORAGE_KEYS.ACCESS_TOKEN) {
    this.client = this.init(tokenKey);
  }

  init = (tokenKey: string) => {
    this.tokenKey = tokenKey;

    const accessToken = localStorage.getItem(tokenKey);
    if (accessToken) {
      const expTime = getTokenExpTime(tokenKey);
      if (expTime <= 0) throw new Error('token is expired');

      const BASE_URL = process.env.REACT_APP_SHOPIZER_URL;
      this.wsoc = new SockJS(`${BASE_URL}/stompwebsocket?access_token=${accessToken}`);
      this.wsoc.addEventListener('open', () => console.log('ws connected')); // eslint-disable-line

      const client = Stomp.over(this.wsoc);
      if (process.env.NODE_ENV !== 'development') client.debug = null;
      return client;
    }
    return null;
  };

  static getInstance(tokenKey = STORAGE_KEYS.ACCESS_TOKEN) {
    if (Socket.instance) return Socket.instance;
    Socket.instance = new Socket(tokenKey);
    return Socket.instance;
  }

  static removeInstance() {
    Socket.instance = null;
    return Socket.instance;
  }

  connect = (params: any, connectionSuccess: any, connectionError: any) => {
    this.onConnectionSuccessItems.push(connectionSuccess);
    this.onConnectionErrorItems.push(connectionError);
    this.client?.connect(params, this.onConnectionSuccess, this.onConnectionError);
  };

  disconnect = () => {
    this.client?.disconnect();
    this.wsoc.close();
  };

  onConnectionSuccess = () => {
    for (const item of this.onConnectionSuccessItems) {
      item(this.isReconnect);
    }
    this.isReconnect = false;
  };

  onConnectionError = (error: string) => {
    const msg = `Whoops! Lost connection to ${this.wsoc?.url}`;

    if (error === msg) {
      setTimeout(this.reconnect, 5000);
      for (const item of this.onConnectionErrorItems) {
        if (item?.title) item.func(error);
      }
    } else {
      for (const item of this.onConnectionErrorItems) {
        if (item?.title) {
          item.func(error);
        } else {
          item(error);
        }
      }
    }
  };

  reconnect = () => {
    this.isReconnect = true;
    this.wsoc.close();
    this.client = this.init(this.tokenKey);
    this.client?.connect({}, this.onConnectionSuccess, this.onConnectionError);
  };

  subscribe = (userEvent: string, onMessageReceived?: any) => {
    this.userEvent = userEvent;
    if (onMessageReceived) this.onMessageReceivedItems.push(onMessageReceived);
    this.subscription = this.client.subscribe(
      WS_URLS.SUBSCRIBE_APPLICATION.replace(':userEvent', userEvent),
      this.handleMessageReceived,
    );
    return this.subscription;
  };

  unsubscribe = () => {
    this.subscription.unsubscribe();
  };

  addOnMessageReceived = (onMessageReceived: any) => {
    this.onMessageReceivedItems.push(onMessageReceived);
  };

  removeOnMessageReceived = (onMessageReceived: any) => {
    this.onMessageReceivedItems = this.onMessageReceivedItems.filter((item: any) => item !== onMessageReceived);
  };

  addOnConnectionSuccess = (onConnectionSuccess: any) => {
    this.onConnectionSuccessItems.push(onConnectionSuccess);
  };

  removeOnConnectionSuccess = (onConnectionSuccess: any) => {
    this.onConnectionSuccessItems = this.onConnectionSuccessItems.filter((item: any) => item !== onConnectionSuccess);
  };

  addOnConnectionError = (onConnectionError: any) => {
    this.onConnectionErrorItems.push(onConnectionError);
  };

  removeOnConnectionError = (onConnectionError: any) => {
    this.onConnectionErrorItems = this.onConnectionErrorItems.filter((item: any) => item !== onConnectionError);
  };

  handleMessageReceived = (response: any) => {
    try {
      if (response?.body) {
        const result = JSON.parse(response.body);
        for (const item of this.onMessageReceivedItems) {
          item(result);
        }
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };
}
