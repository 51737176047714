export function getValues(value: string, length: number) {
  return new Array(length).fill('').map((item, index) => value?.[index] || '');
}

export function formatCodeValue(index: number | undefined, value: string, values: string[]) {
  const fValue = value?.replace(/\D/g, '');
  return values.map((item, ind) => (ind === index ? fValue : item)).join('');
}

export function deformatCodeValue(value: string) {
  if (value === '') return null;
  return value.replace(/\D/g, '');
}

export const getLastIndex = (values: string[]) => {
  let result = 0;
  values.forEach((item, index) => {
    if (item) result = index;
  });
  return result;
};
