import { ISetContentAction, SET_CONTENT_ID } from '../actions/contentAction';

export interface IContent {
  contentId: string;
}

const initState: IContent = {
  contentId: '',
};

const contentReducer = (state = initState, action: ISetContentAction) => {
  if (action.type === SET_CONTENT_ID) {
    return { ...state, contentId: action.payload };
  }

  return state;
};

export default contentReducer;
