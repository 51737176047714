import { extendTheme } from '@chakra-ui/react';
import { textStyles } from './parts/text';
import { button } from './parts/button';
import { link } from './parts/link';
import { checkbox } from './parts/checkbox';
import { radio } from './parts/radio';
import { menu } from './parts/menu';

const theme = extendTheme({
  fonts: {
    heading: `'ALS Wagon', sans-serif`,
    body: `'ALS Wagon', sans-serif`,
    p: `'ALS Wagon', sans-serif`,
  },

  radii: {
    none: '0',
    sm: '8px',
    base: '8px',
    md: '8px',
    lg: '8px',
  },

  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20
    '2xl': '1.5rem', // 24
    '3xl': '1.875rem', // 30
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },

  textStyles,

  layerStyles: {
    base: {
      bg: 'gray.50',
      border: '2px solid',
      borderColor: 'gray.500',
      color: 'red',
    },
    selected: {
      bg: 'teal.500',
      color: 'teal.700',
      borderColor: 'orange.500',
    },
  },

  colors: {
    trueBlack: 'rgba(0, 0, 0, 1)',
    black: '#515151',
    black08: 'rgba(0, 0, 0, 0.8)',
    green10: '#00F7B6',
    coralRed: '#E43759',
    grey10: '#515151',
    grey06: '#666666',
    grey05: 'rgba(81, 81, 81, 0.5)',
    grey03: 'rgba(81, 81, 81, 0.3)',
    grey01: 'rgba(81, 81, 81, 0.1)',
    mediumGrey10: '#999999',
    lightGrey10: '#F4F4F4',
    ultraLightGrey10: '#F8F8F8',
    lightBlue: '#EDE9F8',
    blue10: '#4801FF',
    blue008: 'rgba(72, 1, 255, 0.08)',
    blueHover: '#674FFF',
    blueActive: '#3101AD',
    white10: '#FFFFFF',
    white064: 'rgba(255, 255, 255, 0.64)',
    white032: 'rgba(255, 255, 255, 0.32)',
    white024: 'rgba(255, 255, 255, 0.24)',
    red10: '#E43759',
    gradient1:
      'linear-gradient(87.44deg, #74F2BA 1.94%, #6CE4CF 12.28%, #62D1EE 21.68%, #57BCF9 32.52%, #4BA4F8 47.77%, #3B89F7 63%, #2864F6 80.39%, #3F20F5 97.67%)',
    gradientGreyWhite:
      'linear-gradient(0deg, #000000 32.8%, rgba(0, 0, 0, 0.991353) 37.28%, rgba(0, 0, 0, 0.96449) 41.76%, rgba(0, 0, 0, 0.91834) 46.24%, rgba(0, 0, 0, 0.852589) 50.72%, rgba(0, 0, 0, 0.768225) 55.2%, rgba(0, 0, 0, 0.668116) 59.68%, rgba(0, 0, 0, 0.557309) 64.16%, rgba(0, 0, 0, 0.442691) 68.64%, rgba(0, 0, 0, 0.331884) 73.12%, rgba(0, 0, 0, 0.231775) 77.6%, rgba(0, 0, 0, 0.147411) 82.08%, rgba(0, 0, 0, 0.0816599) 86.56%, rgba(0, 0, 0, 0.03551) 91.04%, rgba(0, 0, 0, 0.0086472) 95.52%, rgba(0, 0, 0, 0) 100%);',

    civiblue: {
      50: '#EDE5FF',
      100: '#CCB8FF',
      200: '#AA8AFF',
      300: '#895CFF',
      400: '#682EFF',
      500: '#4801FF', // из макета
      600: '#3900CC',
      700: '#2B0099',
      800: '#1D0066',
      900: '#0E0033',
    },

    civicyan: {
      50: '#E5FFF8',
      100: '#B8FFEC',
      200: '#8AFFE0',
      300: '#5CFFD4',
      400: '#2EFFC8',
      500: '#00f7b6', // из макета
      600: '#00CC96',
      700: '#009971',
      800: '#00664B',
      900: '#003326',
    },

    gray: {
      50: '#F2F2F2',
      100: '#DBDBDB',
      200: '#C4C4C4',
      300: '#ADADAD',
      400: '#999999', // из макета ?
      500: '#979797', // из макета #515151 0.6
      600: '#666666',
      700: '#4D4D4D',
      800: '#515151', // из макета
      900: '#1A1A1A',
    },
  },

  checkbox: {
    sizes: {
      lg: '24px',
    },
  },

  styles: {
    global: {
      '.chakra-checkbox__control': {
        borderRadius: '4px !important',
        height: '24px !important',
        width: '24px !important',
      },
      '.chakra-checkbox__control svg': {
        width: '14px !important',
      },
      body: {
        bg: '',
      },
      '.chakra-modal__overlay': {
        zIndex: '99998 !important',
      },
      '.chakra-modal__content-container': {
        zIndex: '99999 !important',
      },
    },
  },

  components: {
    Heading: {
      variants: {
        condensed: {
          fontStretch: 'condensed',
        },
        semiCondensed: {
          fontStretch: 'semi-condensed',
        },
        expanded: {
          fontStretch: 'expanded',
        },
      },
      defaultProps: {
        variant: 'condensed',
      },
    },

    Checkbox: checkbox,

    Radio: radio,

    Input: {
      baseStyle: {
        field: {
          pt: ['14px', '14px', 4, 4, 4],
          px: ['9px !important', '9px !important'],
          fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
          fontSize: '16px !important',
        },
      },
      sizes: {
        lg: {
          field: {
            fontSize: 'lg',
            px: '3',
            h: '14',
            borderRadius: 'md',
          },
        },
        sm: {
          field: {
            pt: 0,
          },
        },
      },
      variants: {
        outline: {
          field: {
            bg: '#F4F4F4 !important',
            border: 'none',
          },
        },
      },
    },

    NumberInput: {
      baseStyle: {
        field: {
          pt: ['14px', '14px', 4, 4, 4],
          px: ['9px !important', '9px !important'],
          fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
          fontSize: '16px !important',
        },
      },
      variants: {
        outline: {
          field: {
            background: '#F4F4F4',
            border: 'none',
          },
        },
      },
      // baseStyle: {
      //   outline: {
      //     field: {
      //       background: "red !important",
      //       borderRadius: 0
      //     }
      //   }
      // },
    },

    Button: button,

    IconButton: button,

    Link: link,

    Menu: menu,

    Table: {
      sizes: {
        sm: {
          th: {
            px: '4',
            py: '1',
            lineHeight: '4',
            fontSize: 'md',
          },
          td: {
            px: '2',
            py: '2',
            fontSize: 'md',
            lineHeight: '4',
          },
          caption: {
            fontSize: 'md',
          },
        },
      },
    },

    Breadcrumb: {
      baseStyle: {
        separator: {
          mt: [1.5, 1.5, 3.5, 10],
          color: 'mediumGrey10',
        },
        link: {
          mt: [2, 2, 4, 10],
          color: 'mediumGrey10',
          fontSize: ['xxs', 'xs', 'sm', 'md'],
          fontWeight: 500,
          lineHeight: '105%',
          letterSpacing: '0.05em',

          _hover: {
            color: '#4f4f4f',
            textDecoration: 'none',
          },
          _focusVisible: {
            boxShadow: 'outline',
          },
        },
      },

      parts: ['link', 'separator'],
      variants: {
        secondary: {
          separator: {
            mt: [1.5, 1.5, 3.5, 10],
            color: 'rgba(255, 255, 255, 0.64)',
          },
          link: {
            mt: [2, 2, 4, 10],
            fontSize: ['xxs', 'xs', 'sm', 'md'],
            color: 'rgba(255, 255, 255, 0.64)',
            fontWeight: 500,
            _hover: {
              color: 'rgba(255, 255, 255, 1)',
            },
          },
        },
      },
    },
  },
});

export default theme;
