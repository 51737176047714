import React from 'react';

import { HeadingProps } from './Heading.types';
import { StyledHeader } from './style';

const H1: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h1',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    children,
    textTransform,
  } = props;

  return (
    <StyledHeader
      as={as}
      sSize="xl"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
    >
      {children}
    </StyledHeader>
  );
};

const H2: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h2',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    textTransform,
    children,
  } = props;
  return (
    <StyledHeader
      as={as}
      sSize="l"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
    >
      {children}
    </StyledHeader>
  );
};

const H3: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h3',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    textTransform,
    children,
  } = props;
  return (
    <StyledHeader
      as={as}
      sSize="m"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
    >
      {children}
    </StyledHeader>
  );
};

const H4: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h4',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    textTransform,
    children,
  } = props;
  return (
    <StyledHeader
      as={as}
      sSize="s"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
    >
      {children}
    </StyledHeader>
  );
};

const H5: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h5',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    textTransform,
    children,
    ...rest
  } = props;
  return (
    <StyledHeader
      as={as}
      sSize="xs"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
      {...rest}
    >
      {children}
    </StyledHeader>
  );
};

const H6: React.FC<HeadingProps> = (props) => {
  const {
    as = 'h6',
    font,
    color,
    sizes,
    lineHeight,
    fontWeight,
    fontStyle,
    fontStretch,
    textAlign,
    whiteSpace,
    textTransform,
    children,
  } = props;
  return (
    <StyledHeader
      as={as}
      sSize="xxs"
      sFont={font}
      sColor={color}
      sSizes={sizes}
      sLineHeight={lineHeight}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sTextAlign={textAlign}
      sWhiteSpace={whiteSpace}
      sTextTransform={textTransform}
    >
      {children}
    </StyledHeader>
  );
};

export default { H1, H2, H3, H4, H5, H6 };
