import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

import { LoaderProps } from './types';
import spinner from './spinner.json';

let timer: any;
const Loader: React.FC<LoaderProps> = ({
  isLoading = true,
  size = 40,
  position = 'fixed',
  delay = 3,
  top = 'auto',
}) => {
  const [mounted, setMounted] = useState(!delay);

  const onSetMounted = () => setMounted(true);
  useEffect(() => {
    if (!mounted && isLoading) {
      timer = setTimeout(onSetMounted, delay * 1000);
    }
    return () => {
      if (mounted) {
        clearTimeout(timer);
        setMounted(false);
      }
    };
  }, [isLoading]);

  let style = {
    display: mounted && isLoading ? 'block' : 'none',
    maxWidth: size,
    maxHeight: size,
    position,
    top: top,
    left: 'auto',
    transform: 'none',
    zIndex: '9999',
  };
  if (['fixed', 'absolute'].includes(position)) {
    style = {
      ...style,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }

  return (
    <div id="loader" style={style}>
      <Lottie animationData={spinner} loop />
    </div>
  );
};

export default Loader;
