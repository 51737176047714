import React from 'react';
import { types, Instance } from 'mobx-state-tree';
import { AlertProps } from './types';

export const Store = types
  .model({
    mounted: types.boolean,
  })

  .volatile(() => ({
    toast: null,
  }))

  .actions((self) => {
    const mount = (toast: any) => {
      self.toast = toast;
      self.mounted = true;
    };

    const unmount = () => {
      self.mounted = false;
      self.toast = null;
    };

    const add = ({ type, message }: AlertProps) => {
      self.toast.addToast(message, { appearance: type });
    };

    return {
      mount,
      unmount,
      add,
    };
  });

export const store = Store.create({
  mounted: false,
});

export const StoreContext = React.createContext(store);

export const useStore = () => React.useContext(StoreContext);

export type IStore = Instance<typeof Store>;
