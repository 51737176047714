import dayjs from 'dayjs';
import moonAlgorithm from './moonAlgorithm';

import typeChecker from './typeChecker';

export const constraintsTypes = {
  required: 'required',
  requiredByCondition: 'requiredByCondition',
  enum: 'enum',
  pattern: 'pattern',
  includes: 'includes',
  equal: 'equal',
  range: 'range',
  yearRange: 'yearRange',
  dateRange: 'dateRange',
  moon: 'moon',
  custom: 'custom',
};

export default function checkConstraints({ value, constraints, otherValues, requireConditions }) {
  if (!constraints) return null;

  for (const constrain of constraints) {
    if (constrain.type === constraintsTypes.required) {
      if (typeChecker.isBoolean(value) && !value) return constrain.message;
      if (typeChecker.isEmpty(value)) return constrain.message;
    }

    if (constrain.type === constraintsTypes.requiredByCondition && requireConditions[constrain.condition]) {
      if (typeChecker.isBoolean(value) && !value) return constrain.message;
      if (typeChecker.isEmpty(value)) return constrain.message;
    }

    if (constrain.type === constraintsTypes.enum && typeChecker.isEmpty(value)) return constrain.message;

    if (constrain.type === constraintsTypes.pattern) {
      const regExp = new RegExp(`^${constrain.condition}$`);
      if (value && !regExp.test(value)) return constrain.message;
    }

    if (constrain.type === constraintsTypes.includes) {
      const regExp = new RegExp(`${constrain.condition}`);
      if (value && !regExp.test(value)) return constrain.message;
    }

    if (constrain.type === constraintsTypes.range) {
      const { min, max } = constrain.condition;
      if (!typeChecker.isEmpty(value) && typeChecker.isNumber(value)) {
        if (typeChecker.isNumber(min) && value < min) return constrain.message;
        if (typeChecker.isNumber(max) && value > max) return constrain.message;
      } else if (value && typeChecker.isString(value)) {
        if (typeChecker.isNumber(min) && value.length < min) return constrain.message;
        if (typeChecker.isNumber(max) && value.length > max) return constrain.message;
      }
    }

    if (constrain.type === constraintsTypes.yearRange) {
      const { min, max } = constrain.condition;
      const dayjsValue = dayjs(value);
      const today = dayjs();
      const minDate = typeChecker.isNumber(min) ? today.clone().subtract(min, 'years') : null;
      const maxDate = typeChecker.isNumber(max) ? today.clone().subtract(max, 'years') : null;
      if (dayjsValue && minDate && dayjsValue.isAfter(minDate)) return constrain.message;
      if (dayjsValue && maxDate && dayjsValue.isBefore(maxDate)) return constrain.message;
    }

    if (constrain.type === constraintsTypes.equal && value && value !== otherValues[constrain.condition])
      return constrain.message;

    if (constrain.type === constraintsTypes.dateRange) {
      const { min, max, format } = constrain.condition;
      const dayjsValue = dayjs(value, format);
      const minDate = typeof min === 'string' ? dayjs(min, format) : dayjs(min);
      const maxDate = typeof max === 'string' ? dayjs(max, format) : dayjs(max);

      if (!dayjsValue.isBetween(minDate, maxDate, null, '[]')) {
        return constrain.message;
      }
    }
    if (constrain.type === constraintsTypes.moon && !moonAlgorithm(value)) {
      return constrain.message;
    }

    if (constrain.type === constraintsTypes.custom && constrain.error({ value, values: otherValues, constrain })) {
      return constrain.message;
    }
  }
  return null;
}
