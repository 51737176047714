import { AxiosInstance } from 'axios';

import { createInstance } from 'api/axios';

type DadataApi = Readonly<{
  baseUrl: string;
  token: string;
}>;

let apiInstance: AxiosInstance;

let apiParams: Readonly<{ token?: string }> = { token: undefined };

export function configureApiInstance(params: DadataApi) {
  apiInstance = createInstance(params.baseUrl);
  apiParams = { token: params.token };
}

export function getApiInstance() {
  return apiInstance;
}

export function getApiHeader() {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json', /* eslint-disable-line */
    'Authorization': `Token ${apiParams.token}`, /* eslint-disable-line */
  };
}
