import { constraintsTypes } from 'utils/checkConstraints';

export const FIELDS = {
  PHONE: 'phone',
};

export const CONSTRAINTS = {
  [FIELDS.PHONE]: [
    {
      type: constraintsTypes.required,
      message: 'phoneRequired',
    },
    {
      type: constraintsTypes.pattern,
      message: 'phonePattern',
      condition: '\\+7[1-9][0-9]{9}',
    },
  ],
};
