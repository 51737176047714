import { SET_LOADER, IAction } from '../actions/loaderActions';

interface ILoader {
  isLoading: boolean;
}

const initState: ILoader = {
  isLoading: false,
};

const loaderReducer = (state = initState, action: IAction) => {
  if (action.type === SET_LOADER) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  return state;
};

export default loaderReducer;
