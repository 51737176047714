const searchParams = new URLSearchParams(window.location.search);
const isMobileApp =
  localStorage.getItem('isMobileApp') === 'true' ||
  searchParams.has('mobileapp') ||
  /\/mobile\//.test(window.location.href);

export function gtmEvent(params: any) {
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push({
    platform: isMobileApp ? 'app' : 'site',
    ...(localStorage.getItem('uph') && { uph: localStorage.getItem('uph') }),
    ...(localStorage.getItem('uem') && { uph: localStorage.getItem('uem') }),
    ...params,
  });
}
