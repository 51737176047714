import { mediaSizes, colors } from 'components/constants';

export default {
  colors: { ...colors },
  types: {
    iconWhite: {
      normal: colors.white,
      hover: colors.white64,
    },
    iconLogo: {
      normal: colors.blue,
      hover: '#674FFF',
    },
  },
  sizes: {
    [mediaSizes.s]: { s: [125, 50], m: [95, 68], l: [128, 52] },
    [mediaSizes.m]: { s: [125, 50], m: [120, 68], l: [167, 68] },
    [mediaSizes.l]: { s: [125, 50], m: [167, 68], l: [200, 82] },
  },
};
