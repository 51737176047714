// @ts-nocheck
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';

import rootReducer from './redux/reducers/rootReducer';
import * as serviceWorker from './serviceWorker';
import './assets/scss/style.scss';
import App from './App';
import Fonts from './styles/global';
import theme from './styles/theme';
import './styles/style.scss';

import { store as mstStore, StoreContext } from './store';

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

if (/civimart.ru$|rancher-test.vsegda.da$/.test(window.location.hostname)) {
  const isProd = window.location.hostname === 'civimart.ru';
  Sentry.init({
    // dsn: isProd
    //   ? 'https://30321f2651ac4a8d880a23e74b11f6b5@o4504405759361024.ingest.sentry.io/4504405761196032'
    //   : 'https://f33b7fab3c84474b94de929aa6c151d0@sentry-test.vsegda.da/5',
    dsn: 'https://30321f2651ac4a8d880a23e74b11f6b5@o4504405759361024.ingest.sentry.io/4504405761196032',
    integrations: [new Integrations.BrowserTracing()],
    environment: isProd ? 'prod' : window.location.hostname.match(/^cm-shopizer-shop-reactjs\.([^.]+)/)?.[1],
    tracesSampleRate: 1,
  });
}

const searchParams = new URLSearchParams(window.location.search);
const reduxData = load();
if (reduxData?.productData?.categoryid) {
  Object.assign(reduxData.productData, {
    categoryid: searchParams.get('categoryIds') ?? reduxData?.productData?.categoryid,
  });
}

const store = createStore(
  rootReducer,
  reduxData,
  composeWithDevTools(applyMiddleware(thunk, save({ ignoreStates: ['error', 'settings', 'cartData', 'goTo'] }))),
);

smoothscroll.polyfill();
const container = document.querySelector('#root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <StoreContext.Provider value={mstStore}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <App />
      </ChakraProvider>
    </StoreContext.Provider>
  </Provider>,
);

serviceWorker.unregister();
