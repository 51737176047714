import {
  mediaSizes,
  fonts,
  colors,
  fontWeights,
  fontStyles,
  textAligns,
  fontStretches,
  whiteSpace,
  textTransform,
} from 'components/constants';

export default {
  font: { ...fonts },
  color: { ...colors },
  fontWeight: { ...fontWeights },
  fontStyle: { ...fontStyles },
  fontStretch: { ...fontStretches },
  textAlign: { ...textAligns },
  whiteSpace: { ...whiteSpace },
  textTransform: { ...textTransform },
  size: {
    [mediaSizes.s]: { xxs: 22, xs: 26, s: 28, m: 30, l: 32, xl: 32 },
    [mediaSizes.m]: { xxs: 26, xs: 30, s: 32, m: 44, l: 56, xl: 64 },
    [mediaSizes.l]: { xxs: 32, xs: 36, s: 48, m: 64, l: 80, xl: 144 },
  },
  lineHeight: {
    [mediaSizes.s]: { xxs: 22, xs: 26, s: 28, m: 30, l: 32, xl: 32 },
    [mediaSizes.m]: { xxs: 26, xs: 30, s: 32, m: 44, l: 56, xl: 64 },
    [mediaSizes.l]: { xxs: 32, xs: 36, s: 48, m: 64, l: 80, xl: 144 },
  },
};
