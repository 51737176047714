import React from 'react';
import { CSSTransition } from 'react-transition-group';

import isMobile from 'utils/isMobile';

import { ComponentProps } from './types';
import { StyledModalTransition } from './style';
import ModalIos from '../ModalIos';

const Modal: React.FC<ComponentProps> = (props) => {
  const { isOpened, duration = 200, draggable, exitOpacity = 1, children, ...rest } = props;
  const mobile = isMobile();

  if (draggable && mobile) return <ModalIos {...props}>{children}</ModalIos>;

  return (
    <CSSTransition in={isOpened} classNames="modal-c" unmountOnExit timeout={duration}>
      <StyledModalTransition sDuration={duration} sExitOpacity={exitOpacity} {...rest}>
        {children}
      </StyledModalTransition>
    </CSSTransition>
  );
};

export default Modal;
