import { URLS } from './constants';

import { getApiInstance } from '../axios';
import { getAuthHeaders } from '../utils';
import { ApiUpdateCustomerData } from './types';

function getProfile(anyToken?: boolean) {
  return getApiInstance()({
    headers: getAuthHeaders(anyToken),
    method: 'GET',
    url: URLS.PROFILE,
  });
}

function updateCustomerData(data: ApiUpdateCustomerData) {
  return getApiInstance()({
    headers: getAuthHeaders(true),
    method: 'PATCH',
    url: URLS.UPDATE,
    data,
  });
}

export default {
  getProfile,
  updateCustomerData,
};
