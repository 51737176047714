export const WS_URLS = {
  SUBSCRIBE_APPLICATION: '/exchange/websocket.cm-shopizer.events.exchange/EVENT.USER.:userEvent',
};

export const ENTITY_TYPES = {
  OFFER: 'OFFER',
  REPORT: 'REPORT',
  ORDER: 'ORDER',
  SELL_ORDER: 'SELL_ORDER',
  VISUAL_TEST: 'VISUAL_TEST',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  SELL_PHONE: 'SELL_PHONE',
  WITHDRAWAL: 'WITHDRAWAL',
  PURCHASE: 'PURCHASE',
};

export const EVENT_TYPES = {
  CREATED: 'CREATED',
  SESSION_START: 'SESSION_START',
  CHANGE_VISUAL_DIAGNOSTIC_GROUP: 'CHANGE_VISUAL_DIAGNOSTIC_GROUP',
  VISUAL_TEST_PREPARED: 'VISUAL_TEST_PREPARED',
  WAITING_RESULT: 'WAITING_RESULT',
  SUCCESS_DIAGNOSTIC: 'SUCCESS_DIAGNOSTIC',
  OFFER_PRICE_RECEIVED: 'OFFER_PRICE_RECEIVED',
  MESSAGE_FROM_OPERATOR: 'MESSAGE_FROM_OPERATOR',
  START_CHAT: 'START_CHAT',
  FRAUD: 'FRAUD',
  CLIENT_REFUSED: 'CLIENT_REFUSED',
  DIAGNOSTIC_SESSION_TERMINATED: 'DIAGNOSTIC_SESSION_TERMINATED',
  REPORT_READY_FOR_PAYMENT: 'REPORT_READY_FOR_PAYMENT',
  CARD_READY_FOR_REGISTER: 'CARD_READY_FOR_REGISTER',
  INAPPROPRIATE_CUSTOMER: 'INAPPROPRIATE_CUSTOMER',
  INAPPROPRIATE_WITHDRAWAL_STATUS: 'INAPPROPRIATE_WITHDRAWAL_STATUS',
  GET_TOKEN_ERROR: 'GET_TOKEN_ERROR',
  CARD_ENROLL_FAILED: 'CARD_ENROLL_FAILED',
  WITHDRAWAL_FAILED: 'WITHDRAWAL_FAILED',
  TIME_IS_OUT: 'TIME_IS_OUT',
  TECHNICAL_ERROR: 'TECHNICAL_ERROR',
  PROCEED: 'PROCEED',
  OK: 'OK',
  ORDER_READY_FOR_PAYMENT: 'ORDER_READY_FOR_PAYMENT',
  DELIVERY_ACCEPTED: 'DELIVERY_ACCEPTED',
  REPORT_FORMED: 'REPORT_FORMED',
  REPORT_FAILED: 'REPORT_FAILED',
  MOBILE_READY_TO_PROCEED: 'MOBILE_READY_TO_PROCEED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CREATE_ORDER_IN_PARTNER_SYSTEM_FAILED: 'CREATE_ORDER_IN_PARTNER_SYSTEM_FAILED',
  OFFER_NOT_RECEIVED: 'OFFER_NOT_RECEIVED',
  PURCHASE_FAILED: 'PURCHASE_FAILED',
  SELL_FAILED: 'SELL_FAILED',
  GET_CLIENT_ERROR: 'GET_CLIENT_ERROR',
  GET_MERCHANT_ERROR: 'GET_MERCHANT_ERROR',
  CREATE_DELIVERY_ORDER_ERROR: 'CREATE_DELIVERY_ORDER_ERROR',
  CALL_COURIER_ERROR: 'CALL_COURIER_ERROR',
  SAVE_DELIVERY_ORDER_ATTRIBUTES_ERROR: 'SAVE_DELIVERY_ORDER_ATTRIBUTES_ERROR',
  CALLBACK_NOT_RECEIVED: 'CALLBACK_NOT_RECEIVED',
  ORDER_READY_FOR_CREDIT_PAYMENT: 'ORDER_READY_FOR_CREDIT_PAYMENT',
  PAY_REJECTED: 'PAY_REJECTED',
  PAY_REFUSED: 'PAY_REFUSED',
  CANCELED: 'CANCELED',
  WAIT_APPROVE: 'WAIT_APPROVE',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
};

export const EVENTS = {
  VISUAL_TEST_PREPARED: 'VISUAL_TEST_PREPARED',
  WAITING_RESULT: 'WAITING_RESULT',
  MESSAGE_EVENT: 'MESSAGE_EVENT',
  FAILED_EVENT: 'FAILED_EVENT',
  SUCCESS_EVENT: 'SUCCESS_EVENT',
  VISUAL_DIAGNOSTIC_SESSION_TERMINATED: 'VISUAL_DIAGNOSTIC_SESSION_TERMINATED',
  START_CHAT_EVENT: 'START_CHAT_EVENT',
  VISUAL_DIAGNOSTIC_SUCCESS: 'VISUAL_DIAGNOSTIC_SUCCESS',
  VISUAL_DIAGNOSTIC_START: 'VISUAL_DIAGNOSTIC_START',
  ORDER_READY_FOR_PAYMENT: 'ORDER_READY_FOR_PAYMENT',
};

export const EVENT_PARAMS = {
  SELL_SESSION_ID: 'sellSessionId',
  CONTEXT_ID: 'contextId',
};

export const EVENTS_LIST = [
  {
    id: EVENTS.VISUAL_TEST_PREPARED,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST],
    eventTypes: [EVENT_TYPES.VISUAL_TEST_PREPARED],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.WAITING_RESULT,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST],
    eventTypes: [EVENT_TYPES.WAITING_RESULT],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.MESSAGE_EVENT,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST, ENTITY_TYPES.ORDER, ENTITY_TYPES.CHAT_MESSAGE],
    eventTypes: [EVENT_TYPES.MESSAGE_FROM_OPERATOR, EVENT_TYPES.CREATED],
    context: EVENT_PARAMS.CONTEXT_ID,
  },
  {
    id: EVENTS.FAILED_EVENT,
    entityTypes: [
      ENTITY_TYPES.VISUAL_TEST,
      ENTITY_TYPES.ORDER,
      ENTITY_TYPES.PURCHASE,
      ENTITY_TYPES.SELL_PHONE,
      ENTITY_TYPES.WITHDRAWAL,
      ENTITY_TYPES.REPORT,
    ],
    eventTypes: [
      EVENT_TYPES.PAYMENT_FAILED,
      EVENT_TYPES.CREATE_ORDER_IN_PARTNER_SYSTEM_FAILED,
      EVENT_TYPES.FRAUD,
      EVENT_TYPES.CLIENT_REFUSED,
      EVENT_TYPES.OFFER_NOT_RECEIVED,
      EVENT_TYPES.PURCHASE_FAILED,
      EVENT_TYPES.SELL_FAILED,
      EVENT_TYPES.GET_CLIENT_ERROR,
      EVENT_TYPES.GET_MERCHANT_ERROR,
      EVENT_TYPES.CREATE_DELIVERY_ORDER_ERROR,
      EVENT_TYPES.CALL_COURIER_ERROR,
      EVENT_TYPES.SAVE_DELIVERY_ORDER_ATTRIBUTES_ERROR,
      EVENT_TYPES.OK,
      EVENT_TYPES.INAPPROPRIATE_CUSTOMER,
      EVENT_TYPES.INAPPROPRIATE_WITHDRAWAL_STATUS,
      EVENT_TYPES.TIME_IS_OUT,
      EVENT_TYPES.CARD_ENROLL_FAILED,
      EVENT_TYPES.GET_TOKEN_ERROR,
      EVENT_TYPES.WITHDRAWAL_FAILED,
      EVENT_TYPES.TECHNICAL_ERROR,
      EVENT_TYPES.REPORT_FORMED,
      EVENT_TYPES.REPORT_FAILED,
      EVENT_TYPES.CALLBACK_NOT_RECEIVED,
      EVENT_TYPES.PAYMENT_FAILED,
      EVENT_TYPES.PAYMENT_FAILED,
    ],
    context: EVENT_PARAMS.CONTEXT_ID,
  },
  {
    id: EVENTS.SUCCESS_EVENT,
    entityTypes: [ENTITY_TYPES.SELL_ORDER],
    eventTypes: [EVENT_TYPES.DELIVERY_ACCEPTED],
    context: EVENT_PARAMS.CONTEXT_ID,
  },
  {
    id: EVENTS.VISUAL_DIAGNOSTIC_SESSION_TERMINATED,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST],
    eventTypes: [EVENT_TYPES.DIAGNOSTIC_SESSION_TERMINATED],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.START_CHAT_EVENT,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST],
    eventTypes: [EVENT_TYPES.START_CHAT],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.VISUAL_DIAGNOSTIC_SUCCESS,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST, ENTITY_TYPES.OFFER],
    eventTypes: [
      EVENT_TYPES.SUCCESS_DIAGNOSTIC,
      EVENT_TYPES.OFFER_PRICE_RECEIVED,
      EVENT_TYPES.START_CHAT,
      EVENT_TYPES.OFFER_ACCEPTED,
    ],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.VISUAL_DIAGNOSTIC_START,
    entityTypes: [ENTITY_TYPES.VISUAL_TEST],
    eventTypes: [EVENT_TYPES.SESSION_START, EVENT_TYPES.PROCEED, EVENT_TYPES.MOBILE_READY_TO_PROCEED],
    context: EVENT_PARAMS.SELL_SESSION_ID,
  },
  {
    id: EVENTS.ORDER_READY_FOR_PAYMENT,
    entityTypes: [ENTITY_TYPES.ORDER, ENTITY_TYPES.REPORT, ENTITY_TYPES.WITHDRAWAL],
    eventTypes: [
      EVENT_TYPES.ORDER_READY_FOR_PAYMENT,
      EVENT_TYPES.REPORT_READY_FOR_PAYMENT,
      EVENT_TYPES.CARD_READY_FOR_REGISTER,
    ],
    context: EVENT_PARAMS.CONTEXT_ID,
  },
];
