import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import getIsMobileApp from 'utils/getIsMobileApp';
import { setMobileNativeEvent } from 'utils/setMobileNativeEvent';
import typeChecker from 'utils/typeChecker';

// import { zipkin } from './zipkin';
import { getToken, removeTokens, setTokens } from './utils';
import { STORAGE_KEYS } from '../constants';
import { channels, mobileNativeEvents, URLS } from './constants';
import { RefreshTokenParams } from './types';
import { getSettings } from 'utils/helper';

const isMobileApp = getIsMobileApp();
let apiInstance: AxiosInstance = null;
let authApiInstance: AxiosInstance = null;
let isRefreshing = false;
let refreshSubscribers: any[] = [];

function processQueue(error: any, token: string = null) {
  refreshSubscribers.forEach((item) => {
    if (error) {
      item.reject(error);
    } else {
      item.resolve(token);
    }
  });

  refreshSubscribers = [];
}

function refreshTokenRequest(params: RefreshTokenParams) {
  return apiInstance({
    method: 'POST',
    baseURL: process.env.REACT_APP_AUTH_URL,
    url: URLS.REFRESH_TOKEN,
    params,
  });
}

export function refreshMobileToken() {
  return new Promise((resolve, reject) => {
    const accessToken = setMobileNativeEvent(mobileNativeEvents.refreshToken);

    if (accessToken && typeChecker.isString(accessToken)) {
      setTokens({ accessToken });
      resolve(accessToken);
    } else {
      removeTokens();
      reject(new Error('Do not receive new token'));
    }
  });
}

export async function refreshToken() {
  try {
    if (isMobileApp) {
      return await refreshMobileToken();
    }
    const settings = getSettings();
    const isChannel = Object.keys(channels).some((el) => channels[el] === settings.channel);
    const user_type = isChannel ? 'admin' : 'customer';
    const params = {
      grant_type: 'refresh_token',
      user_type: user_type,
      refresh_token: getToken(STORAGE_KEYS.REFRESH_TOKEN),
    };
    const result = await refreshTokenRequest(params);
    if (!result?.data?.access_token) {
      removeTokens();
      throw new Error('Do not receive new token');
    }
    setTokens({ accessToken: result?.data?.access_token, refreshToken: result?.data?.refresh_token });
    return result?.data?.access_token;
  } catch (e: any) {
    if (e?.response?.status === 401) removeTokens();
    throw e;
  }
}

export function handleReject(error: any) {
  const refreshtoken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (refreshtoken === 'undefined') localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  const canRefreshToken = !!localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (!error?.response || !canRefreshToken) return Promise.reject(error);
  const {
    config,
    response: { status },
  } = error;
  const originalRequest = config;

  if (status === 401 && !originalRequest.retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshSubscribers.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest.retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      refreshToken()
        .then((newToken) => {
          originalRequest.headers.authorization = `Bearer ${newToken}`;
          processQueue(null, newToken);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
}

export const getCancelToken = (cancelToken: CancelTokenSource) => {
  if (cancelToken) cancelToken.cancel('canceled');
  return axios.CancelToken.source();
};

export function createInstance(baseURL: string) {
  const instance = axios.create({
    validateStatus: (status) => status >= 200 && status < 400,
    baseURL,
  });

  instance.interceptors.request.use(
    async (config) => {
      config.headers.common['mindboxEndpointId'] = localStorage.getItem('mindboxEndpointId'); /* eslint-disable-line */
      config.headers.common['mindboxDeviceId'] = localStorage.getItem('mindboxDeviceUUID'); /* eslint-disable-line */
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => handleReject(error),
  );

  return instance;
}

const BASE_URL = process.env.REACT_APP_SHOPIZER_URL + window._env_.APP_API_VERSION;
// apiInstance = zipkin(createInstance(BASE_URL)); // eslint-disable-line
apiInstance = createInstance(BASE_URL);
authApiInstance = createInstance(process.env.REACT_APP_AUTH_URL);

export function getApiInstance() {
  return apiInstance;
}

export function getAuthApiInstance() {
  return authApiInstance;
}
