import styled from 'styled-components';
import { media } from 'components/constants';
import { StyledGridProps, StyledGridItemProps } from './Grid.types';
import theme from './theme';

const isNumber = (value: any) => typeof value === 'number' && !Number.isNaN(value);

function getMargin(props: StyledGridProps) {
  if (!props.sSpacing) return '0';
  return `-${theme.spacing[props.sSpacing]}px`;
}

function getItemPadding(props: StyledGridItemProps) {
  if (!props.sGridSpacing) return '0';
  return `${theme.spacing[props.sGridSpacing]}px`;
}

function getItemWidth(props: StyledGridItemProps) {
  let computedSize = props.sSize;
  if (isNumber(props.sTablet)) computedSize = props.sTablet;
  if (isNumber(props.sDesktop)) computedSize = props.sDesktop;
  return props.sGridSize && computedSize ? `${(computedSize / props.sGridSize) * 100}%` : 'auto';
}

export const StyledGrid = styled.div<StyledGridProps>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: row ${(props) => (props.sNoWrap ? 'nowrap' : 'wrap')};
  justify-content: ${(props) => theme.justifyContent[props.sJustifyContent]};
  align-items: ${(props) => theme.alignItems[props.sAlignItems]};
  flex-direction: ${(props) => theme.flexDirection[props.sFlexDirection]};
  margin: ${(props) => getMargin(props)};
  width: ${(props) => (props.width ? props.width : 'inherit')};
`;

export const StyledGridItem = styled.div<StyledGridItemProps>`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: ${(props) => getItemWidth({ ...props, sTablet: null, sDesktop: null })};
  flex-grow: ${(props) => (props.sGrow ? 1 : 'initial')};
  padding: ${(props) => getItemPadding(props)};

  @media (${media.tablet}) {
    width: ${(props) => getItemWidth({ ...props, sDesktop: null })};
  }

  @media (${media.desktop}) {
    width: ${(props) => getItemWidth(props)};
  }
`;
