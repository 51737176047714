import { constraintsTypes } from 'utils/checkConstraints';

export const FIELDS = {
  CODE: 'code',
  CONSENT: 'consent',
  USER_AGREEMENT: 'userAgreement',
};

export const CONSTRAINTS = {
  [FIELDS.CODE]: [
    {
      type: constraintsTypes.required,
      message: 'codeRequired',
    },
    {
      type: constraintsTypes.range,
      condition: { min: 4, max: 4 },
      message: 'codeRange',
    },
  ],
  [FIELDS.CONSENT]: [
    {
      type: constraintsTypes.required,
      message: 'consentRequired',
    },
  ],
};

export const CONSENTS = [
  {
    type: 'USER_AGREEMENT',
    sourceCode: 'CIVIMART',
    consentType: 'SMS_CONFIRMED_TEXT',
  },
  {
    type: 'CONSENT',
    sourceCode: 'CIVIMART',
    consentType: 'SMS_CONFIRMED_TEXT',
  },
  {
    type: 'ADVERTISING',
    sourceCode: 'CIVIMART',
    consentType: 'SMS_CONFIRMED_TEXT',
  },
];
