import { colors, mediaSizes } from 'components/constants';

export default {
  background: colors.blue,
  logo: {
    padding: {
      [mediaSizes.s]: '8px 8px',
      [mediaSizes.m]: '40px 40px',
      [mediaSizes.l]: '50px 50px',
    },
  },
  body: {
    code: {
      textStrokeColor: colors.green,
    },
    title: {
      padding: {
        [mediaSizes.s]: '4px 14px',
        [mediaSizes.m]: '12px 16px',
        [mediaSizes.l]: '14px 24px',
      },
      margin: {
        [mediaSizes.s]: '32px 0 40px 0',
        [mediaSizes.m]: '48px 0 40px 0',
        [mediaSizes.l]: '68px 0 0 0',
      },
      borderRadius: {
        [mediaSizes.s]: '5px',
        [mediaSizes.m]: '7px',
        [mediaSizes.l]: '8px',
      },
      background: colors.white,
    },
  },
};
