import React, { useEffect, useRef, useState } from 'react';
import { PanInfo } from 'framer-motion';

import { ComponentProps } from './types';
import { StyledModal, StyledModalIos, StyledModalIosInner, StyledModalIosInnerTitle } from './style';

export const variants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 400,
    opacity: 0,
  },
};

export const transition = {
  damping: 22,
  restDelta: 0.001,
  stiffness: 180,
  type: 'ease-in-out',
};

const ModalIos: React.FC<ComponentProps> = (props) => {
  const { isOpened, title, closingOnOutClick, className, mobileHeight = '', children, onClose } = props;
  const innerNode = useRef(null);
  const bodyNode = document.querySelector('body');
  const [height] = useState(window.innerHeight);

  const handleClick = (e: MouseEvent | TouchEvent) => {
    if (closingOnOutClick && onClose && innerNode.current && !innerNode.current.contains(e.target)) {
      e.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    document.addEventListener('touchend', handleClick, true);
    if (isOpened) bodyNode.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('touchend', handleClick, true);
      bodyNode.style.overflow = '';
    };
  }, [isOpened]);

  const handleDragEnd = (dragInfo: PanInfo) => {
    const { offset } = dragInfo;
    if (offset.y > height / 8) {
      onClose();
    }
  };

  if (!isOpened) return null;

  return (
    <StyledModal className={className}>
      <StyledModalIos
        ref={innerNode}
        variants={variants}
        transition={transition}
        initial="closed"
        animate="open"
        exit="closed"
        drag="y"
        dragConstraints={{ top: 5, bottom: height / 3 }}
        dragElastic={{ top: 0.05, bottom: 0.5 }}
        onDragEnd={(_, info) => handleDragEnd(info)}
        role="dialog"
        aria-modal="true"
        height={mobileHeight}
      >
        {title && (
          <StyledModalIosInnerTitle
            onPointerDownCapture={(e: any) => e.stopPropagation()}
            onTouchStartCapture={(e: any) => e.stopPropagation()}
          >
            {title}
          </StyledModalIosInnerTitle>
        )}
        <StyledModalIosInner
          onPointerDownCapture={(e: any) => e.stopPropagation()}
          onTouchStartCapture={(e: any) => e.stopPropagation()}
        >
          {children}
        </StyledModalIosInner>
      </StyledModalIos>
    </StyledModal>
  );
};

export default ModalIos;
