import { Instance, types } from 'mobx-state-tree';
import { ENTITY_TYPES, EVENT_TYPES } from './constants';

export const Event = types.model({
  id: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  entityType: types.enumeration(Object.values(ENTITY_TYPES)),
  eventType: types.enumeration(Object.values(EVENT_TYPES)),
  timestamp: types.maybeNull(types.string),
  timerStarted: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  offerPrice: types.maybeNull(types.number),
  expiredPeriod: types.maybeNull(types.number),
  sbpUrl: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  reference: types.maybeNull(types.string),
});

export interface IEvent extends Instance<typeof Event> {}
