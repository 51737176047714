import getCamelize from 'utils/getCamelize';
import { IGetToken, IOauth2AccessToken } from './models';

export const normalizeOauth2AccessToken = (data?: IOauth2AccessToken) => {
  if (!data) return null;
  return Object.keys(data).reduce((acc, item) => {
    acc[getCamelize(item)] = data[item];
    return acc;
  }, {});
};

export const normalizeGetToken = (data: IGetToken) => {
  if (!data) return null;
  return {
    confirmationCodeVerificationResult: data.confirmationCodeVerificationResult,
    oauth2AccessToken: normalizeOauth2AccessToken(data.oauth2AccessToken),
  };
};
