import { colors } from 'components/constants';

export default {
  primary: {
    colors: {
      normal: colors.mediumGrey,
      hovered: colors.blue,
    },
  },
  onPrimary: {
    colors: {
      normal: colors.mediumGrey,
      hovered: colors.grey,
    },
  },
  secondary: {
    colors: {
      normal: colors.white64,
      hovered: colors.white,
    },
  },
  tertiary: {
    colors: {
      normal: colors.blue,
      hovered: colors.blue80,
    },
  },
  white: {
    colors: {
      normal: colors.white,
      hovered: colors.white80,
    },
  },
  appBlack: {
    colors: {
      normal: colors.appBlack,
      hovered: colors.appBlack,
    },
  },
  onWhite: {
    colors: {
      normal: colors.grey,
      hovered: colors.blue,
    },
  },
};
