import React from 'react';

import { IconPrimitiveProps } from '../types';
import { StyledIcon, StyledIconSvg } from '../style';

const IconPrimitive: React.FC<IconPrimitiveProps> = (props) => {
  const { size = 'm', display = 'inline', color, viewBox, children, fill, viewBoxH = viewBox, hoverOpacity } = props;

  return (
    <StyledIcon sSize={size} sDisplay={display}>
      <StyledIconSvg
        viewBox={`0 0 ${viewBox} ${viewBoxH}`}
        focusable="false"
        sColor={color}
        sFill={fill}
        hoverOpacity={hoverOpacity}
      >
        {children}
      </StyledIconSvg>
    </StyledIcon>
  );
};

export default IconPrimitive;
