import Cookies from 'universal-cookie';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setMobileNativeEvent } from 'utils/setMobileNativeEvent';
import WebService from '../../utils/webService';
import constant from '../../utils/constant';
import { setLoader } from './loaderActions';
import { getLocalData } from '../../utils/helper';

import type { RootState } from '../reducers/rootReducer';
import { ICartData, IProduct } from '../types/cart';

type AddToast = (message: string, object: any) => void;

export const enum CartActions {
  SET_CART = 'SET_CART',
  SET_CART_ID = 'SET_CART_ID',
  ADD_TO_CART = 'ADD_TO_CART',
  DECREASE_QUANTITY = 'DECREASE_QUANTITY',
  INCREASE_QUANTITY = 'INCREASE_QUANTITY',
  DELETE_FROM_CART = 'DELETE_FROM_CART',
  DELETE_ALL_FROM_CART = 'DELETE_ALL_FROM_CART',
}

export interface IGetCartAction {
  type: CartActions.SET_CART;
  payload: ICartData;
}

export interface IGetCartIdAction {
  type: CartActions.SET_CART_ID;
  payload: string;
}

export interface IDeleteFromCartAction {
  type: CartActions.DELETE_FROM_CART;
  payload: IProduct;
}

export interface IDeleteAllFromCartAction {
  type: CartActions.DELETE_ALL_FROM_CART;
  payload: string | null;
}

export type CartAction = IGetCartAction | IGetCartIdAction | IDeleteFromCartAction | IDeleteAllFromCartAction;

export const getCart = (cartID: string) => async (dispatch: ThunkDispatch<null, null, CartAction>) => {
  dispatch(setLoader(true));

  try {
    const store = JSON.parse(getLocalData('redux_localstorage_simple')!);
    const lang = store?.multilanguage?.currentLanguageCode || 'en';
    const action = `${constant.ACTION.CART + cartID}?lang=${lang}`;

    const response = await WebService.get(action);

    if (response.products) {
      response.products.sort(
        (a: any, b: any) =>
          Number(b.available && b.visible && b.availableQuantity > 0) -
          Number(a.available && a.visible && a.availableQuantity > 0),
      );
    }

    dispatch(setShopizerCartID(response.code));
    dispatch({
      type: CartActions.SET_CART,
      payload: response,
    });
  } catch (error) {
    console.log(`Cart action response ${error}`); // eslint-disable-line
    dispatch(deleteAllFromCart());
  } finally {
    dispatch(setLoader(false));
  }
};

// add to cart
export const addToCart =
  (id: IProduct, cartId: string) =>
  async (dispatch: ThunkDispatch<null, null, CartAction>, getState: () => RootState) => {
    dispatch(setLoader(true));

    const { cartID } = getState().cartData;

    try {
      const params = {
        cartCode: cartId || cartID || undefined,
      };

      const action = `${constant.ACTION.CART + constant.ACTION.PRODUCT + id}`;
      const response = await WebService.post(action, null, { params });

      if (response) {
        dispatch(setShopizerCartID(response));
        dispatch(getCart(response));
      }
    } catch (error: any) {
      console.log(error); // eslint-disable-line
      const response = error?.request?.response;

      if (response?.includes('cart not found') || response?.includes('Unable to find')) {
        console.error(response); // eslint-disable-line

        await dispatch(deleteAllFromCart(cartId || cartID));

        if (cartId !== undefined) {
          dispatch(addToCart(id, undefined));
        }
      }

      dispatch(setLoader(false));
    } finally {
    }
  };

// decrease from cart
// export const decreaseQuantity = (item: IProduct, addToast: AddToast) => {
//   console.log(`decrease ${JSON.stringify(item)}`);
//   return (dispatch) => {
//     // if (addToast) {
//     //   addToast("Item Decremented From Cart", {
//     //     appearance: "warning",
//     //     autoDismiss: true
//     //   });
//     // }
//     // dispatch({ type: CartActions.DECREASE_QUANTITY, payload: item });
//   };
// };
//
// export const increaseQuantity = (item: IProduct, addToast: AddToast) => {
//   console.log(`increase ${JSON.stringify(item)}`);
//   return (dispatch) => {
//     // if (addToast) {
//     //   addToast("Item Decremented From Cart", {
//     //     appearance: "warning",
//     //     autoDismiss: true
//     //   });
//     // }
//     // dispatch({ type: CartActions.DECREASE_QUANTITY, payload: item });
//   };
// };

// delete from cart
export const deleteFromCart =
  (cartID: string, item: IProduct, defaultStore: string, addToast: AddToast, quantity: number, isMobileApp?: boolean) =>
  async (dispatch: ThunkDispatch<null, null, IDeleteFromCartAction>) => {
    dispatch(setLoader(true));
    try {
      if (quantity > 1) {
        const action = `${constant.ACTION.CART + cartID}/${constant.ACTION.PRODUCT}${item.id}?store=${
          window._env_.APP_MERCHANT
        }`;
        await WebService.delete(action);

        dispatch({
          type: CartActions.DELETE_FROM_CART,
          payload: item,
        });

        dispatch(getCart(cartID));
      } else {
        await dispatch(deleteAllFromCart(cartID));
      }

      if (addToast) {
        addToast('Removed From Cart', { appearance: 'error', autoDismiss: true });
      }
    } catch {
      console.log(`Error removing from cart ${cartID}`); // eslint-disable-line
    } finally {
      dispatch(setLoader(false));
      if (isMobileApp) setMobileNativeEvent('cartChanged');
    }
  };

export const selectProduct =
  (cartID: string, productId: string) => async (dispatch: ThunkDispatch<null, null, IDeleteFromCartAction>) => {
    dispatch(setLoader(true));
    try {
      const action = `${constant.ACTION.CART + cartID}/${constant.ACTION.SELECT}?productId=${productId}`;

      await WebService.patch(action);

      dispatch(getCart(cartID));
    } catch {
    } finally {
      dispatch(setLoader(false));
    }
  };

export const setShopizerCartID = (id: string): ((dispatch: Dispatch<IGetCartIdAction>) => void) => {
  // set local data
  // set cart id in cookie
  // const cartCookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
  // const cookies = new Cookies();
  // cookies.set(cartCookie, id, { path: '/', maxAge: 20_000_000 }); // 6 months
  // setLocalData(CartActions.SET_CART_ID, id);
  return (dispatch: Dispatch<IGetCartIdAction>) => {
    dispatch({
      type: CartActions.SET_CART_ID,
      payload: id,
    });
  };
  /*
  *   return ({
    type: CartActions.SET_CART_ID,
    payload: id,
  });
  * */
};

export const getShopizerCartID = () => {
  // set local data
  // set cart id in cookie
  const cartCookie = `${window._env_.APP_MERCHANT}_shopizer_cart`;
  const cookies = new Cookies();
  const cookie = cookies.get(cartCookie);
  if (cookie) {
    getCart(cookie);
  }
};

export const deleteAllFromCart =
  (cartID?: string, isMobileApp?: boolean) => async (dispatch: ThunkDispatch<null, null, IDeleteAllFromCartAction>) => {
    dispatch(setLoader(true));
    try {
      const action = `${constant.ACTION.CART + cartID}/${constant.ACTION.CLEAR}`;

      if (cartID) await WebService.delete(action);
    } catch {
    } finally {
      // dispatch(setShopizerCartID(''));
      dispatch({ type: CartActions.DELETE_ALL_FROM_CART, payload: cartID || null });
      dispatch(setLoader(false));
      if (isMobileApp) setMobileNativeEvent('cartChanged');
    }
  };

// get stock of cart item
// export const cartItemStock = (item, color, size) => {
//   if (item.stock) {
//     return item.stock;
//   }
//   return item.variation.find((single) => single.color === color).size.find((single) => single.name === size).stock;
// };
