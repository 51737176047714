import { types, Instance, flow } from 'mobx-state-tree';

import typeChecker from 'utils/typeChecker';
import { CheckCustomer, store as apiCustomerStore } from 'api/customer';

import { store as formStore, Store as FormStore } from './Form/store';
import { store as codeStore, Store as CodeStore } from './Code/store';
import { gtmAddPhonePopup } from '../../../api/analytics/market/gtm';

const Data = types.model({
  phone: types.maybeNull(types.string),
  checkCustomer: types.maybeNull(CheckCustomer),
});

export const Store = types
  .model({
    mounted: types.boolean,
    fetch: types.boolean,
    data: Data,

    formStore: FormStore,
    codeStore: CodeStore,
    captchaStatus: types.string,
    visibleCaptcha: types.boolean,
    smartToken: types.string,
    captchaEnabled: types.boolean,
    closeModal: types.boolean,
  })

  .volatile(() => ({
    root: {
      store: null,
    },
  }))

  .views((self) => ({
    get captions() {
      return self.root.store.captions.auth;
    },

    get closingOnOutClick() {
      if (typeChecker.isBoolean(self.root.store.auth?.closingOnOutClick))
        return self.root.store.auth?.closingOnOutClick;
      return true;
    },

    get withoutCloseIcon() {
      if (typeChecker.isBoolean(self.root.store.auth?.withoutCloseIcon)) return self.root.store.auth?.withoutCloseIcon;
      return false;
    },

    get visibility() {
      return {
        form: !self.data.checkCustomer,
        code: self.data.checkCustomer,
        captcha: self.visibleCaptcha,
      };
    },
    get closeModalAuth() {
      return self.closeModal;
    },
  }))
  .actions((self) => {
    const setError = (error: any) => {
      self.root.store.setError(error);
    };

    return {
      setError,
    };
  })
  .actions((self) => ({
    sendSmsCode: flow(function* sendSmsCode() {
      gtmAddPhonePopup.smsRequest();
      const params = {
        phone: self.data.phone,
        templateCode: 'register_passcode_ru',
      };
      self.data.checkCustomer = yield apiCustomerStore.sendSmsCode(params, self.smartToken);
    }),
  }))
  .actions((self) => ({
    checkCustomer: flow(function* checkCustomer() {
      try {
        self.fetch = true;
        const params = { phone: self.data.phone, templateCode: 'login_passcode_ru' };
        const result = yield apiCustomerStore.checkCustomer(params);
        if (result.userExist) self.data.checkCustomer = result;
        else {
          if (self.captchaEnabled) {
            self.visibleCaptcha = true;
          } else yield self.sendSmsCode();
        }
      } catch (error) {
        self.setError(error);
      } finally {
        self.fetch = false;
      }
    }),
  }))

  .actions((self) => {
    const mount = () => {
      self.mounted = true;
    };

    const unmount = () => {
      self.mounted = false;
      self.fetch = false;
      self.data = { phone: null, checkCustomer: null };
      self.closeModal = false;
    };

    const handleSetPhone = (phone: string) => {
      self.data.phone = phone;
    };

    const handleClear = () => {
      self.data.checkCustomer = null;
    };

    const handleClose = () => {
      self.root.store.onCloseAuth();
    };

    const setCaptchaStatus = (status: string) => {
      if (status !== 'visible') setVisibleCaptcha(false);
      if (status === 'success') self.sendSmsCode();
      self.captchaStatus = status;
    };

    const setVisibleCaptcha = (status: boolean) => {
      self.visibleCaptcha = status;
    };

    const setCloseModal = (value: boolean) => {
      self.closeModal = value;
    };

    const setSmartToken = (token: string) => {
      self.smartToken = token;
    };

    return {
      mount,
      unmount,
      handleSetPhone,
      handleClear,
      handleClose,
      setCaptchaStatus,
      setVisibleCaptcha,
      setSmartToken,
      setCloseModal,
    };
  });

export function create() {
  return Store.create({
    mounted: false,
    fetch: false,
    data: {
      phone: null,
      checkCustomer: null,
    },

    formStore,
    codeStore,
    captchaStatus: 'hidden',
    visibleCaptcha: false,
    smartToken: '',
    captchaEnabled: true,
    closeModal: false,
  });
}

export interface IStore extends Instance<typeof Store> {}
