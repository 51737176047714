import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Form, { Field } from 'components/atoms/Form';
import Block from 'components/atoms/Block';
import Span from 'components/atoms/Span';
import Link from 'components/atoms/Link';
import Loader from 'components/UI/Loader';
import IconArrowLeft from 'components/icons/IconArrowLeft';
import InputCode from 'components/inputs/InputCode';
import { H3 } from 'components/atoms/Heading';
import Button from 'components/atoms/Button';

import { ComponentProps } from './types';

import Timer from './_internal/Timer';

import { FIELDS } from './constants';

const Code: React.FC<ComponentProps> = (props) => {
  const { store, phoneNumber } = props;
  const {
    mounted,
    mount,
    unmount,
    captions,
    description,
    values,
    errors,
    disabled,
    loading,
    visibility,
    button,
    handleBlur,
    handleChange,
    handleSubmit,
    stopRepeatTimer,
    handleRepeatClick,
    handleGoBack,
  } = store;

  useEffect(() => {
    if (!mounted) mount();

    return () => {
      if (mounted) unmount();
    };
  }, [mounted]);

  return (
    <Form onSubmit={handleSubmit}>
      <Loader position="absolute" isLoading={!mounted} />
      <Block>
        {!phoneNumber && (
          <Block m={12} textTransform="uppercase">
            <Link id="goBack" display="flex" isPseudo onClick={handleGoBack}>
              <IconArrowLeft size="xs" />
              <Span font="primary" sizes={[12, 14, 16]} fontWeight="medium" letterSpacing="0.05em">
                {captions.buttons.goBackToChange}
              </Span>
            </Link>
          </Block>
        )}
        <Block m={12} textTransform="uppercase">
          <H3
            font="primary"
            sizes={[32, 40, 48]}
            fontWeight="medium"
            fontStretch="condensed"
            color="grey"
            whiteSpace="preWrap"
            lineHeight={1}
            textAlign="center"
          >
            {captions.title}
          </H3>
        </Block>
        <Block margins={[12, 24, 24]} textAlign="center">
          <Span font="secondary" size="m" color="grey" whiteSpace="preWrap">
            {description}
          </Span>
        </Block>
        <Block m={24}>
          <Field id={FIELDS.CODE} error={errors[FIELDS.CODE]}>
            <InputCode
              id={FIELDS.CODE}
              value={values[FIELDS.CODE]}
              error={!!errors[FIELDS.CODE]}
              disabled={disabled[FIELDS.CODE]}
              size="s"
              onBlur={handleBlur}
              onChange={handleChange}
              autoFocus
            />
          </Field>
        </Block>
        {visibility.repeatTimer && <Timer captions={captions} stopRepeatTimer={stopRepeatTimer} />}
        {visibility.repeatButton && (
          <Block textAlign="center" margins={[24, 35, 35]} textTransform="uppercase">
            <Button
              id="repeatSendCode"
              type="link"
              height="auto"
              padding="0"
              disabled={loading}
              onClick={handleRepeatClick}
            >
              <Span font="primary" size="m" fontWeight="medium" letterSpacing="0.05em">
                {captions.repeatLink}
              </Span>
            </Button>
          </Block>
        )}
        {visibility.consents && (
          <>
            <Block m={24}>
              <Block margins={[12, 24, 24]} textAlign="center">
                <Span font="secondary" size="m" color="grey" whiteSpace="preWrap">
                  {captions.consent[0]}
                  <Link
                    href="/content/pages/agreementBuyersSellers"
                    display="inlineBlock"
                    type="tertiary"
                    target="_blank"
                    isRouter
                  >
                    {captions.consent[1]}
                  </Link>
                  {captions.consent[2]}
                  {captions.consent[3]}
                  <Link
                    href="/content/pages/dataProcessingPolicy"
                    display="inlineBlock"
                    type="tertiary"
                    target="_blank"
                    isRouter
                  >
                    {captions.consent[4]}
                  </Link>
                </Span>
              </Block>
            </Block>
            <Block m={24}>
              <Button
                id="submit"
                display="block"
                type="gradientDark"
                disabled={button.disabled}
                loading={button.loading}
                onClick={handleSubmit}
              >
                {button.label}
              </Button>
            </Block>
          </>
        )}
      </Block>
    </Form>
  );
};

export default observer(Code);
