import { CancelTokenSource } from 'axios';

import { urls } from './constants';
import { getApiInstance, getApiHeader } from './instance';
import { ApiSuggestData } from './types';

function getFio(data: ApiSuggestData, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getApiHeader(),
    cancelToken: cancel.token,
    method: 'post',
    url: urls.getFio,
    data,
  });
}

function getAddress(data: ApiSuggestData, cancel: CancelTokenSource) {
  return getApiInstance()({
    headers: getApiHeader(),
    cancelToken: cancel.token,
    method: 'post',
    url: urls.getAddress,
    data,
  });
}

const getLocate = (cancel: CancelTokenSource) =>
  getApiInstance()({
    headers: getApiHeader(),
    cancelToken: cancel.token,
    method: 'get',
    url: urls.getLocate,
  });

export default {
  getFio,
  getAddress,
  getLocate,
};
