import { setMobileNativeEvent } from 'utils/setMobileNativeEvent';
import { mobileNativeEvents } from 'api/constants';
import theme from './theme';
export const sendOpenEvent = () => {
  const params = { fadeBackgroundColor: theme.fadeBackgroundColor };
  setMobileNativeEvent(mobileNativeEvents.setBackgroundColor, params);
};

export const sendCloseEvent = () => {
  const params = {};
  setMobileNativeEvent(mobileNativeEvents.setBackgroundColor, params);
};
