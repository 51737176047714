import { types, Instance } from 'mobx-state-tree';

export const CustomFields = types.model({
  assessmentTechnicalCondition: types.maybeNull(types.number),
  evaluationAppearance: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  storage: types.maybeNull(types.string),
});

export const Recommendation = types.model({
  ids: types.model({
    mindboxId: types.maybeNull(types.number),
    purchase: types.maybeNull(types.string),
  }),
  name: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  pictureUrl: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  category: types.maybeNull(types.string),
  customFields: types.maybeNull(CustomFields),
  manufacturer: types.model({
    name: types.maybeNull(types.string),
  }),
});

export const Recommendations = types.model({
  recommendation: types.maybeNull(types.array(types.maybeNull(Recommendation))),
});

export const MindboxRecommendation = types.model({
  result: types.model({
    status: types.maybeNull(types.string),
    recommendations: types.maybeNull(Recommendations),
  }),
});

export type IRecommendation = Instance<typeof Recommendation>;
export type IMindboxRecommendation = Instance<typeof MindboxRecommendation>;
