export const menu = {
  baseStyle: {
    list: {
      borderWidth: 0,
      zIndex: 5,
      boxShadow: '0px 33px 80px 0px rgba(0, 0, 0, 0.07)',
    },

    item: {
      _focus: {
        bg: 'transparent',
        color: 'blue10',
      },
      _active: {
        bg: 'transparent',
        color: 'blueActive',
      },
      _expanded: {
        // bg: 'blue',
      },
    },
  },

  variants: {
    selector: {
      list: {
        borderWidth: 0,
        zIndex: 10,
      },
      item: {
        textStyle: 'bodyM',

        _focus: {
          bg: 'transparent',
          color: 'blue10',
        },
        _active: {
          bg: 'transparent',
          color: 'blueActive',
        },
      },
    },
    second: {
      list: {
        borderWidth: 0,
        zIndex: 10,
        position: 'relative',

        _before: {
          content: '""',
          border: 'solid transparent',
          position: 'absolute',
          bottom: '100%',
          right: '15px',
          borderWidth: '9px',
          borderBottomColor: 'white',
          marginRight: -1,
        },
      },
      item: {
        textStyle: 'bodyM',

        _focus: {
          bg: 'transparent',
          color: 'blue10',

          '> svg > path': {
            fill: 'blue10 !important',
          },
        },
        _active: {
          bg: 'transparent',
          color: 'blueActive',

          '> svg > path': {
            fill: 'blueActive !important',
          },
        },
      },
    },
  },
};
