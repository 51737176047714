export const radio = {
  baseStyle: {
    control: {
      transform: 'skew(-15deg)',
      border: 'none !important',
      borderRadius: 'full',
      bg: '#F4F4F4',
      boxShadow: 'none !important',

      _disabled: {
        bg: 'transparent !important',
      },
      _hover: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #F4F4F4',
      },
      _active: {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #F4F4F4',
      },

      _checked: {
        border: 'none !important',
        bg: '#4801FF',

        _disabled: {
          bg: 'transparent !important',
        },
        _hover: {
          bg: '#674FFF',
        },
        _active: {
          bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #4801FF',
        },

        _before: {
          border: '6px solid white',
        },
      },
    },

    container: {
      _disabled: {
        bg: 'transparent',
        cursor: 'default',
      },
    },

    label: {
      _disabled: {
        opacity: '1',
      },
    },
  },
  sizes: {
    lg: {
      control: { w: '6', h: '6' },
      label: { fontSize: 'lg' },
    },
  },

  variants: {
    hidden: {
      control: { display: 'none !important' },
    },
  },
};
