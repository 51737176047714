import { Dispatch } from 'redux';

export const SET_GO_TO = 'SET_GO_TO';

export interface IAction {
  type: string;
  payload?: string;
}

export const setGoTo = (value?: string) => (dispatch: Dispatch<IAction>) => {
  dispatch({
    type: SET_GO_TO,
    payload: value,
  });
};
