import React from 'react';

import { injectStore, useStore } from 'store';

import { ModuleProps } from './module.types';

import Component from './Auth';
import { create as createStore } from './store';

injectStore('auth', createStore());

const Module: React.FC<ModuleProps> = ({ isOpened, phoneNumber = '', onClose }) => {
  const store = useStore();
  const { containers } = store;
  const { auth: componentStore } = containers;

  return <Component isOpened={isOpened} store={componentStore} phoneNumber={phoneNumber} onClose={onClose} />;
};

export default Module;
