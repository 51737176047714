import { media, mediaSizes } from 'components/constants';
import styled from 'styled-components';

import theme from './theme';

export const StyledError = styled.div`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  background: ${theme.background};
  height: 100%;
`;

export const StyledErrorLogo = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: ${theme.logo.padding[mediaSizes.s]};

  @media (${media.tablet}) {
    padding: ${theme.logo.padding[mediaSizes.m]};
  }

  @media (${media.desktop}) {
    justify-content: flex-start;
    padding: ${theme.logo.padding[mediaSizes.l]};
  }
`;

export const StyledErrorBody = styled.div`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const StyledErrorBodyCode = styled.div`
  position: relative;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  -webkit-text-stroke: 2px ${theme.body.code.textStrokeColor};

  h3 {
    display: inline-block;
    transform: matrix(1, 0, -0.22, 0.98, 0, 0);
  }

  @media (${media.tablet}) {
    -webkit-text-stroke: 4px ${theme.body.code.textStrokeColor};
  }
`;

export const StyledErrorBodyTitle = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  text-transform: uppercase;
  padding: ${theme.body.title.padding[mediaSizes.s]};
  margin: ${theme.body.title.margin[mediaSizes.s]};

  @media (${media.tablet}) {
    padding: ${theme.body.title.padding[mediaSizes.m]};
    margin: ${theme.body.title.margin[mediaSizes.m]};
  }

  @media (${media.desktop}) {
    padding: ${theme.body.title.padding[mediaSizes.l]};
    margin: ${theme.body.title.margin[mediaSizes.l]};
  }

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${theme.body.title.background};
    transform: matrix(1, 0, -0.27, 0.98, 0, 0);
    border-radius: ${theme.body.title.borderRadius[mediaSizes.s]};

    @media (${media.tablet}) {
      border-radius: ${theme.body.title.borderRadius[mediaSizes.m]};
    }

    @media (${media.desktop}) {
      border-radius: ${theme.body.title.borderRadius[mediaSizes.l]};
    }
  }
`;
export const StyledErrorBodyDescription = styled.div`
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin: 32px 16px;
`;

export const StyledErrorBodyAction = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;
