import styled from 'styled-components';

import { media, mediaSizes } from 'components/constants';
import { StyledIcon } from 'components/icons/style';

import { StyledModalProps, StyledModalInnerProps, StyledModalBlockProps } from './types';
import theme from './theme';

export const StyledModal = styled.div<StyledModalProps>`
  position: fixed;
  box-sizing: border-box;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.sZIndex};
  background: ${theme.fadeBackgroundColor};
  transition: background-color 0.32s ease-out;
`;

function getInnerWidth(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sWidth)) {
    const [s, m, l] = props.sWidth;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sWidth === 'number') return `${props.sWidth}px`;
  return props.sWidth || 'auto';
}

function getInnerHeight(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sHeight)) {
    const [s, m, l] = props.sHeight;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sHeight === 'number') return `${props.sHeight}px`;
  return props.sHeight || 'auto';
}

function getInnerMinWidth(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sMinWidth)) {
    const [s, m, l] = props.sMinWidth;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sMinWidth === 'number') return `${props.sMinWidth}px`;
  return props.sMinWidth || 'auto';
}

function getInnerMinHeight(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sMinHeight)) {
    const [s, m, l] = props.sMinHeight;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sMinHeight === 'number') return `${props.sMinHeight}px`;
  return props.sMinHeight || 'auto';
}

function getInnerMaxWidth(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sMaxWidth)) {
    const [s, m, l] = props.sMaxWidth;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sMinWidth === 'number') return `${props.sMaxWidth}px`;
  return props.sMaxWidth || 'auto';
}

function getInnerMaxHeight(props: StyledModalInnerProps, mediaSize: string) {
  if (Array.isArray(props.sMaxHeight)) {
    const [s, m, l] = props.sMaxHeight;
    const params = { s, m, l };
    if (typeof params[mediaSize] === 'number') return `${params[mediaSize]}px`;
    return `${params[mediaSize]}`;
  }
  if (typeof props.sMaxHeight === 'number') return `${props.sMaxHeight}px`;
  return props.sMaxHeight || 'auto';
}

function getFontFamily(props: StyledModalInnerProps) {
  if (!props.sFont) return 'inherit';
  return theme.font[props.sFont];
}

function getFontSize(props: StyledModalInnerProps, mediaSize: string) {
  if (!props.sSize) return 'inherit';
  return `${theme.size[mediaSize][props.sSize]}px`;
}

function getLineHeight(props: StyledModalInnerProps, mediaSize: string) {
  if (!props.sSize) return 'inherit';
  return `${theme.lineHeight[mediaSize][props.sSize]}px`;
}

function getColor(props: StyledModalBlockProps) {
  return props.sColor ? theme.colors[props.sColor] : 'inherit';
}

function getBackgroundColor(props: StyledModalInnerProps) {
  return props.sBackgroundColor ? theme.backgroundColors[props.sBackgroundColor] : theme.backgroundColors.white;
}

function getBlockBackgroundColor(props: StyledModalBlockProps) {
  return props.sBackgroundColor ? theme.backgroundColors[props.sBackgroundColor] : theme.backgroundColors.transparent;
}

function getBorderRadius(props: StyledModalInnerProps, mediaSize: string) {
  return props.sBorderRadius || theme.inner.borderRadius[mediaSize];
}

export const StyledModalInner = styled.div<StyledModalInnerProps>`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  width: ${(props) => getInnerWidth(props, mediaSizes.s)};
  height: ${(props) => getInnerHeight(props, mediaSizes.s)};
  min-width: ${(props) => getInnerMinWidth(props, mediaSizes.s)};
  min-height: ${(props) => getInnerMinHeight(props, mediaSizes.s)};
  max-width: ${(props) => getInnerMaxWidth(props, mediaSizes.s)};
  max-height: ${(props) => getInnerMaxHeight(props, mediaSizes.s)};
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: ${(props) => getBorderRadius(props, mediaSizes.s)};
  font-family: ${(props) => getFontFamily(props)};
  color: ${(props) => getColor(props)};
  background: ${(props) => getBackgroundColor(props)};

  font-size: ${(props) => getFontSize(props, mediaSizes.s)};
  line-height: ${(props) => getLineHeight(props, mediaSizes.s)};

  @media (${media.tablet}) {
    font-size: ${(props) => getFontSize(props, mediaSizes.m)};
    line-height: ${(props) => getLineHeight(props, mediaSizes.m)};
    width: ${(props) => getInnerWidth(props, mediaSizes.m)};
    height: ${(props) => getInnerHeight(props, mediaSizes.m)};
    min-width: ${(props) => getInnerMinWidth(props, mediaSizes.m)};
    min-height: ${(props) => getInnerMinHeight(props, mediaSizes.m)};
    max-width: ${(props) => getInnerMaxWidth(props, mediaSizes.m)};
    max-height: ${(props) => getInnerMaxHeight(props, mediaSizes.m)};
    border-radius: ${(props) => getBorderRadius(props, mediaSizes.m)};
  }

  @media (${media.desktop}) {
    font-size: ${(props) => getFontSize(props, mediaSizes.l)};
    line-height: ${(props) => getLineHeight(props, mediaSizes.l)};
    width: ${(props) => getInnerWidth(props, mediaSizes.l)};
    height: ${(props) => getInnerHeight(props, mediaSizes.l)};
    min-width: ${(props) => getInnerMinWidth(props, mediaSizes.l)};
    min-height: ${(props) => getInnerMinHeight(props, mediaSizes.l)};
    max-width: ${(props) => getInnerMaxWidth(props, mediaSizes.l)};
    max-height: ${(props) => getInnerMaxHeight(props, mediaSizes.l)};
    border-radius: ${(props) => getBorderRadius(props, mediaSizes.l)};
  }
`;

export const StyledModalHeader = styled.div<StyledModalBlockProps>`
  position: sticky;
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${theme.header.padding[mediaSizes.s]};
  z-index: 2;
  color: ${(props) => getColor(props)};
  background: ${(props) => getBlockBackgroundColor(props)};

  @media (${media.tablet}) {
    padding: ${theme.header.padding[mediaSizes.m]};
  }

  @media (${media.desktop}) {
    padding: ${theme.header.padding[mediaSizes.l]};
  }
`;

export const StyledModalTitle = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  min-height: 24px;
`;

export const StyledModalClose = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: block;
  top: ${theme.close.position[mediaSizes.s][0]}px;
  right: ${theme.close.position[mediaSizes.s][1]}px;
  cursor: pointer;
  z-index: 2;

  ${StyledIcon} {
    width: ${theme.close.size[mediaSizes.s]}px;
    height: ${theme.close.size[mediaSizes.s]}px;
  }

  @media (${media.tablet}) {
    top: ${theme.close.position[mediaSizes.m][0]}px;
    right: ${theme.close.position[mediaSizes.m][1]}px;

    ${StyledIcon} {
      width: ${theme.close.size[mediaSizes.m]}px;
      height: ${theme.close.size[mediaSizes.m]}px;
    }
  }

  @media (${media.desktop}) {
    top: ${theme.close.position[mediaSizes.l][0]}px;
    right: ${theme.close.position[mediaSizes.l][1]}px;

    ${StyledIcon} {
      width: ${theme.close.size[mediaSizes.l]}px;
      height: ${theme.close.size[mediaSizes.l]}px;
    }
  }
`;

export const StyledModalContent = styled.div<StyledModalBlockProps>`
  position: relative;
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  padding: ${({ sPadding }) => theme.content.padding[mediaSizes.s][sPadding]};
  z-index: 1;
  overflow: auto;
  color: ${(props) => getColor(props)};
  background: ${(props) => getBlockBackgroundColor(props)};

  @media (${media.tablet}) {
    padding: ${({ sPadding }) => theme.content.padding[mediaSizes.m][sPadding]};
  }

  @media (${media.desktop}) {
    padding: ${({ sPadding }) => theme.content.padding[mediaSizes.l][sPadding]};
  }
`;
