import React from 'react';

import { BlockProps } from './Block.types';
import { StyledBlock } from './style';

const Block: React.FC<BlockProps> = (props) => {
  const {
    display = 'block',
    size,
    font,
    color,
    fontWeight,
    fontStyle,
    fontStretch,
    margin,
    margins,
    m,
    padding,
    vPadding,
    hPadding,
    textAlign,
    textTransform,
    children,
    className,
    maxWidth,
    width,
    cursor,
    ...rest
  } = props;

  return (
    <StyledBlock
      sDisplay={display}
      sSize={size}
      sFont={font}
      sColor={color}
      sFontWeight={fontWeight}
      sFontStyle={fontStyle}
      sFontStretch={fontStretch}
      sMargin={margin}
      sMargins={margins}
      sM={m}
      sPadding={padding}
      sVPadding={vPadding}
      sHPadding={hPadding}
      sTextAlign={textAlign}
      sTextTransform={textTransform}
      className={className}
      maxWidth={maxWidth}
      width={width}
      cursor={cursor}
      {...rest}
    >
      {children}
    </StyledBlock>
  );
};

export default Block;
