import { types, getSnapshot, Instance, getParent } from 'mobx-state-tree';

import { gtmAddPhonePopup } from 'api/analytics/market/gtm';
import checkConstraints from 'utils/checkConstraints';

import { InputEventParams } from 'components/inputs/types';

import type { IStore as IParentStore } from '../store';

import { CONSTRAINTS, FIELDS } from './constants';

const Touched = types.model(
  Object.fromEntries(Object.values(FIELDS).map((item) => [item, types.maybeNull(types.boolean)])),
);

const Values = types.model(
  Object.values(FIELDS).reduce((result, item) => {
    let type;
    switch (item) {
      default:
        type = types.string;
    }
    return { ...result, [item]: types.maybeNull(type) };
  }, {}),
);

const Data = types.model({
  touched: Touched,
  values: Values,
});

export const Store = types
  .model({
    mounted: types.boolean,
    fetch: types.boolean,
    data: Data,
  })

  .views((self) => ({
    get parent(): any {
      const parent: IParentStore = getParent(self);
      return parent;
    },

    get captions() {
      return this.parent.captions?.form;
    },

    get values() {
      return getSnapshot(self.data.values);
    },

    get constraints() {
      return Object.fromEntries(
        Object.keys(CONSTRAINTS).map((item) => [
          item,
          CONSTRAINTS[item].map((constraint) => ({
            ...constraint,
            message: this.captions.constraints[constraint.message],
          })),
        ]),
      );
    },

    get disabled() {
      return Object.fromEntries(Object.values(FIELDS).map((item) => [item, self.fetch]));
    },

    get errors() {
      return Object.values(FIELDS).reduce((result, item) => {
        if (!this.disabled[item] && self.data.touched[item]) {
          return {
            ...result,
            [item]: checkConstraints({
              value: self.data.values[item],
              constraints: this.constraints[item],
              otherValues: self.data.values,
              requireConditions: [],
            }),
          };
        }
        return result;
      }, {});
    },

    get hasErrors() {
      return !!Object.values(FIELDS).filter(
        (item) =>
          !this.disabled[item] &&
          checkConstraints({
            value: self.data.values[item],
            constraints: this.constraints[item],
            otherValues: self.data.values,
            requireConditions: [],
          }),
      ).length;
    },

    get button() {
      return {
        loading: this.parent.fetch,
        disabled: this.hasErrors,
      };
    },
  }))

  .actions((self) => {
    const mount = () => {
      gtmAddPhonePopup.initiate();
      if (self.parent.data.phone) self.data.values[FIELDS.PHONE] = `+${self.parent.data.phone}`;
      self.mounted = true;
    };

    const unmount = () => {
      self.mounted = false;
      self.fetch = false;
      self.data = { touched: {}, values: {} };
    };

    const handleChange = ({ id, value }: InputEventParams) => {
      if (self.data.values[id] === value) return;
      self.data.values[id] = value;
    };

    const handleBlur = ({ id }: InputEventParams) => {
      if (typeof self.data.values[id] === 'string') {
        const trimValue = self.data.values[id].trim().replace(/\s+/gi, ' ');
        if (trimValue !== self.data.values[id]) handleChange({ id, value: trimValue });
      }
      if (self.data.values[id]) self.data.touched[id] = true;
    };

    const handleSubmit = () => {
      for (const item of Object.values(FIELDS)) {
        self.data.touched[item] = true;
      }
      if (self.hasErrors) return;
      const phone = self.data.values[FIELDS.PHONE].replace(/^\+/, '');
      self.parent.handleSetPhone(phone);
      self.parent.checkCustomer();
    };

    return {
      mount,
      unmount,
      handleChange,
      handleBlur,
      handleSubmit,
    };
  });

export const store = Store.create({
  mounted: false,
  fetch: false,
  data: { touched: {}, values: {} },
});

export interface IStore extends Instance<typeof Store> {}
