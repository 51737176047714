import { Dispatch } from 'redux';

export const enum SettingsActions {
  SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED',
}

export interface IIsAuthorizedAction {
  type: string;
  payload: boolean;
}

export const setIsAuthorized = (payload: boolean) => (dispatch: Dispatch<IIsAuthorizedAction>) => {
  dispatch({
    type: SettingsActions.SET_IS_AUTHORIZED,
    payload,
  });
};
