// import WebService from '../../utils/webService';
// import constant from '../../utils/constant';
import { Dispatch } from 'redux';

export const SET_LOADER = 'SET_LOADER';

export interface IAction {
  type: string;
  payload: boolean;
}

export const setLoader = (isValue: boolean) => (dispatch: Dispatch<IAction>) => {
  dispatch({
    type: SET_LOADER,
    payload: isValue,
  });
};
