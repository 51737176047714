import { getAuthorized } from 'utils/auth';
import { IIsAuthorizedAction, SettingsActions } from '../actions/settingsActions';

const searchParams = new URLSearchParams(window.location.search);
const isMobileApp =
  localStorage.getItem('isMobileApp') === 'true' ||
  searchParams.has('mobileapp') ||
  /\/mobile\//.test(window.location.href) ||
  window.AndroidWebInterface;

export interface ISettings {
  isMobileApp: boolean;
  isAuthorized: boolean;
}

const initState: ISettings = {
  isMobileApp,
  isAuthorized: getAuthorized(isMobileApp),
};

const settingsReducer = (state = initState, action: IIsAuthorizedAction) => {
  if (action.type === SettingsActions.SET_IS_AUTHORIZED) {
    return {
      ...state,
      isAuthorized: action.payload,
    };
  }

  return state;
};

export default settingsReducer;

localStorage.setItem('isMobileApp', localStorage.getItem('isMobileApp') || String(searchParams.has('mobileapp')));
