import { types, Instance } from 'mobx-state-tree';

const Billing = types.model({
  postalCode: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  bilstateOther: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  stateProvince: types.maybeNull(types.string),
  billingAddress: types.maybeNull(types.boolean),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
  zone: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
});

const Delivery = types.model({
  postalCode: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  bilstateOther: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  stateProvince: types.maybeNull(types.string),
  billingAddress: types.maybeNull(types.boolean),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
  zone: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
});

const Group = types.model({
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
});

const Profile = types.model({
  id: types.maybeNull(types.number),
  emailAddress: types.maybeNull(types.string),
  registrationPhone: types.maybeNull(types.string),
  billing: types.maybeNull(Billing),
  delivery: types.maybeNull(Delivery),
  gender: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  provider: types.maybeNull(types.string),
  storeCode: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  ratingCount: types.maybeNull(types.number),
  attributes: types.maybeNull(types.array(types.string)),
  groups: types.maybeNull(types.array(Group)),
});

export type IProfile = Instance<typeof Profile>;

export { Profile };
