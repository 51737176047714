import { types, flow } from 'mobx-state-tree';

import requests from './requests';
import { ApiUpdateCustomerData } from './types';
import { IProfile, Profile } from './models';
import { hashText } from '../../utils/hash';

export const Store = types
  .model({
    profile: types.maybeNull(Profile),
  })
  .actions((self) => {
    const getProfile = flow(function* request(anyToken?: boolean) {
      clearProfile();

      const response = yield requests.getProfile(anyToken);

      if (response?.data) {
        const profile: IProfile = response.data;

        hashText(`+${profile.billing.phone}`).then((result) => localStorage.setItem('uph', result));
        hashText(profile.emailAddress).then((result) => localStorage.setItem('uem', result));
      }

      self.profile = response?.data ?? null;
    });

    const clearProfile = () => {
      self.profile = null;
    };

    const updateCustomerData = flow(function* request(data: ApiUpdateCustomerData) {
      const response = yield requests.updateCustomerData(data);
      return response?.data;
    });

    return {
      getProfile,
      clearProfile,
      updateCustomerData,
    };
  });

export const store = Store.create();

export default store;
