const Constant = {
  ACTION: {
    STORE: 'store/',
    DEFAULT: 'DEFAULT/',
    CATEGORY: 'category/',
    CONTENT: 'content/',
    PAGES: 'pages/',
    PRODUCT_GROUP: 'products/group/',
    PRODUCT_BOUNDARY_VALUES: 'product/boundary-values/',
    PRODUCT: 'product/',
    PRODUCTS: 'products/',
    PRODUCTS_SEARCH: 'search/products/',
    PRODUCTS_FRIENDLY: 'products/friendly/',
    SELECT: 'select',
    CART: 'cart/',
    CARTS: 'cart',
    CLEAR: 'clear',
    PRICE: 'price/',
    CUSTOMER: 'customer/',
    CUSTOMERS: 'customers/',
    MANUFACTURERS: 'manufacturers/',
    VARIANTS: 'variants/',
    CONFIG: 'config/',
    COUNTRY: 'country/',
    ZONES: 'zones/',
    AUTH: 'auth/',
    PROFILE: 'profile',
    SHIPPING: 'shipping',
    SHIPPING_COUNTRY: 'shipping/country',
    TOTAL: 'total/',
    NEWSLETTER: 'newsletter/',
    REGISTER: 'register',
    CHECKOUT: 'checkout',
    PASSWORD: 'password',
    CONTACT: 'contact/',
    ORDERS: 'orders/',
    ADDRESS: 'address',
    RESET: 'reset/',
    REQUEST: 'request/',
    SEARCH: 'search/',
    SEARCH_FILTERS: 'search/category/{id}/filters',
    AUTOCOMPLETE: 'autocomplete/',
    BOXES: 'boxes/',
    BANNER_TEXT: 'bannerText/',
    IMAGES: 'images/',
    HEADER_MESSAGE: 'headerMessage/',
    AGREEMENT: 'agreement/',
    PROMO: 'promo/',
    OAUTH: 'oauth',
    LOGIN: '/token?grant_type=client_credentials&user_type=customer',
  },
  SORT: {
    DEFAULT: ['DEFAULT'],
    RATING_PRICE: ['RATING_PRICE'],
    NEW: ['CREATED'],
    CHEAP: ['PRICE', 'ASC'],
    EXPENSIVE: ['PRICE'],
    GOOD_RATING: ['RATING'],
  },
};

export default Constant;
