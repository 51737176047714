import styled from 'styled-components';
import { StyledLogoProps, StyledLogoSvgProps } from './types';
import theme from './theme';
import { media, mediaSizes } from '../../constants';

export const StyledLogo = styled.div<StyledLogoProps>`
  position: relative;
  display: block;
  box-sizing: border-box;
  height: ${({ sSize }) => theme.sizes[mediaSizes.s][sSize][1]}px;
  width: ${({ sSize }) => theme.sizes[mediaSizes.s][sSize][0]}px;

  @media (${media.tablet}) {
    height: ${({ sSize }) => theme.sizes[mediaSizes.m][sSize][1]}px;
    width: ${({ sSize }) => theme.sizes[mediaSizes.m][sSize][0]}px;
  }

  @media (${media.desktop}) {
    height: ${({ sSize }) => theme.sizes[mediaSizes.l][sSize][1]}px;
    width: ${({ sSize }) => theme.sizes[mediaSizes.l][sSize][0]}px;
  }
`;

export const StyledLogoSvg = styled.svg<StyledLogoSvgProps>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  fill: ${({ sType }) => theme.types[sType].normal};

  :hover {
    fill: ${({ sType }) => theme.types[sType].hover};
  }
`;
