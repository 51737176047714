import { MerchantActions, ISetStoreNameAction, ISetMerchantAction } from '../actions/storeAction';
import { IMerchant } from '../types/merchant';

const initState: IMerchant = {
  merchant: '',
  defaultStore: '',
};

type MerchantAction = ISetStoreNameAction | ISetMerchantAction;

const storeReducer = (state = initState, action: MerchantAction): IMerchant => {
  switch (action.type) {
    case MerchantActions.SET_MERCHANT:
      return {
        ...state,
        merchant: action.payload,
      };
    case MerchantActions.SET_STORE:
      return {
        ...state,
        defaultStore: action.payload,
      };
    default:
      return state;
  }
};

export default storeReducer;
