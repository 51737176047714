import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from 'components/constants';

export const StyledModal = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black56};
  z-index: 69001;
`;

export const StyledModalIos = styled(motion.div)<{ height?: string }>`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => (props.height ? `${props.height}` : '75vh')};
  padding-top: 28px;
  background: #f6f6f6;
  border-radius: 16px 16px 0 0;

  ::after {
    content: '';
    position: absolute;
    top: 8px;
    width: 48px;
    height: 4px;
    background: rgba(81, 81, 81, 0.1);
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledModalIosInner = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0 16px;
  overflow: auto;
`;

export const StyledModalIosInnerTitle = styled.div`
  position: relative;
  margin-bottom: 12px;
  padding: 0 16px;
`;
