import React from 'react';
import { observer } from 'mobx-react-lite';
import Countdown, { zeroPad } from 'react-countdown';

import Span from 'components/atoms/Span';

import { TimerProps } from './types';
import { StyledAuthTimer } from './style';

const renderer = ({ minutes, seconds, completed }: any) => {
  if (completed) return null;
  return (
    <Span font="primary" fontWeight="medium" size="m" color="grey50">
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </Span>
  );
};

const Timer: React.FC<TimerProps> = (props) => {
  const { captions, stopRepeatTimer } = props;

  return (
    <StyledAuthTimer>
      <Span font="primary" size="m" fontWeight="medium" color="grey50" letterSpacing="0.05em">
        {captions.repeatLabel}
      </Span>
      <Countdown date={Date.now() + 45 * 1000} renderer={renderer} zeroPadTime={2} onComplete={stopRepeatTimer} />
    </StyledAuthTimer>
  );
};

export default observer(Timer);
