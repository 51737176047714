import { types, Instance } from 'mobx-state-tree';

export const FioData = types.model({
  type: types.maybeNull(types.literal('fio')),
  surname: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  patronymic: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  qc: types.maybeNull(types.string),
});

export type IFioData = Instance<typeof FioData>;

export const Fio = types.model({
  value: types.maybeNull(types.string),
  unrestricted_value: types.maybeNull(types.string),
  data: types.maybeNull(FioData),
});

export type IFio = Instance<typeof Fio>;

export const GetFioResult = types.model({
  suggestions: types.maybeNull(types.array(Fio)),
});

export type IGetFioResult = Instance<typeof GetFioResult>;

export const AddressData = types.model({
  type: types.maybeNull(types.literal('address')),
  postal_code: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  federal_district: types.maybeNull(types.string),
  region_fias_id: types.maybeNull(types.string),
  region_kladr_id: types.maybeNull(types.string),
  region_with_type: types.maybeNull(types.string),
  region_type: types.maybeNull(types.string),
  region_type_full: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  area_fias_id: types.maybeNull(types.string),
  area_kladr_id: types.maybeNull(types.string),
  area_with_type: types.maybeNull(types.string),
  area_type: types.maybeNull(types.string),
  area_type_full: types.maybeNull(types.string),
  area: types.maybeNull(types.string),
  city_fias_id: types.maybeNull(types.string),
  city_kladr_id: types.maybeNull(types.string),
  city_with_type: types.maybeNull(types.string),
  city_type: types.maybeNull(types.string),
  city_type_full: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  city_area: types.maybeNull(types.string),
  city_district_fias_id: types.maybeNull(types.string),
  city_district_kladr_id: types.maybeNull(types.string),
  city_district_with_type: types.maybeNull(types.string),
  city_district_type: types.maybeNull(types.string),
  city_district_type_full: types.maybeNull(types.string),
  city_district: types.maybeNull(types.string),
  settlement_fias_id: types.maybeNull(types.string),
  settlement_kladr_id: types.maybeNull(types.string),
  settlement_with_type: types.maybeNull(types.string),
  settlement_type: types.maybeNull(types.string),
  settlement_type_full: types.maybeNull(types.string),
  settlement: types.maybeNull(types.string),
  street_fias_id: types.maybeNull(types.string),
  street_kladr_id: types.maybeNull(types.string),
  street_with_type: types.maybeNull(types.string),
  street_type: types.maybeNull(types.string),
  street_type_full: types.maybeNull(types.string),
  street: types.maybeNull(types.string),
  house_fias_id: types.maybeNull(types.string),
  house_kladr_id: types.maybeNull(types.string),
  house_type: types.maybeNull(types.string),
  house_type_full: types.maybeNull(types.string),
  house: types.maybeNull(types.string),
  block_type: types.maybeNull(types.string),
  block_type_full: types.maybeNull(types.string),
  block: types.maybeNull(types.string),
  flat_type: types.maybeNull(types.string),
  flat_type_full: types.maybeNull(types.string),
  flat: types.maybeNull(types.string),
  flat_area: types.maybeNull(types.string),
  square_meter_price: types.maybeNull(types.string),
  flat_price: types.maybeNull(types.string),
  postal_box: types.maybeNull(types.string),
  fias_id: types.maybeNull(types.string),
  fias_code: types.maybeNull(types.string),
  fias_level: types.maybeNull(types.string),
  fias_actuality_state: types.maybeNull(types.string),
  kladr_id: types.maybeNull(types.string),
  geoname_id: types.maybeNull(types.string),
  capital_marker: types.maybeNull(types.string),
  okato: types.maybeNull(types.string),
  oktmo: types.maybeNull(types.string),
  tax_office: types.maybeNull(types.string),
  tax_office_legal: types.maybeNull(types.string),
  timezone: types.maybeNull(types.string),
  geo_lat: types.maybeNull(types.string),
  geo_lon: types.maybeNull(types.string),
  beltway_hit: types.maybeNull(types.string),
  beltway_distance: types.maybeNull(types.string),
  qc_geo: types.maybeNull(types.string),
  qc_complete: types.maybeNull(types.string),
  qc_house: types.maybeNull(types.string),
  unparsed_parts: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  qc: types.maybeNull(types.string),
});

export type IAddressData = Instance<typeof AddressData>;

export const Address = types.model({
  value: types.maybeNull(types.string),
  unrestricted_value: types.maybeNull(types.string),
  data: types.maybeNull(AddressData),
});

export type IAddress = Instance<typeof Address>;

export const GetAddressResult = types.model({
  suggestions: types.maybeNull(types.array(Address)),
});

export type IGetAddressResult = Instance<typeof GetAddressResult>;
