import { Dispatch } from 'redux';

export const enum ProductActions {
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  SET_CATEGORY_ID = 'SET_CATEGORY_ID',
}

export interface ISetProductsAction {
  type: ProductActions.SET_PRODUCTS;
  payload: any[];
}

export interface ISetProductIdAction {
  type: ProductActions.SET_PRODUCT_ID;
  payload: number;
}

export interface ISetCategoryIdAction {
  type: ProductActions.SET_CATEGORY_ID;
  payload: number;
}

// fetch products
export const fetchProducts = (products: any[]) => (dispatch: Dispatch<ISetProductsAction>) => {
  dispatch({ type: ProductActions.SET_PRODUCTS, payload: products });
};
export const setProductID = (productID: number) => (dispatch: Dispatch<ISetProductIdAction>) => {
  dispatch({ type: ProductActions.SET_PRODUCT_ID, payload: productID });
};
export const setCategoryID = (categoryID: number) => (dispatch: Dispatch<ISetCategoryIdAction>) => {
  dispatch({ type: ProductActions.SET_CATEGORY_ID, payload: categoryID });
};
