import { SET_GO_TO, IAction } from '../actions/goToActions';

const initState: string = null;

const goToReducer = (state = initState, action: IAction) => {
  if (action.type === SET_GO_TO) {
    return action.payload;
  }

  return state;
};

export default goToReducer;
