import { Dispatch } from 'redux';

export const SET_ERROR = 'SET_ERROR';

interface Payload {
  timestamp?: string;
  status?: string;
  error?: string;
  message?: string;
}

export interface IAction {
  type: string;
  payload: Payload;
}

export const setError = (error: Payload) => (dispatch: Dispatch<IAction>) => {
  dispatch({
    type: SET_ERROR,
    payload: error,
  });
};
