import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Pathname({ update }) {
  const { pathname } = useLocation();

  useEffect(() => {
    update(pathname);
  }, [pathname]);

  return null;
}

Pathname.propTypes = {
  update: PropTypes.func.isRequired,
};
