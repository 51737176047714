import styled from 'styled-components';

import { getInputElementBackgroundColor, getInputElementColor, StyledInput, StyledInputBase } from '../style';
import { StyledInputElementProps } from '../types';

export const StyledInputPhone = styled(StyledInput)<StyledInputElementProps>`
  position: relative;
  box-sizing: border-box;
  color: ${(props) => getInputElementColor(props)};
  background: ${(props) => getInputElementBackgroundColor(props)};
  border-radius: 8px;
`;

export const StyledInputPhoneBase = styled(StyledInputBase)`
  position: relative;
  box-sizing: border-box;
`;
