const commonH = {
  fontWeight: 500,
  lineHeight: '100%',
  letterSpacing: '0px',
  textTransform: 'uppercase',
  fontStretch: 'condensed',
};

const bodyMU = {
  fontWeight: 500,
  fontSize: ['xxs', 'xs', 'sm', 'md'],
  lineHeight: '105%',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
};

const bodyM = {
  fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
  fontWeight: 400,
  fontSize: 'md',
  lineHeight: '135%',
  letterSpacing: '0px',
};

const rectangle = {
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 1,
};

const rectangleBefore = {
  content: `""`,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  background: 'green10',
  transform: 'skew(-15deg)',
  zIndex: -1,
};

const rectangleRating = {
  ...commonH,
  ...rectangle,

  marginRight: '4px !important',
  py: [1, 1, 1, 2],
  px: [2, 2, 2, 4],
  fontSize: ['22px', '22px', '22px', '36px'],
  lineHeight: ['24px', '24px', '24px', '36px'],
  color: 'white',

  _before: {
    ...rectangleBefore,
    background: 'blue10',
    borderRadius: '4px',
  },
};

export const textStyles = {
  h1: {
    ...commonH,
    fontSize: ['26px', '36px', '48px', '64px'],
  },
  h2: {
    ...commonH,
    fontSize: ['26px', '30px', '36px', '48px'],
  },
  h3: {
    ...commonH,
    fontSize: ['24px', '26px', '30px', '36px'],
  },
  h4: {
    ...commonH,
    fontSize: ['20px', '22px', '24px', '24px'],
  },
  pageTitle: {
    ...commonH,
    fontSize: ['26px', '36px', '48px', '64px'],
  },
  appH1: {
    ...commonH,
    fontSize: ['32px', '32px', '36px', '48px'],
    lineHeight: ['36px', '36px', '36px', '48px'],
  },
  appH2: {
    ...commonH,
    fontSize: ['22px', '22px', '30px', '48px'],
    lineHeight: ['24px', '24px', '30px', '48px'],
  },
  appCaptionM: {
    ...commonH,
    fontSize: ['12px', '12px', '24px', '36px'],
    lineHeight: ['14px', '14px', '24px', '36px'],
    fontStretch: ['normal', 'normal', 'condensed'],
    fontWeight: ['400', '400', '500', '500'],
    fontFamily: [
      `'ALS Hauss', 'ALS Wagon', sans-serif`,
      `'ALS Hauss', 'ALS Wagon', sans-serif`,
      `'ALS Wagon', sans-serif`,
      `'ALS Wagon', sans-serif`,
    ],
  },
  appCaptionS: {
    ...commonH,
    fontSize: ['10px', '10px', '24px', '24px'],
    lineHeight: ['10px', '10px', '24px', '24px'],
    fontStretch: ['normal', 'normal', 'condensed'],
  },
  appP3Light: {
    ...bodyM,
    fontSize: ['12px', '12px', 'md', 'md'],
    lineHeight: ['14px', '14px', '135%', '135%'],
  },
  appBodyLightM: {
    ...bodyM,
    fontSize: 'md',
    lineHeight: ['20px', '20px', '135%', '135%'],
  },
  price: {
    ...commonH,
    fontSize: '48px',
  },
  white: {
    ...commonH,
    fontSize: ['26px', '30px', '36px', '48px'],
    background: 'white10',
    backgroundClip: 'text',
    webkitTextFillColor: 'transparent',
  },
  gradient: {
    ...commonH,
    fontSize: ['26px', '30px', '36px', '48px'],
    background: 'gradient1',
    backgroundClip: 'text',
    webkitTextFillColor: 'transparent',
  },
  p2: {
    fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '0px',
  },
  bodyMU,
  bodyM,
  p3: {
    fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0px',
  },
  captionM: {
    fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '135%',
    letterSpacing: '0px',
  },
  captionMU: {
    fontFamily: 'ALS Wagon',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '135%',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  titleM: {
    fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '135%',
    letterSpacing: '0px',
  },
  titleMU: {
    ...commonH,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontSize: '18px',
  },
  subtitleM: {
    fontFamily: `'ALS Wagon', 'ALS Hauss', sans-serif`,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '105%',
    letterSpacing: '0.05em',
    color: '#999999',
  },
  subtitleL: {
    fontFamily: `'ALS Wagon', 'ALS Hauss', sans-serif`,
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '100%',
    textTransform: 'uppercase',
    fontStretch: 'condensed',
  },
  subtextM: {
    fontFamily: `'ALS Hauss', 'ALS Wagon', sans-serif`,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '120%',
  },
  subtext: {
    fontFamily: `'ALS Wagon', sans-serif`,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '135%',
    letterSpacing: '5%',
  },
  pagination: {
    fontSize: '24px',
    margin: '2px',
    fontWeight: '500',
    fontStretch: 'condensed',
    textAlign: 'center !important',
    color: 'grey10',
  },
  inputLabel: {
    position: 'absolute',
    fontWeight: 500,
    lineHeight: '135%',
    letterSpacing: '0.04em',
    fontSize: '10px',
    color: 'mediumGrey10',
    userSelect: 'none',
    pt: [1, 1, '6px'],
    px: ['9px !important', '9px !important'],
    zIndex: 2,
  },

  rectangle: {
    ...commonH,
    ...rectangle,

    py: 2,
    px: [4, 4, 8, 8],
    fontSize: ['32px', '36px', '48px', '64px'],
    color: 'blue10',

    _before: {
      ...rectangleBefore,
    },
  },

  rectangleReverse: {
    ...commonH,
    ...rectangle,

    py: 2,
    px: [4, 4, 8, 8],
    fontSize: ['32px', '36px', '48px', '64px'],
    color: 'blue10',

    _before: {
      ...rectangleBefore,
      transform: 'skew(15deg)',
    },
  },

  rectangleRed: {
    ...commonH,
    ...rectangle,

    py: 2,
    px: 8,
    fontSize: ['32px', '36px', '48px', '64px'],
    color: 'white',

    _before: {
      ...rectangleBefore,
      background: 'red10',
    },
  },

  rectangleFuncRating: rectangleRating,

  rectangleVisualRating: {
    ...rectangleRating,
    color: 'grey10',

    _before: {
      ...rectangleRating._before,
      background: 'green10',
      transform: 'skew(15deg)',
    },
  },

  rectangleAsNew: {
    ...bodyMU,
    color: 'grey10',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'green10',
    },
  },

  rectangleOnSale: {
    ...bodyMU,
    color: 'white10',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'coralRed',
    },
  },
  rectangleOrange: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '22px',
    letterSpacing: 'inherit',
    color: 'blue10',
    py: [1, 1, 1, 1],
    px: [2, 2, 2, 5],

    _before: {
      ...rectangleRating._before,
      background: '#FFCC80',
      transform: 'skew(15deg)',
    },
  },

  backRectangleAcuteOrange: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '22px',
    letterSpacing: 'inherit',
    color: 'blue10',
    py: [1, 1, 1, 1],
    px: [2, 2, 2, 5],

    _before: {
      ...rectangleRating._before,
      background: '#FFCC80',
      transform: 'skew(333deg)',
      borderRadius: '3px',
    },
  },

  rectangleBlue: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '25px',
    letterSpacing: 'inherit',
    color: 'white',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'blue10',
    },
  },
  rectangleAcuteBlue: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '25px',
    letterSpacing: 'inherit',
    color: 'white',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'blue10',
      transform: 'skew(333deg)',
    },
  },
  backRectangleAcuteBlue: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '25px',
    letterSpacing: 'inherit',
    color: 'white',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'blue10',
      transform: 'skew(-333deg)',
    },
  },
  rectangleGreen: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '36px',
    letterSpacing: 'inherit',
    color: 'blue10',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'green10',
    },
  },
  backRectangleGreen: {
    ...bodyMU,
    fontFamily: `'ALS Wagon', sans-serif`,
    fontStretch: 'condensed',
    fontSize: '36px',
    letterSpacing: 'inherit',
    color: 'blue10',
    py: [1, 1, 1, 2],
    px: [2, 2, 2, 3],

    _before: {
      ...rectangleRating._before,
      background: 'green10',
      transform: 'skew(-333deg)',
    },
  },
  strikethroughText: {
    position: 'relative',
    fontFamily: 'ALS Wagon',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '135%',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: '#999999',
    _before: {
      content: `""`,
      borderBottom: '1px solid red',
      position: 'absolute',
      width: '100%',
      height: '50%',
      transform: 'rotate(-12deg)',
    },
  },
  discountText: {
    position: 'relative',
    fontFamily: 'ALS Wagon',
    fontWeight: [500, 500, 400, 400],
    fontSize: '10px',
    lineHeight: '135%',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    zIndex: 2,
    textAlign: 'center',
    padding: '2px 4px',

    _before: {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: ['1px', '1px', '4px', '4px'],
      background: 'red10',
      zIndex: -1,
    },
  },

  miniRectangleGreen: {
    ...bodyMU,
    position: 'relative',
    fontFamily: [
      `'ALS Wagon', 'ALS Hauss', sans-serif`,
      `'ALS Wagon', 'ALS Hauss', sans-serif`,
      `'ALS Hauss', 'ALS Wagon', sans-serif`,
      `'ALS Hauss', 'ALS Wagon', sans-serif`,
    ],
    fontStretch: ['inherit', 'inherit', 'condensed', 'condensed'],
    fontSize: '10px',
    textTransform: ['uppercase', 'uppercase', 'inherit', 'inherit'],
    letterSpacing: 'inherit',
    color: 'grey10',
    py: [1, 1, 2, 2],
    px: [2, 2, 2, 2],
    zIndex: 2,

    _before: {
      ...rectangleRating._before,
      background: 'green10',
      borderRadius: '2px',
      zIndex: -1,
    },
  },
};
