import axios from 'axios';
import { getApiInstance } from '../api/axios';
import { getAuthHeaders } from '../api/utils';

const BASE_URL = process.env.REACT_APP_SHOPIZER_URL + window._env_.APP_API_VERSION;
axios.defaults.baseURL = BASE_URL;

export default class WebService {
  static async post(action, data, config = {}) {
    const response = await getApiInstance()({
      ...config,
      headers: getAuthHeaders(),
      method: 'POST',
      url: action,
      data,
    });
    return response.data;
  }

  static async get(action, params) {
    const response = await getApiInstance()({
      headers: getAuthHeaders(),
      method: 'GET',
      url: action,
      params,
    });
    return response.data;
  }

  static async delete(action) {
    const response = await getApiInstance()({
      headers: getAuthHeaders(),
      method: 'DELETE',
      url: action,
    });
    return response.data;
  }

  static async patch(action, params) {
    const response = await getApiInstance()({
      headers: getAuthHeaders(),
      method: 'PATCH',
      url: action,
      params,
    });
    return response.data;
  }
}
