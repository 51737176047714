import { setMobileNativeEvent } from './setMobileNativeEvent';
import { STORAGE_KEYS } from '../constants';

export const getMobileToken = (params = {}) => {
  const result = setMobileNativeEvent('getAccessToken', params);

  console.log('mobile token result: %o', result); // eslint-disable-line

  if (result) {
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, result);
  } else {
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  }
};

export const isExistMobileToken = () => setMobileNativeEvent('hasAuthorization');
