export default function checkVersion(vv: string, vvv: string) {
  if (!(/^[0-9.]*$/.test(vv) && /^[0-9.]*$/.test(vvv))) return;
  const va = vv.toString().split('.');
  const vb = vvv.toString().split('.');
  length = Math.max(va.length, vb.length);
  for (let i = 0; i < length; i++) {
    if ((va[i] || 0) < (vb[i] || 0)) {
      return false;
    }
  }
  return true;
}
