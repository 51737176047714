import { STORAGE_KEYS } from '../../constants';
import Socket from './socket';

export class TestsSocket extends Socket {
  constructor(tokenKey = STORAGE_KEYS.DIAGNOSTIC_TOKEN) {
    super(tokenKey);
  }

  static override getInstance(tokenKey = STORAGE_KEYS.DIAGNOSTIC_TOKEN) {
    if (TestsSocket.instance) return TestsSocket.instance;
    TestsSocket.instance = new TestsSocket(tokenKey);
    return TestsSocket.instance;
  }
}
