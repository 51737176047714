export const ROUTES = {
  SELL: '/mobile/sell',
  SELL_PHONE: '/mobile/sell/phone',
  SELL_PHONE_MODEL: '/mobile/sell/phone/:manufacturer',
  SELL_PHONE_MODEL_MEMORY: '/mobile/sell/phone/:manufacturer/:model',
  SELL_PHONE_MODEL_MEMORY_POLL: '/mobile/sell/phone/:manufacturer/:model/:memory/poll/:sellSessionId',

  MY_ACCOUNT: '/mobile/my-account',
  MY_ACCOUNT_ORDERS_PURCHASES: '/mobile/my-account/orders/purchases',
  MY_ACCOUNT_ORDERS_PURCHASES_ITEM: '/mobile/my-account/orders/purchases/:id',
  MY_ACCOUNT_ORDERS_SALES: '/mobile/my-account/orders/sales',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT: '/mobile/my-account/orders/sales/:id/checkout/',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT: '/mobile/my-account/orders/sales/:id/checkout/result',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_ERROR: '/mobile/my-account/orders/sales/:id/checkout/result/error',
  MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_SELL_ERROR:
    '/mobile/my-account/orders/sales/:id/checkout/result/error-sell',

  REPORT: '/mobile/report',
  REPORT_BUY: '/mobile/report/buy',
  REPORT_BUY_FUNCTIONAL: '/mobile/report/buy-functional',
  REPORT_CHECKOUT: '/mobile/report/checkout',
  REPORT_CHECKOUT_RESULT: '/mobile/report/checkout/result',
  REPORT_CHECKOUT_RESULT_ERROR: '/mobile/report/checkout/result/error',
  REPORT_CHECKOUT_RESULT_FAILED: '/mobile/report/checkout/result/failed',

  CARD_ENROLL_RESULT: '/mobile/card-enroll/result',
  CARD_ENROLL_RESULT_ERROR: '/mobile/card-enroll/result/error',

  CREDIT_DC_WIDGET: '/mobile/credit/dc-widget/:partnerId/:token',
  CREDIT_WAIT_APPROVE: '/mobile/credit/wait-approve',
  CREDIT_ERROR: '/mobile/credit/error',

  CONTENT: '/mobile/content/pages/:id',

  FAQ: '/mobile/faq',
  FAQ_SALE_QUESTIONS: '/mobile/faq/sale-questions',
  FAQ_PURCHASE_QUESTIONS: '/mobile/faq/purchase-questions',

  INSTRUCTIONS_ITEM: '/mobile/instructions/:type/:os',
  INSTRUCTIONS_ITEM_BRANDS: '/mobile/instructions/:type/:os/brands',
  INSTRUCTIONS_ITEM_BRANDS_ITEM: '/mobile/instructions/:type/:os/brands/:brand',

  NEWS: '/mobile/news',
  HOW_TO_TRANSFER: '/mobile/howToTransfer',

  NOT_FOUND: '/mobile/*',
};
