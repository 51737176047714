import { AxiosRequestConfig } from 'axios';
import { STORAGE_KEYS } from '../constants';

const style1 = 'font-weight: normal; color: #888888;';
const style2 = 'font-weight: bold; color: #cccccc;';
const prefix = 'mock request';

export type ResponseType = Array<any>;

export const logRequest = (config: AxiosRequestConfig, response: ResponseType) => {
  if (process.env.REACT_APP_MOCK === 'true') {
    /* eslint-disable no-console */
    console.groupCollapsed('%c %s %c%s', style1, prefix, style2, `${config.method} ${config.url}`);
    console.log('---- config ----');
    console.log(config);
    console.log('----------------');
    console.log('----- data -----');
    console.log(typeof config.data === 'string' ? JSON.parse(config.data) : config.data);
    console.log('----------------');
    console.log('--- response ---');
    console.log(response);
    console.log('----------------');
    console.groupEnd();
  }
  return response;
};

export function setTokens(data: { accessToken: string; refreshToken?: string }) {
  localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, data.accessToken);
  if (data.refreshToken) localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
}

export function removeTokens() {
  localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
}

export function getToken(key: string) {
  return localStorage.getItem(key);
}

export function clearLocalStorage() {
  localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.DIAGNOSTIC_TOKEN);
  localStorage.removeItem('uph');
  localStorage.removeItem('uem');
  localStorage.removeItem('DEFAULT_shopizer_cart');
  localStorage.removeItem('SET_CART_ID');
}

export function getBearerToken(anyToken?: boolean) {
  if (anyToken) {
    const diagnosticToken = localStorage.getItem('diagnostic_token');
    if (diagnosticToken) return `Bearer ${diagnosticToken}`;
  }
  const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  if (token) return `Bearer ${token}`;
  return null;
}

export function getAuthHeaders(anyToken?: boolean) {
  const token = getBearerToken(anyToken);
  if (!token) return {};
  return {
    authorization: token,
  };
}

export const base64toBlob = (base64Data: string, contentType = '') => {
  const cType = contentType;
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = Array.from({ length: slicesCount });
  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);
    const bytes = Array.from({ length: end - begin });
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].codePointAt(0);
    }
    // @ts-ignore
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  // @ts-ignore
  return new Blob(byteArrays, { type: cType });
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve) => {
    const fileReader = new window.FileReader();
    fileReader.addEventListener('load', () => {
      return resolve(fileReader.result);
    });
    fileReader.readAsDataURL(blob);
  });
};

export const setQueryParam = (key: string, value: string, clear?: boolean) => {
  if (window.history.pushState) {
    const searchParams = clear ? new URLSearchParams() : new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.origin + window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export function ApiError(message: string) {
  // @ts-ignore
  this.name = 'ApiError';
  // @ts-ignore
  this.message = message || '';
  // @ts-ignore
  this.stack = '';
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;
