import React from 'react';

export const types = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  white: 'white',
  appBlack: 'appBlack',
  onPrimary: 'onPrimary',
  onWhite: 'onWhite',
};
export const display = { block: 'block', inlineBlock: 'inline-block', flex: 'flex' };

export type Type = keyof typeof types;
export type Display = keyof typeof display;

export type LinkProps = Readonly<{
  id?: string;
  display?: Display;
  external?: boolean;
  href?: string;
  type?: Type;
  isPseudo?: boolean;
  isRouter?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}> &
  React.ComponentPropsWithoutRef<'a'>;

export type StyledLinkProps = Readonly<{
  as?: any;
  sDisplay?: Display;
  sType?: Type;
  children?: React.ReactNode | React.ReactNode[];
}>;
