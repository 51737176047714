import { Dispatch } from 'redux';

import WebService from '../../utils/webService';
import constant from '../../utils/constant';
import { IMerchantData } from '../types/merchant';

export const enum MerchantActions {
  SET_MERCHANT = 'SET_MERCHANT',
  SET_STORE = 'SET_STORE',
}

export interface ISetStoreNameAction {
  type: MerchantActions.SET_STORE;
  payload: string;
}

export interface ISetMerchantAction {
  type: MerchantActions.SET_MERCHANT;
  payload: IMerchantData;
}

export const setStore = (storeCode: string) => async (dispatch: Dispatch<ISetStoreNameAction>) => {
  dispatch({
    type: MerchantActions.SET_STORE,
    payload: storeCode,
  });
};

export const setMerchant = () => async (dispatch: Dispatch<ISetMerchantAction>) => {
  const action = constant.ACTION.STORE + window._env_.APP_MERCHANT;
  try {
    const response = await WebService.get(action);
    // console.log(response);
    // if (response) {
    dispatch({
      type: MerchantActions.SET_MERCHANT,
      payload: response,
    });
    // @ts-ignore
    dispatch(setStore(response.code));
    setStore(response.code);
  } catch {}
};
