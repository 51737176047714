import { colors, mediaSizes } from 'components/constants';

export default {
  field: {
    label: {
      color: colors.grey80,
      fontSize: 16,
      lineHeight: 24,
      paddingBottom: 8,
    },
    description: {
      color: colors.grey80,
      fontSize: 14,
      lineHeight: 18,
      paddingTop: 4,
      paddingBottom: 4,
    },
    error: {
      color: colors.red,
      backgroundColor: colors.transparent,
      fontSize: 14,
      lineHeight: 18,
      padding: {
        [mediaSizes.s]: [8, 16, 0],
        [mediaSizes.m]: [8, 16, 0],
        [mediaSizes.l]: [8, 16, 0],
      },
    },
  },
};
