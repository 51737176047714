import React from 'react';
import { useCallback, useState } from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { ComponentProps } from './types';

export const SubscriptionToCaptcha = ({ setCaptchaStatus, visible, setSmartToken }: ComponentProps) => {
  const handleChallengeVisible = useCallback(() => setCaptchaStatus('visible'), []);
  const handleChallengeHidden = useCallback(() => setCaptchaStatus('hidden'), []);
  const handleNetworkError = useCallback(() => setCaptchaStatus('network-error'), []);
  const handleSuccess = useCallback((token: string) => {
    setSmartToken(token);
    setCaptchaStatus('success');
    handleReset();
  }, []);
  const handleTokenExpired = useCallback(() => {
    setCaptchaStatus('token-expired');
  }, []);

  const [resetCaptcha, setResetCaptcha] = useState(0);
  const handleReset = () => setResetCaptcha((prev) => prev + 1);

  return (
    <div style={{ height: '100px', display: 'none' }}>
      <InvisibleSmartCaptcha
        key={resetCaptcha}
        sitekey={process.env['REACT_APP_ysc1_FSwVpfyH6qJxDP17f8UQUC3h7WfiRLqAvEVGyGft77e3fdce']}
        onChallengeVisible={handleChallengeVisible}
        onChallengeHidden={handleChallengeHidden}
        onNetworkError={handleNetworkError}
        onSuccess={handleSuccess}
        onTokenExpired={handleTokenExpired}
        visible={visible}
        hideShield={true}
        test={JSON.parse(process.env.REACT_APP_YANDEX_CAPTCHA_IS_TEST)}
      />
    </div>
  );
};
