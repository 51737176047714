import React from 'react';

import { IconProps } from './types';
import IconPrimitive from './_internal/IconPrimitive';

const IconArrowLeft: React.FC<IconProps> = (props) => {
  return (
    <IconPrimitive {...props} viewBox={24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7188 20.6952C17.1028 20.2982 17.0922 19.6651 16.6952 19.2812L9.41447 12.2396L16.7183 4.69558C17.1025 4.29879 17.0922 3.66571 16.6954 3.28155C16.2987 2.89739 15.6656 2.90763 15.2814 3.30442L7.28163 11.5673C7.09709 11.7579 6.99586 12.014 7.00022 12.2793C7.00458 12.5445 7.11417 12.7972 7.30488 12.9817L15.3048 20.7188C15.7018 21.1028 16.3349 21.0922 16.7188 20.6952Z"
      />
    </IconPrimitive>
  );
};

export default IconArrowLeft;
