export const operations = {
  popularproducts: 'popularproducts',
  personaloffer: 'personaloffer',
  bestprice: 'personaloffer',
  popularproductsiPhone12: 'popularproductsiPhone12',
  popularproductsiGalaxyZ: 'popularproductsiGalaxyZ',
  youwatched: 'youwatched',
  popularproductSamsung: 'popularproductSamsung',
  lowCostProduct: 'lowCostProduct',
};

export const urls = {
  getRecommendation: '/sync?endpointId=civimart.ru&operation={operation}{deviceUUID}',
};
