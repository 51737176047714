import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ResetPageMetadata = (props: any): null => {
  const { strings } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    if (strings) {
      document.title = strings.siteTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', strings.plainMainDescription);
    }
  }, [pathname]);

  return null;
};

export default ResetPageMetadata;
