import React, { useEffect, useRef, useState, useMemo } from 'react';

import { StyledInput } from 'components/inputs/style';
import { getChar } from 'components/inputs/utils';
import isMobile from '../../../utils/isMobile';

import { InputCodeProps } from './types';
import { StyledInputCode, StyledInputCodeItem } from './style';
import { formatCodeValue, deformatCodeValue, getValues, getLastIndex } from './utils';

const InputCode: React.FC<InputCodeProps> = (props) => {
  const {
    id,
    value,
    type = 'primary',
    size = 'm',
    error = false,
    disabled = false,
    autoFocus = false,
    onFocus = () => null,
    onBlur = () => null,
    onChange = () => null,
    onComplete = () => null,
    length = 4,
  } = props;

  const [focused, setFocused] = useState<number | null>();
  const [prevValue, setPrevValue] = useState(null);
  const [formattedValue, setFormattedValue] = useState('');
  const [disallowBlurFlag, setDisallowBlurFlag] = useState(false);

  const [values, setValues] = useState(getValues(value, length));

  const items = useMemo(() => new Array(length).fill('').map((item, index) => index), [length]);

  const itemsRef = useRef([]);

  const handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    const index = Number.parseInt(e.currentTarget.dataset.index, 10);
    setFocused(index);
    onFocus({ id, value, formattedValue });
  };

  const handleBlur = () => {
    if (disallowBlurFlag) return;
    setFocused(null);
    onBlur({ id, value, formattedValue });
  };

  const focusByValue = (fValue: string) => {
    setDisallowBlurFlag(true);
    const index = fValue.length;
    const item = itemsRef.current[index];
    if (item) {
      setTimeout(() => {
        item.focus();
        setDisallowBlurFlag(false);
      }, 0);
    }
  };

  const focusNext = (e: React.FormEvent<HTMLInputElement>) => {
    setDisallowBlurFlag(true);
    const index = Number.parseInt(e.currentTarget.dataset.index, 10);
    const nextIndex = index + 1;
    const next = itemsRef.current[nextIndex];
    if (next) {
      setTimeout(() => {
        next.focus();
        setDisallowBlurFlag(false);
      }, 0);
    }
  };

  const focusPrev = (e: React.FormEvent<HTMLInputElement>) => {
    setDisallowBlurFlag(true);
    const index = Number.parseInt(e.currentTarget.dataset.index, 10);
    const prevIndex = index - 1;
    const prev = itemsRef.current[prevIndex];
    if (prev) {
      setTimeout(() => {
        prev.focus();
        setDisallowBlurFlag(false);
      }, 0);
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const index = Number.parseInt(e.currentTarget.dataset.index, 10);
    const eValue = e.currentTarget.value;

    const fValue = formatCodeValue(index, eValue, values);
    const dfValue = deformatCodeValue(fValue);

    setPrevValue(dfValue);
    setFormattedValue(fValue);
    setValues(getValues(dfValue, length));

    onChange({ id, value: dfValue, formattedValue: fValue });

    focusByValue(fValue);

    if (fValue.length === length) onComplete({ id, value: dfValue, formattedValue: fValue });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 90) e.preventDefault(); // disable ctrl-z
    if (e.keyCode === 8 && !e.currentTarget.value) focusPrev(e);
    if (e.keyCode === 37) {
      focusPrev(e);
    }
    if (e.keyCode === 39) {
      focusNext(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = getChar(e);
    if (!e.ctrlKey && !e.altKey && !e.metaKey && char !== null && (char < '0' || char > '9')) e.preventDefault();
  };

  useEffect(() => {
    if (autoFocus && !disabled) {
      itemsRef.current[getLastIndex(values)].focus();
    }
  }, [autoFocus, disabled]);

  useEffect(() => {
    if (disabled && focused != undefined) handleBlur();
  }, [disabled]);

  useEffect(() => {
    if (value !== prevValue) {
      setValues(getValues(value, length));
      const fValue = formatCodeValue(undefined, value, values);
      setFormattedValue(fValue);
    }
  }, [value, length]);

  return (
    <StyledInput>
      <StyledInputCode>
        {items.map((item) => (
          <StyledInputCodeItem
            key={item}
            data-index={item}
            as="input"
            ref={(element: HTMLDivElement) => (itemsRef.current[item] = element)}
            type="tel"
            pattern={isMobile() ? '[0-9]*' : null}
            inputMode="numeric"
            onInvalid={(e: React.FormEvent<HTMLInputElement>) => {
              e.preventDefault();
            }}
            value={values[item] || ''}
            maxLength={1}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            sType={type}
            sSize={size}
            sDisabled={disabled}
            sFocused={focused === item}
            sError={error}
            sLength={length}
          />
        ))}
      </StyledInputCode>
    </StyledInput>
  );
};

export default InputCode;
