import { CancelTokenSource } from 'axios';

import { types, flow } from 'mobx-state-tree';

import { getCancelToken } from 'api/axios';

import requests from './requests';
import { normalizeGetAddressFromServer, normalizeGetFioFromServer } from './normalizers';

let cancelGetAddress: CancelTokenSource = null;
let cancelGetLastName: CancelTokenSource = null;
let cancelGetFirstName: CancelTokenSource = null;
let cancelGetMiddleName: CancelTokenSource = null;

export const Store = types.model().actions(() => ({
  getAddress: flow(function* request(query) {
    cancelGetAddress = getCancelToken(cancelGetAddress);

    const data = { query };

    const response = yield requests.getAddress(data, cancelGetAddress);
    return normalizeGetAddressFromServer(response?.data, 'address');
  }),

  getLocate: flow(function* request() {
    cancelGetAddress = getCancelToken(cancelGetAddress);

    const response = yield requests.getLocate(cancelGetAddress);
    return response?.data;
  }),

  getLastName: flow(function* request(query) {
    cancelGetLastName = getCancelToken(cancelGetLastName);

    const data = { query, parts: ['SURNAME'] };

    const response = yield requests.getFio(data, cancelGetLastName);
    return normalizeGetFioFromServer(response?.data, 'fio');
  }),

  getFirstName: flow(function* request(query) {
    cancelGetFirstName = getCancelToken(cancelGetFirstName);

    const data = { query, parts: ['NAME'] };

    const response = yield requests.getFio(data, cancelGetLastName);
    return normalizeGetFioFromServer(response?.data, 'fio');
  }),

  getMiddleName: flow(function* request(query) {
    cancelGetMiddleName = getCancelToken(cancelGetMiddleName);

    const data = { query, parts: ['PATRONYMIC'] };

    const response = yield requests.getFio(data, cancelGetMiddleName);
    return normalizeGetFioFromServer(response?.data, 'fio');
  }),
}));

export const store = Store.create();

export default store;
