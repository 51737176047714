import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Modal from 'components/UI/Modal';

import { ComponentProps } from './types';

import Form from './Form';
import Code from './Code';
import { SubscriptionToCaptcha } from './SubscriptionToCaptcha/SubscriptionToCaptcha';
import Loader from 'components/UI/Loader';
import { StyledLoaderBlock } from './style';

const Auth: React.FC<ComponentProps> = ({ store, isOpened, phoneNumber = '', onClose }) => {
  const {
    mounted,
    mount,
    unmount,
    visibility,
    closingOnOutClick,
    withoutCloseIcon,
    handleClose,
    formStore,
    codeStore,
    setCaptchaStatus,
    setSmartToken,
    handleSetPhone,
    checkCustomer,
    closeModalAuth,
  } = store;

  useEffect(() => {
    if (!mounted && isOpened) mount();

    return () => {
      if (mounted) unmount();
    };
  }, [mounted, isOpened]);

  useEffect(() => {
    if (phoneNumber?.length >= 11) {
      handleSetPhone(phoneNumber.replace(/^\+/, ''));
      checkCustomer();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (closeModalAuth && phoneNumber?.length >= 11) onClose();
  }, [closeModalAuth]);

  return (
    <Modal
      isOpened={isOpened}
      width={[300, 420, 446]}
      padding="l"
      zIndex={1000}
      onClose={onClose ? onClose : handleClose}
      closingOnOutClick={closingOnOutClick}
      withoutCloseIcon={withoutCloseIcon}
      closingOnEscPress={closingOnOutClick}
    >
      {visibility.form && !phoneNumber && <Form store={formStore} />}
      {visibility.code && <Code store={codeStore} phoneNumber={phoneNumber} />}
      {visibility.captcha && (
        <SubscriptionToCaptcha
          setCaptchaStatus={setCaptchaStatus}
          visible={visibility.captcha}
          setSmartToken={setSmartToken}
        />
      )}
      {phoneNumber && visibility.form && !visibility.code && !visibility.captcha && (
        <StyledLoaderBlock>
          <Loader size={110} delay={0} />
        </StyledLoaderBlock>
      )}
    </Modal>
  );
};

export default observer(Auth);
