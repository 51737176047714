export const link = {
  baseStyle: {
    textDecoration: 'none !important',
    _hover: {
      textDecoration: 'none !important',
      color: 'grey10',
    },
  },
  variants: {
    blue: {
      textStyle: 'bodyM',
      fontSize: 'md',
      color: 'blue10',
      _hover: {
        color: 'blueHover !important',
      },
    },
    second: {
      textStyle: 'bodyM',
      fontSize: 'md',
      color: 'mediumGrey10',
      _hover: {
        color: 'grey10 !important',
      },
    },
    secondBlue: {
      textStyle: 'bodyM',
      fontSize: 'md',
      color: 'white064',
      _hover: {
        color: 'white !important',
      },
    },
    buttonStore: {
      _hover: {
        '> svg': { opacity: 0.8 },
      },
    },
    icon: {
      '> svg': {
        fill: 'grey10 !important',
        stroke: 'grey10 !important',
      },
      _hover: {
        '> svg': {
          fill: 'blue10 !important',
          stroke: 'blue10 !important',
        },
      },
      _active: {
        '> svg': {
          fill: 'blueActive !important',
          stroke: 'blueActive !important',
        },
      },
    },
    iconWhite: {
      '> svg': {
        fill: 'white !important',
        stroke: 'white !important',
      },
      _hover: {
        '> svg': {
          fill: 'white064 !important',
          stroke: 'white064 !important',
        },
      },
      _active: {
        '> svg': {
          fill: 'white !important',
          stroke: 'white !important',
        },
      },
    },
    iconLogo: {
      '> svg': { fill: 'blue10 !important' },
      _hover: {
        '> svg': { fill: 'blueHover !important' },
      },
      _active: {
        '> svg': { fill: 'blueActive !important' },
      },
    },
  },
};
