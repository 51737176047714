import { lazy } from 'react';
import { ROUTES } from './constants';

import Mobile from './mobile';

const Home = lazy(() => import('./containers/home'));

const AboutUs = lazy(() => import('./pages/other/AboutUs'));
const Catalog = lazy(() => import('./pages/catalog/Catalog'));
const ProductDetail = lazy(() => import('./pages/product-details/ProductDetail'));
const DeviceReport = lazy(() => import('./pages/other/DeviceReport'));
const Contact = lazy(() => import('./pages/other/Contact'));
const MyAccountProfile = lazy(() => import('./pages/my-account/Profile'));
const MyAccountPurchasesList = lazy(() => import('./pages/my-account/purchases/List'));
const MyAccountPurchasesItem = lazy(() => import('./pages/my-account/purchases/Item'));
const MyAccountSalesList = lazy(() => import('./pages/my-account/sales/List'));
const MyAccountOld = lazy(() => import('./pages/other/MyAccount'));
const Login = lazy(() => import('./pages/other/Login'));

const Blog = lazy(() => import('./pages/blog/List'));
const BlogItem = lazy(() => import('./pages/blog/Item'));

const BuyReport = lazy(() => import('./pages/report/BuyReport'));
const BuyFunctionalReport = lazy(() => import('./pages/report/BuyFuncReport'));
const CheckoutReport = lazy(() => import('./pages/report/CheckoutReport'));
const CheckoutReportResult = lazy(() => import('./pages/report/CheckoutResult'));
const CheckoutReportResultError = lazy(() => import('./pages/report/CheckoutError'));
const CheckoutReportResultFailed = lazy(() => import('./pages/report/CheckoutFailed'));

const Cart = lazy(() => import('./pages/other/Cart'));
const OrderDetails = lazy(() => import('./pages/other/OrderDetails'));
const Checkout = lazy(() => import('./pages/market/Checkout'));
const CheckoutResult = lazy(() => import('./pages/market/Checkout/Result'));
const CheckoutResultError = lazy(() => import('./pages/market/Checkout/ResultError'));

const NotFound = lazy(() => import('./pages/other/NotFound'));
const OrderConfirm = lazy(() => import('./pages/other/OrderConfirm'));
const Content = lazy(() => import('./pages/content/Content'));
const Search = lazy(() => import('./pages/search/Search'));
const SearchProduct = lazy(() => import('./pages/search-product/SearchProduct'));

const Sell = lazy(() => import('./pages/sell/Sell'));
const SellPhone = lazy(() => import('./pages/sell/poll/Phone'));
const SellModel = lazy(() => import('./pages/sell/poll/Model'));
const SellMemory = lazy(() => import('./pages/sell/poll/Memory'));
const SellPoll = lazy(() => import('./pages/sell/poll/Poll'));

const SellVisualDiagnostic = lazy(() => import('./pages/sell/visual-diagnostic'));
const SellVisualDiagnosticProcess = lazy(() => import('./pages/sell/visual-diagnostic/Process'));
const SellVisualDiagnosticResult = lazy(() => import('./pages/sell/visual-diagnostic/Result'));
const SellVisualDiagnosticReject = lazy(() => import('./pages/sell/visual-diagnostic/Reject'));

const SecurityPolicy = lazy(() => import('./pages/policy/SecurityPolicy'));

const SellCheckout = lazy(() => import('./pages/sell/Checkout'));
const SellCheckoutResult = lazy(() => import('./pages/sell/Checkout/Result'));
const SellCheckoutResultError = lazy(() => import('./pages/sell/Checkout/ResultError'));
const SellCheckoutResultSellError = lazy(() => import('./pages/sell/Checkout/ResultSellError'));

const CardEnrollResult = lazy(() => import('./pages/card-enroll/CardEnrollResult'));
const CardEnrollResultError = lazy(() => import('./pages/card-enroll/CardEnrollError'));

const BlankPage = lazy(() => import('./pages/other/BlankPage'));

const CreditDCWidget = lazy(() => import('./pages/credit/direct-credit/Widget'));
const CreditWaitApprove = lazy(() => import('./pages/credit/direct-credit/WaitApprove'));
const CreditError = lazy(() => import('./pages/credit/direct-credit/Error'));

const Faq = lazy(() => import('./pages/faq/Faq'));
const FaqPurchaseQuestions = lazy(() => import('./pages/faq/PurchaseQuestions'));
const FaqSaleQuestions = lazy(() => import('./pages/faq/SaleQuestions'));

const InstructionsItem = lazy(() => import('./pages/instructions/Item'));

const News = lazy(() => import('./pages/news/News'));

const HowToTransfer = lazy(() => import('./pages/how-to-transfer/HowToTransfer'));

export const ROUTING = [
  {
    id: 'home',
    path: ROUTES.HOME,
    exact: true,
    Component: Home,
  },
  {
    id: 'catalog',
    path: ROUTES.CATALOG,
    exact: false,
    Component: Catalog,
  },
  {
    id: 'deviceReport',
    path: ROUTES.DEVICE_REPORT,
    exact: false,
    Component: DeviceReport,
  },
  {
    id: 'product',
    path: ROUTES.PRODUCT,
    exact: false,
    Component: ProductDetail,
  },
  {
    id: 'content',
    path: ROUTES.CONTENT,
    exact: false,
    Component: Content,
  },
  {
    id: 'search',
    path: ROUTES.SEARCH,
    exact: false,
    Component: Search,
  },
  {
    id: 'searchProduct',
    path: ROUTES.SEARCH_PRODUCT,
    exact: false,
    Component: SearchProduct,
  },
  {
    id: 'aboutUs',
    path: ROUTES.ABOUT_US,
    exact: false,
    Component: AboutUs,
  },
  {
    id: 'contact',
    path: ROUTES.CONTACT,
    exact: false,
    Component: Contact,
  },
  {
    id: 'sellCheckoutResultSellError',
    path: ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_SELL_ERROR,
    exact: true,
    Component: SellCheckoutResultSellError,
  },
  {
    id: 'sellCheckoutResultError',
    path: ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT_ERROR,
    exact: true,
    Component: SellCheckoutResultError,
  },
  {
    id: 'sellCheckoutResult',
    path: ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT_RESULT,
    exact: true,
    Component: SellCheckoutResult,
  },
  {
    id: 'sellCheckout',
    path: ROUTES.MY_ACCOUNT_ORDERS_SALES_ITEM_CHECKOUT,
    exact: false,
    Component: SellCheckout,
  },
  {
    id: 'myAccountProfile',
    path: ROUTES.MY_ACCOUNT,
    exact: true,
    Component: MyAccountProfile,
  },
  {
    id: 'myAccountPurchasesList',
    path: ROUTES.MY_ACCOUNT_ORDERS_PURCHASES,
    exact: true,
    Component: MyAccountPurchasesList,
  },
  {
    id: 'myAccountPurchasesItem',
    path: ROUTES.MY_ACCOUNT_ORDERS_PURCHASES_ITEM,
    exact: true,
    Component: MyAccountPurchasesItem,
  },
  {
    id: 'myAccountSalesList',
    path: ROUTES.MY_ACCOUNT_ORDERS_SALES,
    exact: true,
    Component: MyAccountSalesList,
  },
  {
    id: 'myAccountOld',
    path: ROUTES.MY_ACCOUNT_OLD,
    exact: false,
    Component: MyAccountOld,
  },
  {
    id: 'register',
    path: ROUTES.REGISTER,
    exact: false,
    Component: Login,
  },
  {
    id: 'login',
    path: ROUTES.LOGIN,
    exact: false,
    Component: Login,
  },
  {
    id: 'cart',
    path: ROUTES.CART,
    exact: false,
    Component: Cart,
  },
  {
    id: 'orderDetails',
    path: ROUTES.ORDER_DETAILS,
    exact: false,
    Component: OrderDetails,
  },
  {
    id: 'checkoutResultError',
    path: ROUTES.CHECKOUT_RESULT_ERROR,
    exact: true,
    Component: CheckoutResultError,
  },
  {
    id: 'checkoutResult',
    path: ROUTES.CHECKOUT_RESULT,
    exact: true,
    Component: CheckoutResult,
  },
  {
    id: 'checkout',
    path: ROUTES.CHECKOUT,
    exact: false,
    Component: Checkout,
  },
  {
    id: 'orderConfirm',
    path: ROUTES.ORDER_CONFIRM,
    exact: false,
    Component: OrderConfirm,
  },

  {
    id: 'sell',
    path: ROUTES.SELL,
    exact: true,
    Component: Sell,
  },
  {
    id: 'sellPhone',
    path: ROUTES.SELL_PHONE,
    exact: true,
    Component: SellPhone,
  },
  {
    id: 'sellModel',
    path: ROUTES.SELL_PHONE_MODEL,
    exact: true,
    Component: SellModel,
  },
  {
    id: 'sellMemory',
    path: ROUTES.SELL_PHONE_MODEL_MEMORY,
    exact: true,
    Component: SellMemory,
  },
  {
    id: 'sellPoll',
    path: ROUTES.SELL_PHONE_MODEL_MEMORY_POLL,
    exact: true,
    Component: SellPoll,
  },
  {
    id: 'sellVisualDiagnostic',
    path: ROUTES.SELL_VISUAL_DIAGNOSTIC,
    exact: true,
    Component: SellVisualDiagnostic,
  },
  {
    id: 'sellVisualDiagnosticProcess',
    path: ROUTES.SELL_VISUAL_DIAGNOSTIC_PROCESS,
    exact: true,
    Component: SellVisualDiagnosticProcess,
  },
  {
    id: 'sellVisualDiagnosticResult',
    path: ROUTES.SELL_VISUAL_DIAGNOSTIC_RESULT,
    exact: true,
    Component: SellVisualDiagnosticResult,
  },
  {
    id: 'sellVisualDiagnosticReject',
    path: ROUTES.SELL_VISUAL_DIAGNOSTIC_REJECT,
    exact: true,
    Component: SellVisualDiagnosticReject,
  },
  {
    id: 'buyReport',
    path: ROUTES.REPORT_BUY,
    exact: true,
    Component: BuyReport,
  },
  {
    id: 'buyFunctionalReport',
    path: ROUTES.REPORT_BUY_FUNCTIONAL,
    exact: true,
    Component: BuyFunctionalReport,
  },
  {
    id: 'checkoutReport',
    path: ROUTES.REPORT_CHECKOUT,
    exact: true,
    Component: CheckoutReport,
  },
  {
    id: 'checkoutReportResult',
    path: ROUTES.REPORT_CHECKOUT_RESULT,
    exact: true,
    Component: CheckoutReportResult,
  },
  {
    id: 'checkoutReportResultError',
    path: ROUTES.REPORT_CHECKOUT_RESULT_ERROR,
    exact: true,
    Component: CheckoutReportResultError,
  },
  {
    id: 'checkoutReportResultFailed',
    path: ROUTES.REPORT_CHECKOUT_RESULT_FAILED,
    exact: true,
    Component: CheckoutReportResultFailed,
  },
  {
    id: 'securityPolicy',
    path: ROUTES.SECURITY_POLICY,
    exact: true,
    Component: SecurityPolicy,
  },
  {
    id: 'cardEnrollResult',
    path: ROUTES.CARD_ENROLL_RESULT,
    exact: true,
    Component: CardEnrollResult,
  },
  {
    id: 'cardEnrollResultError',
    path: ROUTES.CARD_ENROLL_RESULT_ERROR,
    exact: true,
    Component: CardEnrollResultError,
  },
  {
    id: 'blog',
    path: ROUTES.BLOG,
    exact: true,
    Component: Blog,
  },
  {
    id: 'blogItem',
    path: ROUTES.BLOG_ITEM,
    exact: true,
    Component: BlogItem,
  },
  {
    id: 'creditDCWidget',
    path: ROUTES.CREDIT_DC_WIDGET,
    exact: true,
    Component: CreditDCWidget,
  },
  {
    id: 'creditWaitApprove',
    path: ROUTES.CREDIT_WAIT_APPROVE,
    exact: true,
    Component: CreditWaitApprove,
  },
  {
    id: 'creditError',
    path: ROUTES.CREDIT_ERROR,
    exact: true,
    Component: CreditError,
  },
  {
    id: 'faq',
    path: ROUTES.FAQ,
    exact: true,
    Component: Faq,
  },
  {
    id: 'faqPurchaseQuestions',
    path: ROUTES.FAQ_PURCHASE_QUESTIONS,
    exact: true,
    Component: FaqPurchaseQuestions,
  },
  {
    id: 'faqSaleQuestions',
    path: ROUTES.FAQ_SALE_QUESTIONS,
    exact: true,
    Component: FaqSaleQuestions,
  },
  {
    id: 'instructionsItem',
    path: ROUTES.INSTRUCTIONS_ITEM,
    exact: true,
    Component: InstructionsItem,
  },
  {
    id: 'instructionsItemBrands',
    path: ROUTES.INSTRUCTIONS_ITEM_BRANDS,
    exact: true,
    Component: InstructionsItem,
  },
  {
    id: 'instructionsItemBrandsItem',
    path: ROUTES.INSTRUCTIONS_ITEM_BRANDS_ITEM,
    exact: true,
    Component: InstructionsItem,
  },
  {
    id: 'news',
    path: ROUTES.NEWS,
    exact: true,
    Component: News,
  },
  {
    id: 'howToTransfer',
    path: ROUTES.HOW_TO_TRANSFER,
    exact: true,
    Component: HowToTransfer,
  },
  {
    id: 'creditError',
    path: ROUTES.CREDIT_ERROR,
    exact: true,
    Component: CreditError,
  },
  {
    id: 'mindbox',
    path: ROUTES.MINDBOX,
    exact: true,
    Component: BlankPage,
  },
  {
    id: 'mobile',
    path: ROUTES.MOBILE,
    exact: false,
    Component: Mobile,
  },
  {
    id: 'notFound',
    path: ROUTES.NOT_FOUND,
    exact: false,
    Component: NotFound,
  },
];
