import Heading from './Heading';

const { H1 } = Heading;
const { H2 } = Heading;
const { H3 } = Heading;
const { H4 } = Heading;
const { H5 } = Heading;
const { H6 } = Heading;

export { H1, H2, H3, H4, H5, H6 };
