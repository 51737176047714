import styled from 'styled-components';

import { StyledInputElement } from 'components/inputs/style';
import { media } from 'components/constants';

export const StyledInputCode = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
`;

export const StyledInputCodeItem = styled(StyledInputElement)`
  margin: 0 4px;
  padding: 11px 0;
  text-align: center;
  width: ${(props) => (props.sLength === 6 ? '25%' : '85.5px')};
  height: ${(props) => (props.sLength === 6 ? '40px' : '120px')};

  :focus {
    background: white;
    border: 3px solid #f4f4f4;
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.sLength === 6 ? '25%' : '61.5px')};
    max-height: ${(props) => (props.sLength === 6 ? '40px' : '48px')};

    :focus {
      border: 4px solid #f4f4f4;
    }
  }

  @media (${media.tablet}) {
    width: 85.5px;
    height: 120px;
  }
`;
