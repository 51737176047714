import { AxiosInstance } from 'axios';

import { createInstance } from 'api/axios';

type Api = Readonly<{
  baseUrl: string;
}>;

let apiInstance: AxiosInstance;

export function configureApiInstance(params: Api) {
  apiInstance = createInstance(params.baseUrl);
}

export function getApiInstance() {
  return apiInstance;
}

export function getApiHeader() {
  return {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  };
}
